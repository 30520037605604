import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import moment from "moment";
import DataTable from "../../components/DataTable";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";
import { getAlternativeFields } from "../../utils/stats";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { fetchAggregateForList } from "../../services/lists";

const Aggregate = ({ listId, dateRange, token }) => {
  const [aggregate, setAggregate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadAggregateData() {
      setLoading(true);
      const aggregateData = await fetchAggregateForList(
        token,
        listId,
        dateRange.start,
        dateRange.end
      );
      setAggregate(aggregateData);
      setLoading(false);
    }

    loadAggregateData().then(() => {});
  }, [listId, token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="Aggregate" />;
  }
  if (!aggregate) {
    return null;
  }

  const stats = [];
  for (const revRow of aggregate) {
    const row = { ...revRow, ...getAlternativeFields(revRow) };
    stats.push(row);
  }
  stats.reverse();

  const columns = [
    { name: "date", label: "Date" },
    {
      name: "source_blacklisted",
      label: "Source Rejected",
      options: { display: false }
    },
    {
      name: "carrier_blacklisted",
      label: "Carrier Rejected",
      options: {
        display: false
      }
    },
    {
      name: "operating_system_blacklisted",
      label: "OS Rejected",
      options: {
        display: false
      }
    },
    {
      name: "keyword_blacklisted",
      label: "Keyword Rejected",
      options: { display: false }
    },
    ...getCommonAdminHeaders(aggregate, { action: false }),

    {
      name: "average_profit_per_record_for_7_days",
      label: "7-Day Avg PPR",
      options: {
        display: false,
        footerData: ""
      }
    },
    {
      name: "average_profit_per_record_for_14_days",
      label: "14-Day Avg PPR",
      options: {
        display: false,
        footerData: ""
      }
    },
    {
      name: "average_profit_per_record_for_30_days",
      label: "30-Day Avg PPR",
      options: {
        display: false,
        footerData: ""
      }
    },
    {
      name: "average_profit_per_record_for_45_days",
      label: "45-Day Avg PPR",
      options: {
        display: false,
        footerData: ""
      }
    },
    {
      name: "average_profit_per_record_for_60_days",
      label: "60-Day Avg PPR",
      options: {
        display: false,
        footerData: ""
      }
    },
    {
      name: "average_profit_per_record_for_90_days",
      label: "90-Day Avg PPR",
      options: {
        display: false,
        footerData: ""
      }
    }
  ];

  const data = [];

  for (const row of stats) {
    data.push({
      date: `${row.date} \n ${moment(row.date).format("dddd")}`,
      source_blacklisted: row.source_blacklisted || 0,
      carrier_blacklisted: row.carrier_blacklisted || 0,
      operating_system_blacklisted: row.operating_system_blacklisted || 0,
      keyword_blacklisted: row.keyword_blacklisted || 0,

      ...getCommonAdminColumns(row, { action: false }),

      average_profit_per_record_for_7_days: (
        row?.additional_stats?.average_profit_per_record_for_7_days || 0
      ).toFixed(4),
      average_profit_per_record_for_14_days: (
        row?.additional_stats?.average_profit_per_record_for_14_days || 0
      ).toFixed(4),
      average_profit_per_record_for_30_days: (
        row?.additional_stats?.average_profit_per_record_for_30_days || 0
      ).toFixed(4),
      average_profit_per_record_for_45_days: (
        row?.additional_stats?.average_profit_per_record_for_45_days || 0
      ).toFixed(4),
      average_profit_per_record_for_60_days: (
        row?.additional_stats?.average_profit_per_record_for_60_days || 0
      ).toFixed(4),
      average_profit_per_record_for_90_days: (
        row?.additional_stats?.average_profit_per_record_for_90_days || 0
      ).toFixed(4)
    });
  }

  return (
    <PerfectScrollbar>
      <DataTable title="Aggregate" data={data} columns={columns} />
    </PerfectScrollbar>
  );
};

export default Aggregate;

export function getContentProviderHeaders(
  contentProvidersConfig,
  event,
  label,
  options = {}
) {
  const headers = [];

  for (const [contentProvider, config] of Object.entries(
    contentProvidersConfig
  )) {
    headers.push({
      name: `${contentProvider}.${event}.total`,
      label: `${config.name} ${label}`,
      options,
    });
  }

  return headers;
}

export function getContentProviderColumnValue(
  event,
  contentProviders,
  config = {}
) {
  const stats = {};

  for (const [contentProvider, values] of Object.entries(contentProviders)) {
    let value = values[event]?.total || 0;
    if (config.toFixed) {
      value = value.toFixed(config.toFixed);
    }
    stats[`${contentProvider}.${event}.total`] = value;
  }

  return stats;
}

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { fetchStatsByDomainForDateRange } from "src/services/email/stats";
import DataTable from "../../components/DataTable";
import {
  blockDomain,
  getBlockedDomains,
  unblockDomain
} from "../../services/email/blacklist";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders, getHeadersForEvent
} from "../../utils/email/stats_table";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { EVENT_TYPES } from "../../config/email/events";

const StatsByDomain = ({ dateRange, token, permission }) => {
  const [stats, setStats] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [blockedDomains, setBlockDomains] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadStats() {
      setLoading(true);
      const domainStats = await fetchStatsByDomainForDateRange(
        token,
        dateRange.start,
        dateRange.end
      );
      setStats(domainStats.stats);

      const blockedDomainData = {};
      const blockedData = await getBlockedDomains(token);
      for (const item of blockedData.domains) {
        const domain = item.domain;
        blockedDomainData[domain] = true;
      }
      setBlockDomains(blockedDomainData);
      setLoading(false);
    }

    loadStats().then(() => {});
  }, [token, dateRange, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Domain" />;
  }
  if (!stats) {
    console.log("No email stats by domain available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "domain", label: "Domain" },
      ...getHeadersForEvent({
        event: "insert",
        eventName: "Accepted",
        EVENT_TYPES,
      }),
      ...getCommonAdminHeaders(stats, { action: true })
    ];
  } else {
    columns = [{ name: "domain", label: "Domain" }];
  }

  const data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      const isBlocked = blockedDomains[row.domain] || false;
      let action;
      if (isBlocked) {
        action = async e => {
          await unblockDomain(token, row.domain);
          setRefreshTrigger(new Date());
        };
      } else {
        action = async e => {
          await blockDomain(token, row.domain);
          setRefreshTrigger(new Date());
        };
      }

      data.push({
        domain: row.domain,
        "insert.total":
          (row?.insert?.unique?.total || 0) +
          (row?.insert?.duplicate?.total || 0) +
          (row?.insert?.inbox_ally?.total || 0),
        "insert.unique.total": row?.insert?.unique?.total || 0,
        "insert.duplicate.total": row?.insert?.duplicate?.total || 0,
        "insert.inbox_ally.total": row?.insert?.inbox_ally?.total || 0,
        ...getCommonAdminColumns(row, { action, isBlocked })
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        domain: row.domain,
        revenue: (row.partner_profit || 0).toFixed(4)
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Domain" columns={columns} data={data} />
    </PerfectScrollbar>
  );
};

export default StatsByDomain;

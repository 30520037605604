import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { fetchStatsByTemplateForDateRange } from "src/services/email/stats";
import DataTable from "../../components/DataTable";
import { getCommonAdminColumns } from "../../utils/email/stats_table";
import { getCommonAdminHeaders } from "../../utils/email/stats_table";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const StatsByTemplate = ({ dateRange, token, permission }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadStats() {
      setLoading(true);
      const templateStats = await fetchStatsByTemplateForDateRange(
        token,
        dateRange.start,
        dateRange.end
      );
      setStats(templateStats.stats);
      setLoading(false);
    }

    loadStats().then(() => {});
  }, [token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Template" />;
  }
  if (!stats) {
    console.log("No email stats by template available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "template", label: "Template" },
      ...getCommonAdminHeaders(stats, {})
    ];
  } else {
    columns = [{ name: "template", label: "Template" }];
  }

  const data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      data.push({
        template: row.template,
        ...getCommonAdminColumns(row)
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        template: row.template,
        revenue: (row.partner_profit || 0).toFixed(4)
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Template" columns={columns} data={data} />
    </PerfectScrollbar>
  );
};

export default StatsByTemplate;

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, CardContent } from "@material-ui/core";
import DataTable from "src/components/DataTable";
import { fetchAggregateForDate } from "src/services/email/stats";
import { calculateAdditionalStats } from "./utils";
import { capitalize } from "src/utils/text";
import { calculateTotals } from "../../utils/stats";
import { calculatePercentage } from "../../utils/math";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders,
  getHeadersForEvent,
} from "../../utils/email/stats_table";
import MultilineChart from "../../components/charts/Multiline";
import { getChartLabel } from "../../config/chart";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { EVENT_TYPES } from "../../config/email/events";

const blacklistedFields = [
  "source",
  "keyword",
  "email_provider",
  "operating_system",
  "domain",
];

function getAverageProfitPerRecordHeaders(baseName, days, options) {
  const headers = [];
  for (const day of days) {
    headers.push({
      name: `${baseName}.days_${day}`,
      label: `${day}-Day Avg PPR`,
      options,
    });
  }

  return headers;
}

function getAverageProfitPerRecordValues(baseName, days, row) {
  const data = {};
  for (const day of days) {
    data[`${baseName}.days_${day}`] = (
      row[baseName]?.[`days_${day}`] || 0
    ).toFixed(4);
  }

  return data;
}

function getBlacklistedFieldsHeaders(fields, config = {}) {
  const headers = [];
  for (const field of fields) {
    const item = config.label?.[field] || capitalize(field);
    headers.push({
      name: `blacklisted.${field}.total`,
      label: `${item} Rejected`,
    });
  }
  return headers;
}

function getBlacklistedFieldsValues(fields, row) {
  const data = {};
  for (const field of fields) {
    data[`blacklisted.${field}.total`] =
      row["blacklisted"]?.[field]?.total || 0;
  }
  return data;
}

const EmailStats = ({ dateRange, token, permission }) => {
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    async function fetchStats() {
      const data = await fetchAggregateForDate(
        token,
        dateRange.start,
        dateRange.end
      );
      if (data.status === "success") {
        const tableData = calculateAdditionalStats(data.aggregate);
        setStats(tableData);
      }
      setLoading(false);
    }

    fetchStats().then(() => {});
  }, [token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="Email Quick Look" />;
  }

  // TODO: refactor chart label and data
  let chartItems, chartLabelConfig;
  if (permission.view === "internal") {
    const basicEvents = [
      "send",
      "open",
      "click",
      "soft_bounce",
      "hard_bounce",
      "unsubscribe",
      "complaint",
    ];
    chartItems = ["unique_insert", "duplicate_insert", "total_insert"];
    for (const event of basicEvents) {
      for (const eventType of ["welcome", "alert", "total"]) {
        chartItems.push(`${eventType}_${event}`);
      }
    }
    chartLabelConfig = {
      unique_insert: "Unique Accepted",
      duplicate_insert: "Duplicate Accepted",
      total_insert: "Total Accepted",
    };
  } else {
    chartItems = ["insert", "partner_profit"];
    chartLabelConfig = { partner_profit: "Profit", insert: "Total New Users" };
  }
  const labels = Object.values(stats).map((item) => item.date);
  const chartData = [];
  for (const chartItem of chartItems) {
    const row = Object.values(stats).map(
      (value) => value[chartItem] || value?.additional_stats?.[chartItem]
    );
    chartData.push({
      label: getChartLabel(chartItem, chartLabelConfig),
      data: row,
      hidden: chartItem !== "unique_insert",
    });
  }

  const totalFields = ["sent", "clicks", "unsubscribes", "complaints"];
  const totals = calculateTotals(stats, totalFields);
  totals.click_percentage = calculatePercentage(totals.clicks, totals.sent);
  totals.unsubscribe_percentage = calculatePercentage(
    totals.unsubscribes,
    totals.sent
  );
  totals.complaint_percentage = calculatePercentage(
    totals.complaints,
    totals.sent
  );

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "date", label: "Date" },
      { name: "ping.accepted.total", label: "Accepted Ping" },
      { name: "ping.rejected.total", label: "Rejected Ping" },

      ...getBlacklistedFieldsHeaders(blacklistedFields, {
        label: { email_provider: "ISP", operating_system: "OS" },
      }),

      {
        name: "blacklisted_and_purged_email.blacklisted.total",
        label: "Blacklisted Emails",
      },
      {
        name: "blacklisted_and_purged_email.purged.total",
        label: "Purged Emails",
      },

      ...getHeadersForEvent({
        event: "insert",
        eventName: "Accepted",
        EVENT_TYPES,
      }),
      ...getCommonAdminHeaders(stats, {}),
      ...getAverageProfitPerRecordHeaders(
        "average_profit_per_record",
        [7, 14, 30, 45, 60, 90],
        {
          footerData: "",
        }
      ),
    ];
  } else {
    columns = [
      { name: "date", label: "Date" },
      { name: "total_send", label: "Total Send" },
    ];
  }

  const data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      data.push({
        date: row.date,
        "ping.accepted.total": row?.ping?.accepted?.total || 0,
        "ping.rejected.total": row?.ping?.rejected?.total || 0,

        ...getBlacklistedFieldsValues(blacklistedFields, row),

        "blacklisted_and_purged_email.blacklisted.total":
          row?.blacklisted_and_purged_email?.blacklisted?.total || 0,
        "blacklisted_and_purged_email.purged.total":
          row?.blacklisted_and_purged_email?.purged?.total || 0,

        "insert.total":
          (row?.insert?.unique?.total || 0) +
          (row?.insert?.duplicate?.total || 0) +
          (row?.insert?.inbox_ally?.total || 0),
        "insert.unique.total": row?.insert?.unique?.total || 0,
        "insert.duplicate.total": row?.insert?.duplicate?.total || 0,
        "insert.inbox_ally.total": row?.insert?.inbox_ally?.total || 0,
        ...getCommonAdminColumns(row),
        ...getAverageProfitPerRecordValues(
          "average_profit_per_record",
          [7, 14, 30, 45, 60, 90],
          row
        ),
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        date: row.date,
        total_send: row.total_send || 0,
      });
    }
  }

  return (
    <Card>
      <CardContent>
        <PerfectScrollbar>
          <DataTable
            title="Email Quick Look"
            columns={columns}
            data={data.reverse()}
          />
        </PerfectScrollbar>

        {/*{stats.length ? (*/}
        {/*  <Box m={2}>*/}
        {/*    <MultilineChart labels={labels} datasets={chartData} />*/}
        {/*  </Box>*/}
        {/*) : null}*/}
      </CardContent>
    </Card>
  );
};

EmailStats.propTypes = {};

export default EmailStats;

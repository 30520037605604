import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";

import {
  blockSourceOnList,
  getBlockedSourcesOnList,
  unblockSourceOnList
} from "src/services/blacklist";

import DataTable from "../../components/DataTable";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";
import { fetchStatsBySourceForList } from "../../services/lists";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const StatsBySource = ({ token, listId, dateRange }) => {
  const [blockedSources, setBlockedSources] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true);

      const statsData = await fetchStatsBySourceForList(
        token,
        listId,
        dateRange.start,
        dateRange.end
      );
      setStats(statsData.stats);

      const blockedSourceData = await getBlockedSourcesOnList(token, listId);
      const blockedSources = {};
      for (const item of blockedSourceData.sources) {
        const source = item.source;
        if (!blockedSources[source]) {
          blockedSources[source] = true;
        }
      }
      setBlockedSources(blockedSources);
      setLoading(false);
    }

    loadInitialData().then(() => {});
  }, [listId, refreshTrigger, token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Source" />;
  }
  if (!stats) {
    return null;
  }

  const columns = [
    { name: "source", label: "Source" },
    ...getCommonAdminHeaders(stats, { action: true })
  ];

  const data = [];

  for (const row of stats) {
    const isBlocked = blockedSources[row.source] || false;

    const action = {
      isBlocked,
      block: e => {
        blockSourceOnList(token, row.source, listId).then(
          e => setRefreshTrigger(new Date()),
          e => setRefreshTrigger(new Date())
        );
      },
      unblock: e => {
        unblockSourceOnList(token, row.source, listId).then(
          e => setRefreshTrigger(new Date()),
          e => setRefreshTrigger(new Date())
        );
      }
    };

    const config = { action };

    data.push({
      source: row.source,
      ...getCommonAdminColumns(row, config)
    });
  }

  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Source" data={data} columns={columns} />
    </PerfectScrollbar>
  );
};
StatsBySource.propTypes = {
  className: PropTypes.string
};

export default StatsBySource;

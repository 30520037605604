import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";

import DataTable from "../../components/DataTable";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";
import { fetchStatsByAdTypeForList } from "../../services/lists";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { AD_TYPES } from "../../config/ad";

const StatsByAdType = ({ token, listId, dateRange }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true);

      const data = await fetchStatsByAdTypeForList(
        token,
        listId,
        dateRange.start,
        dateRange.end
      );
      setStats(data.stats);
    }

    loadInitialData().then(() => {});

    setLoading(false);
  }, [token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Ad Type" />;
  }
  if (!stats) {
    return null;
  }

  const headerConfig = {
    insert: { display: "excluded" },
    rejected: { display: "excluded" },
    unsub: { display: "excluded" },
    time_inactivity: { display: "excluded" },
    revenue_per_record: { display: "excluded" },
    ls_revenue_per_record: { display: "excluded" },
    partner_revenue_per_record: { display: "excluded" }
  };
  const columns = [
    { name: "ad_type", label: "Ad Type" },
    ...getCommonAdminHeaders(stats, headerConfig)
  ];

  const data = [];

  const config = {};
  for (const row of stats) {
    data.push({
      ad_type: AD_TYPES[row.ad_type] || row.ad_type,
      ...getCommonAdminColumns(row, config)
    });
  }

  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Ad Type" data={data} columns={columns} />
    </PerfectScrollbar>
  );
};

StatsByAdType.propTypes = {
  className: PropTypes.string
};

export default StatsByAdType;

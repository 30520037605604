import PropTypes from "prop-types";

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  blockKeyword,
  getBlockedKeywords,
  unblockKeyword
} from "src/services/blacklist";
import { fetchStatsByKeywordForDateRange } from "src/services/stats_by_keyword";
import DataTable from "../../components/DataTable";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";

const StatsByKeyword = ({ dateRange, auth, permission }) => {
  const [stats, setStats] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [blockedKeywords, setBlockedKeywords] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true);
      const keywordStats = await fetchStatsByKeywordForDateRange(
        auth.token,
        dateRange.start,
        dateRange.end
      );
      setStats(keywordStats.stats);

      const blockedData = await getBlockedKeywords(auth.token);
      const blockedKeywordData = {};
      for (const item of blockedData.keywords) {
        const keyword = item.keyword;
        if (!blockedKeywordData[keyword]) {
          blockedKeywordData[keyword] = true;
        }
      }
      setBlockedKeywords(blockedKeywordData);
      setLoading(false);
    }

    loadInitialData().then(() => {});
  }, [auth.token, dateRange, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Keyword" />;
  }
  if (!stats) {
    console.log("No Stats by keyword available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "keyword", label: "Keyword" },
      ...getCommonAdminHeaders(stats, { action: true })
    ];
  } else {
    columns = [
      { name: "keyword", label: "Keyword" },
      { name: "revenue", label: "Revenue" }
    ];
  }

  let data = [];
  if (permission.view === "internal")
    for (const row of stats) {
      const isBlocked = blockedKeywords[row.keyword] || false;
      const action = {
        isBlocked,
        block: e => {
          blockKeyword(auth.token, row.keyword).then(
            e => setRefreshTrigger(new Date()),
            e => setRefreshTrigger(new Date())
          );
        },
        unblock: e => {
          unblockKeyword(auth.token, row.keyword).then(
            e => setRefreshTrigger(new Date()),
            e => setRefreshTrigger(new Date())
          );
        }
      };

      data.push({
        keyword: row.keyword,
        ...getCommonAdminColumns(row, { action })
      });
    }
  else {
    for (const row of stats) {
      data.push({
        keyword: row.keyword,
        revenue: (row.partner_profit || 0).toFixed(4)
      });
    }
  }

  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Keyword" columns={columns} data={data} />
    </PerfectScrollbar>
  );
};

StatsByKeyword.propTypes = {
  className: PropTypes.string
};

export default StatsByKeyword;

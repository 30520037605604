import React from 'react';
import { Box } from '@material-ui/core';
import { Text } from 'src/components/Text';
import { AiFillApple, AiOutlineAmazon } from 'react-icons/ai';
import { SiFedex, SiTarget, SiUps, SiWalmart } from 'react-icons/si';

export const Footer = () => (
  <Box display="flex" paddingTop="2rem" alignItems="center" flexDirection="column">
    <Text style={{ fontWeight: 'bold', marginBottom: '-1rem' }} variant="overline">
      Find jobs from other companies including
    </Text>
    <Box display="flex" alignItems="center">
      <SiWalmart color="#7F8487" size="5rem" />
      <Box padding="0 0.4rem" />
      <AiOutlineAmazon color="#7F8487" size="2rem" />
      <Box padding="0 0.4rem" />
      <AiFillApple color="#7F8487" size="2rem" />
      <Box padding="0 0.4rem" />
      <SiUps color="#7F8487" size="2rem" />
      <Box padding="0 0.4rem" />
      <SiTarget color="#7F8487" size="2rem" />
      <Box padding="0 0.4rem" />
      <SiFedex color="#7F8487" size="4rem" />
    </Box>
  </Box>
);

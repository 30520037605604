import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Nunjucks from "nunjucks";

import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Button,
  Box,
  TextField,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import { useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import _ from "lodash";

import Page from "src/components/Page";

import DateRangePicker from "src/components/DateRangePicker";

import { fetchInformationForList, sendBurstSMS } from "src/services/lists";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const adData = {
  first_name: "John",
  last_name: "Doe",
  phone: "1011001000",
  ip_address: "99.203.0.62",
  search: "warehouse",
  utm_source: "facebook",
  company: "UPS",
  zip: "48341",
  domain: "example.com",
};

const RenderedContent = ({ content, emptyMessage }) => {
  let renderedContent = null;
  let error = false;

  if (content === "") {
    content = emptyMessage;
  }

  try {
    renderedContent = Nunjucks.configure(null, {
      throwOnUndefined: true,
    }).renderString(content, adData);
    error = false;
  } catch (e) {
    renderedContent = content;
    error = true;
  }

  return error ? (
    <Alert severity="error">{renderedContent}</Alert>
  ) : (
    <Alert severity="info">{renderedContent}</Alert>
  );
};

const InputView = (props) => {
  const [content, updateContent] = useState(props.content || "");
  const [link, updateLink] = useState(props.link || "");
  const [adName, updateAdName] = useState(props.adName || "");
  const classes = useStyles();

  return (
    <Box>
      <Typography gutterBottom>
        Send a one time SMS to all the recipients on this list (
        {props.listInfo.activeLeadsCount} active leads).
      </Typography>

      <br />
      <br />

      <Box>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <TextField
              fullWidth
              defaultValue={adName}
              label="Short Name for Campaign"
              variant="outlined"
              onChange={(e) => updateAdName(e.target.value)}
            />
          </Grid>

          <Grid item md={8}>
            <Typography gutterBottom>
              This name will be shown in the templates section in the stats.
              Please keep it short and unique.
            </Typography>
          </Grid>

          <Grid item md={8}>
            <TextField
              fullWidth
              label="Ad Copy"
              variant="outlined"
              defaultValue={content}
              onChange={(e) => {
                updateContent(e.target.value);
              }}
            />
          </Grid>

          <Grid item md={8}>
            <RenderedContent
              emptyMessage="Type in an ad to see preview here"
              content={content}
            />
          </Grid>

          <Grid item md={8}>
            <TextField
              fullWidth
              label="Link (optional)"
              variant="outlined"
              defaultValue={link}
              onChange={(e) => {
                updateLink(e.target.value);
              }}
            />
          </Grid>

          <Grid item md={8}>
            <RenderedContent
              emptyMessage="Type in a link to see preview here"
              content={link}
            />
          </Grid>
        </Grid>

        <br />
        <br />

        <Grid item md={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              props.proceedNext(adName, content, link);
            }}
          >
            Next
          </Button>
        </Grid>
      </Box>

      <br />
      <br />

      <Typography component="div" gutterBottom>
        <div>
          Available variables:
          <ul className={classes.list}>
            {Object.keys(adData)
              .map((v) => `{{${v}}}`)
              .map((v) => (
                <li key={v}>{v}</li>
              ))}
          </ul>
        </div>
      </Typography>
    </Box>
  );
};

const ConfirmView = ({
  adName,
  content,
  link,
  goBack,
  listInfo,
  sendHandler,
}) => {
  return (
    <Box>
      <Typography gutterBottom>
        You're going to send a one time sms to{" "}
        <strong>{listInfo.activeLeadsCount}</strong> people.
      </Typography>

      <br />
      <br />

      <Box>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <Typography>
              {" "}
              <strong>Campaign: </strong>
              {adName}
            </Typography>
          </Grid>

          <Grid item md={8}>
            <RenderedContent
              emptyMessage="Type in an ad to see preview here"
              content={content}
            />
          </Grid>

          <Grid item md={8}>
            <RenderedContent emptyMessage="No link" content={link} />
          </Grid>

          <Grid item md={8}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                goBack();
              }}
            >
              Back
            </Button>{" "}
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendHandler();
              }}
            >
              Send to {listInfo.activeLeadsCount} people
            </Button>
          </Grid>
        </Grid>
      </Box>

      <br />
      <br />
    </Box>
  );
};

const BurstSMSView = () => {
  const classes = useStyles();
  const token = useSelector((state) => state.auth.token);

  const { state } = useLocation();

  const { list } = state;

  const [content, updateContent] = useState("");
  const [link, updateLink] = useState("");
  const [adName, updateAdName] = useState("");
  const [confirmView, updateConfirmView] = useState(false);
  const [listInfo, updateListInfo] = useState({});

  const refresh = () => {
    fetchInformationForList(token, list._id).then((response) => {
      updateListInfo(response);
    });
  };

  const sendHandler = () => {
    sendBurstSMS(token, list._id, adName, content, link).then((response) => {
      alert("SMS Queued");
    });
  };

  const proceedNext = (adName, content, link) => {
    if (!adName || !content) {
      alert("Either campaign name or ad copy is empty!");
      return;
    }

    updateContent(content);
    updateAdName(adName);
    updateLink(link);

    updateConfirmView(true);
  };

  const goBack = () => {
    updateConfirmView(false);
  };

  useEffect(() => {
    refresh();
  }, [list]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item md={1}>
            <Button
              component={NavLink}
              to="/app/admin/lists"
              color="primary"
              variant="contained"
              startIcon={<ArrowBackIcon />}
            />
          </Grid>

          <Grid item md={3}>
            <Typography variant="h4">Burst SMS to {list.name}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item md={12}>
            {confirmView ? (
              <ConfirmView
                listInfo={listInfo}
                content={content}
                adName={adName}
                link={link}
                goBack={goBack}
                sendHandler={sendHandler}
              />
            ) : (
              <InputView
                listInfo={listInfo}
                content={content}
                adName={adName}
                link={link}
                proceedNext={proceedNext}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default BurstSMSView;

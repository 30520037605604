export const CONTENT_PROVIDERS = {
  neuvoo: { name: "Talent" },
  talent_through_ls_jobs: { name: "Talent Through LS Jobs" },
  lead5: { name: "Upward" },
  operation_media: { name: "Operation Media" },
  //operation_media_xml: { name: "Operation Media xml" },
  operation_media_xml_1_click: { name: "OM-XML 1-Click" },
  operation_media_xml_2_click: { name: "OM-XML 2-Click" },
  direct_link: { name: "Direct Link" },
  J2C: { name: "Talroo" },

  expertjobmatch_board: { name: "Expertjobmatch Board" },
  jobmatic_board: { name: "Jobmatic Board" },
  flexjobstoday_board: { name: "Flexjobstoday Board" },
  quickjobfinder_board: { name: "Quickjobfinder Board" },
  local_jobs_board: { name: "Local Jobs Board" },
  rocketjobs_board: { name: "Rocketjobs Board" },

  expertjobmatch_board_clean: { name: "Expertjobmatch Board Clean" },
  jobmatic_board_clean: { name: "Jobmatic Board Clean" },
  flexjobstoday_board_clean: { name: "Flexjobstoday Board Clean" },
  quickjobfinder_board_clean: { name: "Quickjobfinder Board Clean" },
  local_jobs_board_clean: { name: "Local Jobs Board Clean" },
  rocketjobs_board_clean: { name: "Rocketjobs Board Clean" },
};

export const CONTENT_PROVIDERS_FOR_EMAIL = {
  J2C: { name: "Talroo" },
  operation_media_xml_1_click: { name: "OM-XML 1-Click" },
  operation_media_2_click: { name: "OM 2-click" },
};

import { debounce } from 'lodash';
import { Text } from 'src/components/Text';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Button, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { searchCompanyDetails } from 'src/services/tagging';

export const SelectCompany = ({ setCompanyDetails, setUser }) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [loading, setLoading] = useState();
  const handleCompanySearch = async searchStr => {
    setLoading(true);
    const res = await searchCompanyDetails(searchStr);
    if (res) setCompanies(res);
    setLoading(false);
  };

  const debounceLoadData = useMemo(() => debounce(handleCompanySearch, 700), []);

  useEffect(() => {
    handleCompanySearch();
  }, []);

  return (
    <Box width="100%">
      <Text variant="h4" align="left" style={{ fontWeight: 'bold', color: '#0e57b4' }}>
        Which company are you interested in?
      </Text>
      <Box p="0.5rem" />
      <Autocomplete
        fullWidth
        loading={true}
        options={companies
          ?.filter(item => item?.name?.toLowerCase() !== 'default')
          .map(com => com.name)}
        onChange={(e, v) => setSelectedCompany(companies.find(item => item?.name === v))}
        renderOption={option => <Text style={{ fontWeight: 'bold' }}>{option}</Text>}
        renderInput={params => (
          <TextField
            {...params}
            onChange={e => debounceLoadData(e?.target?.value)}
            label="Search Company"
            margin="normal"
            variant="outlined"
          />
        )}
      />
      <Box p="0.5rem" />
      <Button
        fullWidth
        color="primary"
        variant="contained"
        disabled={!selectedCompany}
        onClick={() => {
          setCompanyDetails(selectedCompany);
          setUser(user => ({ ...user, company: selectedCompany.name }));
        }}
      >
        Next
      </Button>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Card,
  CardHeader,
  Divider
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RecentActorsIcon from "@material-ui/icons/RecentActors";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Chip from "@material-ui/core/Chip";
import CheckIcon from "@material-ui/icons/Check";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import SearchIcon from "@material-ui/icons/Search";

import { NavLink } from "react-router-dom";

import { fetchUserStats } from "src/services/user";

import Page from "src/components/Page";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  chip: { margin: theme.spacing(0.5) },

  table: {
    minWidth: 650
  }
}));

const SingleUserLookupView = () => {
  const [userSearchPhoneNumber, setUserSearchPhoneNumber] = useState("");

  return (
    <Grid container spacing={3}>
      <Grid item lg={9}>
        <Typography>
          <h3>Single User Lookup</h3>
        </Typography>
        <br />
        <TextField
          onChange={e => {
            setUserSearchPhoneNumber(e.target.value);
          }}
        />
        <Button
          color="primary"
          size="small"
          component={NavLink}
          to={`/app/admin/stats/user/${userSearchPhoneNumber}`}
          startIcon={<SearchIcon />}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

const DateAndUserListView = () => {
  const PER_PAGE = 20;
  const DEFAULT_ORDER = "revenue";

  const [stats, setStats] = useState([]);
  const [date, setDate] = useState(yesterday);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [showNext, setShowNext] = useState(true);

  const auth = useSelector(state => state.auth);

  useEffect(() => {
    const skip = (page - 1) * PER_PAGE;
    fetchUserStats(auth.token, date, order, skip, PER_PAGE).then(response => {
      setStats(response.stats);
      if (response.stats.length < PER_PAGE) {
        setShowNext(false);
      }
    });
  }, [date, page, order, auth.token]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          <Typography>
            <h3>User By Date</h3>
          </Typography>
        </Grid>

        <Grid item lg={3}>
          <TextField
            id="date"
            label="Date"
            type="date"
            defaultValue={date}
            InputLabelProps={{
              shrink: true
            }}
            onChange={e => {
              setDate(e.target.value);
            }}
          />
        </Grid>

        <Grid item lg={3}>
          <Select value={order} onChange={ev => setOrder(ev.target.value)}>
            <MenuItem key="revenue" value="revenue">
              Revenue
            </MenuItem>

            <MenuItem key="welcome_send" value="welcome_send">
              Welcome Send
            </MenuItem>
            <MenuItem key="alerts_send" value="alerts_send">
              Alerts Send
            </MenuItem>

            <MenuItem key="welcome_click" value="welcome_click">
              Welcome Click
            </MenuItem>
            <MenuItem key="alerts_click" value="alerts_click">
              Alerts Click
            </MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={9}>
          <TableView rows={stats} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={9}>
          {page > 1 ? (
            <Button
              onClick={() => setPage(page - 1)}
              variant="contained"
              color="primary"
            >
              Prev
            </Button>
          ) : (
            ""
          )}
          &nbsp;(<strong>Page {page}</strong>)&nbsp;
          {showNext ? (
            <Button
              onClick={() => setPage(page + 1)}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const TableView = ({ rows }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Phone</TableCell>
            <TableCell>SubID</TableCell>

            <TableCell>Welcome Send</TableCell>
            <TableCell>Alerts Send</TableCell>
            <TableCell>Total Send</TableCell>

            <TableCell>Welcome Click</TableCell>
            <TableCell>Alerts Click</TableCell>
            <TableCell>Total Click</TableCell>

            <TableCell>Revenue</TableCell>
            <TableCell>Details & History</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {row.phone}
              </TableCell>
              <TableCell>{row.sub_id || "-"}</TableCell>

              <TableCell>{row.welcome_send || 0}</TableCell>
              <TableCell>{row.alerts_send || 0}</TableCell>
              <TableCell>
                {(row.welcome_send || 0) + (row.alerts_send || 0)}
              </TableCell>

              <TableCell>{row.welcome_click || 0}</TableCell>
              <TableCell>{row.alerts_click || 0}</TableCell>
              <TableCell>
                {(row.welcome_click || 0) + (row.alerts_click || 0)}
              </TableCell>

              <TableCell>{(row.revenue || 0).toFixed(4) || "-"}</TableCell>
              <TableCell>
                {row.site_wide_unique ? (
                  <Chip
                    className={classes.chip}
                    icon={<CheckIcon />}
                    color="primary"
                    label="U"
                  />
                ) : (
                  ""
                )}{" "}
                {row.network_wide_unique ? (
                  <Chip
                    className={classes.chip}
                    icon={<CloudDoneIcon />}
                    color="secondary"
                    label="U"
                  />
                ) : (
                  ""
                )}
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  component={NavLink}
                  to={`/app/admin/stats/user/${row.phone}`}
                  startIcon={<RecentActorsIcon />}
                ></Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const yesterday = moment()
  .subtract(1, "days")
  .format("YYYY-MM-DD");

const UserStatsView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="User Stats">
      <Container maxWidth={false}>
        <SingleUserLookupView />
        <br />
        <br />
        <br />
        <DateAndUserListView />
      </Container>
    </Page>
  );
};

export default UserStatsView;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { verifyLogin, refresh } from "../services/auth";

const AUTH_LOCAL_STORAGE_KEY = "auth";

function getUserDataFromLocalStorage() {
  const str = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!str) {
    return {};
  }

  try {
    const data = JSON.parse(str);
    data.refresh = true;
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
}

//console.log(getUserDataFromLocalStorage());

export const login = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const { setUserData } = authSlice.actions;
    const { email, password } = payload;

    const data = await verifyLogin(email, password);
    const { name, token, access, company, error } = data;

    if (token) {
      return dispatch(setUserData({ name, email, token, access, company }));
    } else {
      return rejectWithValue({ error });
    }
  }
);

export const refreshAuth = createAsyncThunk(
  "auth/refresh",
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const { setUserData } = authSlice.actions;
    const { token } = payload;

    const data = await refresh(token);
    const { name, email, access, company, error } = data;

    if (token) {
      return dispatch(setUserData({ name, email, token, access, company }));
    } else {
      return rejectWithValue({ error });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: getUserDataFromLocalStorage(),
  reducers: {
    setUserData: (state, action) => {
      const { name, email, token, access, company } = action.payload;

      localStorage.setItem(
        AUTH_LOCAL_STORAGE_KEY,
        JSON.stringify({ name, email, token, access, company })
      );

      state.token = token;
      state.name = name;
      state.email = email;
      state.access = access;
      state.company = company;
      state.refresh = false;

      state.error = undefined;
    },

    clearUserData: (state, action) => {
      localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
      return {};
    }
  },

  extraReducers: {
    [login.rejected]: (state, action) => {
      const { error, payload } = action;

      if (!payload) {
        state.error = error.message || "Error occurred";
        return;
      }

      state.error = action.payload.error || "Error occurred";
    }
  }
});

export const { setUserData, clearUserData } = authSlice.actions;
export default authSlice.reducer;

import axios from "axios";
import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;

export async function fetchJobsForUser(userId, sendId, type) {
  const endpoint = apiURL + "/v1/serp/by-user";
  const response = await axios.post(endpoint, {
    userId,
    sendId,
    type
  });

  return response.data;
}

export async function recordImpression(url) {
  const response = await axios.get(url);
  return response.data;
}

import React from "react";
import { Grid } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { getChartColors } from "./helpers/color";

const MultilineChart = ({ labels, datasets }) => {
  const { colorsWithOpacity } = getChartColors(datasets.length, 0.5);

  let processedDatasets = [];
  for (const [index, row] of Object.entries(datasets)) {
    processedDatasets.push({
      ...row,
      borderColor: colorsWithOpacity[index],
      fill: false
    });
  }

  const chartData = {
    labels: labels,
    datasets: processedDatasets
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Line data={chartData} height={80} />
      </Grid>
    </Grid>
  );
};

export default MultilineChart;

export function divideOrGetZero(dividend, divisor) {
  dividend = parseFloat(dividend);
  divisor = parseFloat(divisor);
  return dividend && divisor ? dividend / divisor : 0;
}

export function calculatePercentage(dividend, divisor) {
  return divideOrGetZero(dividend, divisor) * 100;
}

export function calculateRevenuePerRecord(dividend, divisor) {
  return divideOrGetZero(dividend, divisor);
}

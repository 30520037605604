const loadPopUp = (url, w, h) => {
  const popup = window.open(url, "_blank");
  return popup !== null && typeof popup !== "undefined";
};

export function loadPopUpAndPopUnder(primaryLink, popUnderLink) {

  if (popUnderLink) {
    const popped = loadPopUp(primaryLink);
    if (popped) {
      window.location.assign(popUnderLink);
    } else {
      setTimeout(() => {
        window.location.assign(primaryLink);
      }, 500);
    }
  } else {
    window.location.assign(primaryLink);
  }


}
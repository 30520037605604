import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography
} from '@material-ui/core';
// import Logo from 'src/components/Logo';

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    backgroundColor: '#33ccc5'
  },
  title: {
    width: '100%',
    paddingTop: 30,
    paddingBottom: 30,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 25,
      paddingBottom: 25,
      fontSize: '23px'
    },
  }
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <Typography variant="h2" className={classes.title}>
          HIRING IMMEDIATELY
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;

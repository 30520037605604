import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

import { fetchStatsByCarrierForDateRange } from "src/services/stats_by_carrier";

import {
  blockCarrier,
  getBlockedCarriers,
  unblockCarrier
} from "src/services/blacklist";
import DataTable from "../../components/DataTable";

import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const StatsByCarrier = ({ dateRange, auth, permission }) => {
  let [stats, setStats] = useState(null);
  let [blockedCarriers, setBlockedCarriers] = useState({});
  let [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true);
      const carrierStats = await fetchStatsByCarrierForDateRange(
        auth.token,
        dateRange.start,
        dateRange.end
      );
      setStats(carrierStats.stats);

      const blockedData = await getBlockedCarriers(auth.token);
      const blockedCarrierData = {};
      for (const item of blockedData.carriers) {
        const carrier = item.carrier;
        if (!blockedCarrierData[carrier]) {
          blockedCarrierData[carrier] = true;
        }
      }
      setBlockedCarriers(blockedCarrierData);
      setLoading(false);
    }

    loadInitialData().then(() => {});
  }, [auth.token, dateRange, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Carrier" />;
  }

  if (!stats) {
    console.log("No Stats by carrier available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "carrier", label: "Carrier" },
      ...getCommonAdminHeaders(stats, { action: true })
    ];
  } else {
    columns = [
      { name: "carrier", label: "Carrier" },
      { name: "revenue", label: "Revenue" }
    ];
  }

  let data = [];

  if (permission.view === "internal") {
    for (const row of stats) {
      const isBlocked = blockedCarriers[row.carrier] || false;
      const action = {
        isBlocked,
        block: e => {
          blockCarrier(auth.token, row.carrier).then(
            e => setRefreshTrigger(new Date()),
            e => setRefreshTrigger(new Date())
          );
        },
        unblock: e => {
          unblockCarrier(auth.token, row.carrier).then(
            e => setRefreshTrigger(new Date()),
            e => setRefreshTrigger(new Date())
          );
        }
      };

      data.push({
        carrier: row.carrier,
        ...getCommonAdminColumns(row, { action })
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        carrier: row.carrier,
        revenue: (row.partner_profit || 0).toFixed(4)
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Carrier" columns={columns} data={data} />
    </PerfectScrollbar>
  );
};

StatsByCarrier.propTypes = {
  className: PropTypes.string
};

export default StatsByCarrier;

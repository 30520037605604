import axios from "axios";
import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;

export async function verifyLogin(email, password) {
  const endpoint = `${apiURL}/v1/auth/login`;
  const response = await axios.post(endpoint, {
    email,
    password
  });
  return response.data;
}

export async function refresh(token) {
  const endpoint = `${apiURL}/v1/auth/refresh`;
  const response = await axios.post(endpoint, {}, { headers: { token } });
  return response.data;
}

import React from "react";
import { makeStyles, Card, CardHeader, CardContent, Box, Typography } from "@material-ui/core";

import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  list: {
    paddingLeft: theme.spacing(3)
  }
}));

const Ping = ({ features, token, apiUrl, pingClassification }) => {
  let View;

  if (pingClassification) {
    return <PingClassification token={token} apiUrl={apiUrl} />;
  }

  if (features.includes("sms") && features.includes("email")) {
    View = <SMSAndEmailPing token={token} apiUrl={apiUrl} />;
  } else if (features.includes("sms")) {
    View = <SMSPing token={token} apiUrl={apiUrl} />;
  } else if (features.includes("email")) {
    View = <EmailPing token={token} apiUrl={apiUrl} />;
  }

  return View;
};

const SMSPing = ({ token, apiUrl }) => {
  const endpoint = apiUrl + "/v2/leads/ping";

  return (
    <Card>
      <CardHeader title="Ping for uniqueness" />

      <CardContent>
        <Box>
          <Typography component="span" variant="body1">
            With this API endpoint, it is possible to ping us before sending in the actual lead data. The API accepts a
            phone number and tells us if that number is unique or not. Pinging the API for uniqueness before sending the
            full data for processing can help improve the number of accepted leads per day. Unique numbers are more
            likely to be accepted and duplicates are more often rejected.
            <br />
            <br />
            To ping for a number, please send a <strong>POST</strong> request to the endpoint with the phone number
            wrapped in a <strong>JSON</strong> payload.
            <br />
            <br />
            Here's an example JSON snippet with a sample lead data:
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
  "phone": "0000000000"
}`}
            </SyntaxHighlighter>
            <br />
            <br />
            Each request needs to contain the <strong>Token</strong> header for authentication. You can find your token
            in the top most section of this docs page or you can also copy it from the code snippet below.
            <br />
            <br />
            Here's an example with cURL:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`curl --location --request POST '${endpoint}' \
  --header 'Content-Type: application/json' \
  --header 'Token: ${token}' \
  --data-raw '{
   "phone": "0000000000"
}'`}
            </SyntaxHighlighter>
            <br />
            <br />
            The response should tell us if the phone number is unique or not. Here's a sample response for a unique
            phone number:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`{
  "phone": {
      "unique": true
  },
  "status": "success"
}`}
            </SyntaxHighlighter>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const EmailPing = ({ token, apiUrl }) => {
  const endpoint = apiUrl + "/v2/leads/ping";

  return (
    <Card>
      <CardHeader title="Ping for uniqueness" />

      <CardContent>
        <Box>
          <Typography component="span" variant="body1">
            With this API endpoint, it is possible to ping us before sending in the actual lead data. The API accepts an
            email and tells us if that email is unique or not. Pinging the API for uniqueness before sending the full
            data for processing can help improve the number of accepted leads per day. Unique emails are more likely to
            be accepted and duplicates are more often rejected.
            <br />
            <br />
            To ping for an email, please send a <strong>POST</strong> request to the endpoint with the email wrapped in
            a <strong>JSON</strong> payload.
            <br />
            <br />
            Here's an example JSON snippet with a sample lead data:
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
  "email": "myawesomeemail@gmail"
}`}
            </SyntaxHighlighter>
            <br />
            <br />
            Each request needs to contain the <strong>Token</strong> header for authentication. You can find your token
            in the top most section of this docs page or you can also copy it from the code snippet below.
            <br />
            <br />
            Here's an example with cURL:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`curl --location --request POST '${endpoint}' \
  --header 'Content-Type: application/json' \
  --header 'Token: ${token}' \
  --data-raw '{
    "email": "myawesomeemail@gmail"
}'`}
            </SyntaxHighlighter>
            <br />
            <br />
            The response should tell us if the email is unique or not. Here's a sample response for a unique email:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`{
    "email": {
        "unique": true
    },
    "status": "success"
}`}
            </SyntaxHighlighter>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const SMSAndEmailPing = ({ token, apiUrl }) => {
  const endpoint = apiUrl + "/v2/leads/ping";

  return (
    <Card>
      <CardHeader title="Ping for uniqueness" />

      <CardContent>
        <Box>
          <Typography component="span" variant="body1">
            With this API endpoint, it is possible to ping us before sending in the actual lead data. The API accepts a
            phone number and an email; and tells us if that phone number and email are unique or not. Pinging the API
            for uniqueness before sending the full data for processing can help improve the number of accepted leads per
            day. Unique numbers and emails are more likely to be accepted and duplicates are more often rejected.
            <br />
            <br />
            To ping for a number and an email, please send a <strong>POST</strong> request to the endpoint with the
            phone number and email wrapped in a <strong>JSON</strong> payload.
            <br />
            <br />
            Here's an example JSON snippet with a sample lead data:
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
  "phone": "0000000000",
  "email": "myawesomeemail@gmail"
}`}
            </SyntaxHighlighter>
            <br />
            <br />
            Each request needs to contain the <strong>Token</strong> header for authentication. You can find your token
            in the top most section of this docs page or you can also copy it from the code snippet below.
            <br />
            <br />
            Here's an example with cURL:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`curl --location --request POST '${endpoint}' \
  --header 'Content-Type: application/json' \
  --header 'Token: ${token}' \
  --data-raw '{
    "phone": "0000000000",
    "email": "myawesomeemail@gmail"
}'`}
            </SyntaxHighlighter>
            <br />
            <br />
            The response should tell us if the phone number and email are unique or not. Here's a sample response for a
            unique phone number and email:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`{
    "phone": {
        "unique": true
    },
    "email": {
        "unique": true
    },
    "status": "success"
}`}
            </SyntaxHighlighter>
            <br />
            Note: You can ping for only phone or email too.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const PingClassification = ({ token, apiUrl }) => {
  const endpoint = apiUrl + "/v2/leads/classify-ping";

  return (
    <Card>
      <CardHeader title="Ping for uniqueness" />

      <CardContent>
        <Box>
          <Typography component="span" variant="body1">
            With this API endpoint, it is possible to ping us before sending in the actual lead data. The API accepts a
            phone number and tells us if the lead should be sent over or not. We do some pre processing on our end to improve
            the quality and the number of the accepted leads.
            <br />
            <br />
            To ping for a number, please send a <strong>POST</strong> request to the endpoint with the phone number
            wrapped in a <strong>JSON</strong> payload.
            <br />
            <br />
            Here's an example JSON snippet with a sample lead data:
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
  "phone": "0000000000"
}`}
            </SyntaxHighlighter>
            <br />
            <br />
            Each request needs to contain the <strong>Token</strong> header for authentication. You can find your token
            in the top most section of this docs page or you can also copy it from the code snippet below.
            <br />
            <br />
            Here's an example with cURL:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`curl --location --request POST '${endpoint}' \
  --header 'Content-Type: application/json' \
  --header 'Token: ${token}' \
  --data-raw '{
   "phone": "0000000000"
}'`}
            </SyntaxHighlighter>
            <br />
            <br />
            The response should tell us if the phone number is <strong>allowed</strong> or not. Here's a sample response for a unique
            phone number:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`{
  "status": "duplicate_active",
  "allowed": false,
  "request_id": "f61d532c-31b3-4f62-a64b-dae5694058e8"
}`}
            </SyntaxHighlighter>

            <br />
            <br />

            You should send the lead only if <strong>allowed</strong> field has a <strong>true</strong> value.


          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};


export default Ping;

import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import moment from "moment";
import PropTypes from "prop-types";

import Page from "src/components/Page";
import DateRangePicker from "src/components/DateRangePicker";
import EmailStats from "./EmailStats";

import StatsByCampaign from "./StatsByCampaign";
import StatsByCompany from "./StatsByCompany";
import StatsByDomain from "./StatsByDomain";
import StatsByEmailProvider from "./StatsByEmailProvider";
import StatsByOperatingSystem from "./StatsByOperatingSystem";
import StatsByKeyword from "./StatsByKeyword";
import StatsBySource from "./StatsBySource";
import StatsByTemplate from "./StatsByTemplate";
import StatsBySubject from "./StatsBySubject";
import StatsByFromAddress from "./StatsByFromAddress";
import RecentLeads from "./RecentLeads";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const ContactActivityAnalysis = () => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const userAccess = auth.access;

  const [dateRange, setDateRange] = useState({
    start: moment().subtract(15, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });

  return (
    <Page className={classes.root} title="Email Stats">
      <Container maxWidth={false}>
        <Box mb={3}>
          <Typography variant="h4">Email Statistics</Typography>
        </Box>

        <DateRangePicker
          start={dateRange.start}
          end={dateRange.end}
          onChange={(values) => {
            setDateRange(values);
          }}
          text="Search"
        />

        <Grid container spacing={3}>
          {userAccess["email:aggregate"] ? (
            <Grid item md={12}>
              <EmailStats
                dateRange={dateRange}
                token={auth.token}
                permission={userAccess["email:aggregate"]}
              />
            </Grid>
          ) : null}

          {userAccess["email:stats_by_source"] ? (
            <Grid item md={12}>
              <StatsBySource
                dateRange={dateRange}
                token={auth.token}
                permission={userAccess["email:stats_by_source"]}
              />
            </Grid>
          ) : null}

          {userAccess["email:stats_by_email_provider"] ? (
            <Grid item md={12}>
              <StatsByEmailProvider
                dateRange={dateRange}
                token={auth.token}
                permission={userAccess["email:stats_by_email_provider"]}
              />
            </Grid>
          ) : null}

          {userAccess["email:stats_by_operating_system"] ? (
            <Grid item md={12}>
              <StatsByOperatingSystem
                dateRange={dateRange}
                token={auth.token}
                permission={userAccess["email:stats_by_operating_system"]}
              />
            </Grid>
          ) : null}

          {userAccess["email:stat_by_domain"] ? (
            <Grid item md={12}>
              <StatsByDomain
                dateRange={dateRange}
                token={auth.token}
                permission={userAccess["email:stat_by_domain"]}
              />
            </Grid>
          ) : null}

          {userAccess["email:stats_by_keyword"] ? (
            <Grid item md={12}>
              <StatsByKeyword
                dateRange={dateRange}
                token={auth.token}
                permission={userAccess["email:stats_by_keyword"]}
              />
            </Grid>
          ) : null}

          {userAccess["email:stats_by_company"] ? (
            <Grid item md={12}>
              <StatsByCompany
                dateRange={dateRange}
                token={auth.token}
                permission={userAccess["email:stats_by_company"]}
              />
            </Grid>
          ) : null}

          {userAccess["email:stats_by_template"] ? (
            <Grid item md={12}>
              <StatsByTemplate
                dateRange={dateRange}
                token={auth.token}
                permission={userAccess["email:stats_by_template"]}
              />
            </Grid>
          ) : null}

          {userAccess["email:stats_by_campaign"] ? (
            <Grid item md={12}>
              <StatsByCampaign
                dateRange={dateRange}
                token={auth.token}
                permission={userAccess["email:stats_by_campaign"]}
              />
            </Grid>
          ) : null}

          {/*{userAccess["email:stats_by_subject"] ? (*/}
          {/*  <Grid item md={12}>*/}
          {/*    <StatsBySubject*/}
          {/*      dateRange={dateRange}*/}
          {/*      token={auth.token}*/}
          {/*      permission={userAccess["email:stats_by_subject"]}*/}
          {/*    />*/}
          {/*  </Grid>*/}
          {/*) : null}*/}

          {/*{userAccess["email:stats_by_from_address"] ? (*/}
          {/*  <Grid item md={12}>*/}
          {/*    <StatsByFromAddress*/}
          {/*      dateRange={dateRange}*/}
          {/*      token={auth.token}*/}
          {/*      permission={userAccess["email:stats_by_from_address"]}*/}
          {/*    />*/}
          {/*  </Grid>*/}
          {/*) : null}*/}

          {userAccess["email:recent_leads"] ? (
            <Grid item md={12}>
              <RecentLeads
                token={auth.token}
                permission={userAccess["email:recent_leads"]}
              />
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Page>
  );
};

ContactActivityAnalysis.propTypes = {
  isAdmin: PropTypes.bool,
};

export default ContactActivityAnalysis;

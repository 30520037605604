import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import DataTable from "../../components/DataTable";

import {
  blockKeywordOnList,
  getBlockedKeywordsOnList,
  unblockKeywordOnList
} from "src/services/blacklist";

import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { fetchStatsByKeywordForList } from "../../services/lists";

const StatsByKeyword = ({ token, listId, dateRange }) => {
  const [blockedKeywords, setBlockedKeywords] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true);
      const keywordStats = await fetchStatsByKeywordForList(
        token,
        listId,
        dateRange.start,
        dateRange.end
      );
      setStats(keywordStats.stats);

      const blockedData = await getBlockedKeywordsOnList(token, listId);
      const blockedKeywordData = {};
      for (const item of blockedData.keywords) {
        const keyword = item.keyword;
        if (!blockedKeywordData[keyword]) {
          blockedKeywordData[keyword] = true;
        }
      }
      setBlockedKeywords(blockedKeywordData);
      setLoading(false);
    }

    loadInitialData().then(() => {});
  }, [listId, refreshTrigger, token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Keyword" />;
  }
  if (!stats) {
    return null;
  }

  const columns = [
    { name: "keyword", label: "Keyword" },
    ...getCommonAdminHeaders(stats, { action: true })
  ];

  const rows = [];
  for (const row of stats) {
    const isBlocked = blockedKeywords[row.keyword] || false;

    const action = {
      isBlocked,
      block: e => {
        blockKeywordOnList(token, row.keyword, listId).then(
          e => setRefreshTrigger(new Date()),
          e => setRefreshTrigger(new Date())
        );
      },
      unblock: e => {
        unblockKeywordOnList(token, row.keyword, listId).then(
          e => setRefreshTrigger(new Date()),
          e => setRefreshTrigger(new Date())
        );
      }
    };

    const config = { action };

    rows.push({
      keyword: row.keyword,
      ...getCommonAdminColumns(row, config)
    });
  }
  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Keyword" columns={columns} data={rows} />
    </PerfectScrollbar>
  );
};
StatsByKeyword.propTypes = {
  className: PropTypes.string
};

export default StatsByKeyword;

import React, { useEffect, useState } from "react";
import { Grid, Container, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import moment from "moment";

import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import CheckIcon from "@material-ui/icons/Check";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import SearchIcon from "@material-ui/icons/Search";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import HttpIcon from "@material-ui/icons/Http";

import {
  fetchUserHistory,
  fetchUserDetails,
  fetchUserEvents,
  updateUserDetails
} from "src/services/user";

import Page from "src/components/Page";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  userName: { marginRight: theme.spacing(1) },
  chip: { margin: theme.spacing(0.3) },

  table: {
    minWidth: 650
  }
}));

const UserView = ({ phone, token }) => {
  const classes = useStyles();

  const [user, setUser] = useState();

  const [search, setSearch] = useState();
  const [zip, setZip] = useState();

  useEffect(() => {
    fetchUserDetails(phone).then(response => {
      const user = response.lead;
      setUser(user);
      setSearch(user.search);
      setZip(user.zip);
    });
  }, [phone, token]);

  if (!user) {
    return "";
  }

  return (
    <Grid container spacing={3}>
      <Grid item lg={10}>
        <Typography component="span" variant="h2" className={classes.userName}>
          {user.first_name} {user.last_name}
        </Typography>

        <Box>
          {user.site_wide_unique ? (
            <Chip
              className={classes.chip}
              icon={<CheckIcon />}
              color="primary"
              label="Unique"
            />
          ) : (
            ""
          )}{" "}
          {user.network_wide_unique ? (
            <Chip
              className={classes.chip}
              icon={<CloudDoneIcon />}
              color="secondary"
              label="Network Unique"
            />
          ) : (
            ""
          )}
          <br />
          <br />
          <Chip
            className={classes.chip}
            icon={<SearchIcon />}
            label={user.search}
          />
          <Chip
            className={classes.chip}
            icon={<PersonPinCircleIcon />}
            label={user.zip}
          />
          <Chip
            className={classes.chip}
            icon={<HttpIcon />}
            label={user.ip_address}
          />
        </Box>

        <br />

        <Box>
          <Typography
            component="span"
            variant="h4"
            className={classes.userName}
          >
            Quick Update
          </Typography>
          <br />
          <br />
          <TextField
            id="search"
            label="Search Query"
            defaultValue={user.search}
            onChange={e => {
              setSearch(e.target.value);
            }}
          />
          &nbsp;&nbsp;&nbsp;
          <TextField
            id="zip"
            label="Location"
            defaultValue={user.zip}
            onChange={e => {
              setZip(e.target.value);
            }}
          />
          &nbsp;&nbsp;&nbsp;
          <br />
          <br />
          <Button
            onClick={() => {
              updateUserDetails(token, phone, { search, zip }, false).then(
                response => {
                  window.location.reload();
                }
              );
            }}
            variant="contained"
            color="primary"
          >
            Update
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            onClick={() => {
              updateUserDetails(token, phone, { search, zip }, true).then(
                response => {
                  window.location.reload();
                }
              );
            }}
            variant="contained"
            color="secondary"
          >
            Update All Accounts
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const RevenueHistoryView = ({ phone, token }) => {
  const classes = useStyles();

  const PER_PAGE = 20;

  const [stats, setStats] = useState([]);

  const [page, setPage] = useState(1);
  const [showNext, setShowNext] = useState(true);

  useEffect(() => {
    const skip = (page - 1) * PER_PAGE;
    fetchUserHistory(token, phone, skip, PER_PAGE).then(response => {
      setStats(response.stats);
      if (response.stats.length < PER_PAGE) {
        setShowNext(false);
      } else {
        setShowNext(true);
      }
    });
  }, [page, phone, token]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item>
          <Typography component="span" variant="h3">
            Revenue History
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Welcome Send</TableCell>
                  <TableCell>Alerts Send</TableCell>
                  <TableCell>Total Send</TableCell>

                  <TableCell>Welcome Click</TableCell>
                  <TableCell>Alerts Click</TableCell>
                  <TableCell>Total Click</TableCell>

                  <TableCell>Revenue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stats.map(row => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      {row.date}
                    </TableCell>
                    <TableCell>{row.welcome_send || 0}</TableCell>
                    <TableCell>{row.alerts_send || 0}</TableCell>
                    <TableCell>
                      {(row.welcome_send || 0) + (row.alerts_send || 0)}
                    </TableCell>

                    <TableCell>{row.welcome_click || 0}</TableCell>
                    <TableCell>{row.alerts_click || 0}</TableCell>
                    <TableCell>
                      {(row.welcome_click || 0) + (row.alerts_click || 0)}
                    </TableCell>

                    <TableCell>{row.revenue || "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6}>
          {page > 1 ? (
            <Button
              onClick={() => setPage(page - 1)}
              variant="contained"
              color="primary"
            >
              Prev
            </Button>
          ) : (
            ""
          )}
          &nbsp;(<strong>Page {page}</strong>)&nbsp;
          {showNext ? (
            <Button
              onClick={() => setPage(page + 1)}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const UserEventsView = ({ phone, token }) => {
  const classes = useStyles();

  const PER_PAGE = 20;

  const [events, setEvents] = useState([]);

  const [page, setPage] = useState(1);
  const [showNext, setShowNext] = useState(true);

  useEffect(() => {
    const skip = (page - 1) * PER_PAGE;
    fetchUserEvents(token, phone, skip, PER_PAGE).then(response => {
      setEvents(response.events);
      if (response.events.length < PER_PAGE) {
        setShowNext(false);
      } else {
        setShowNext(true);
      }
    });
  }, [page, phone, token]);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item>
          <Typography component="span" variant="h3">
            Events History
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Time</TableCell>
                  <TableCell>Event</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map(row => (
                  <TableRow key={row._id}>
                    <TableCell component="th" scope="row">
                      {moment(row.date).format("YYYY-MM-DD h:mm:ss a")}
                    </TableCell>
                    <TableCell>{row.event || "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6}>
          {page > 1 ? (
            <Button
              onClick={() => setPage(page - 1)}
              variant="contained"
              color="primary"
            >
              Prev
            </Button>
          ) : (
            ""
          )}
          &nbsp;(<strong>Page {page}</strong>)&nbsp;
          {showNext ? (
            <Button
              onClick={() => setPage(page + 1)}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const UserHistoryView = () => {
  const { phone } = useParams();

  const classes = useStyles();

  const auth = useSelector(state => state.auth);

  return (
    <Page className={classes.root} title="User Stats">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <UserView phone={phone} token={auth.token} />
          </Grid>
        </Grid>

        <br />
        <br />

        <Grid container spacing={3}>
          <Grid item lg={6}>
            <RevenueHistoryView phone={phone} token={auth.token} />
          </Grid>
        </Grid>

        <br />
        <br />

        <Grid container spacing={3}>
          <Grid item lg={6}>
            <UserEventsView phone={phone} token={auth.token} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default UserHistoryView;

import { calculatePercentage, divideOrGetZero } from "../../utils/math";

export function calculateAdditionalStats(aggregateData) {
  const allAggregateData = [];
  for (const data of aggregateData.slice()) {
    data.cpc = divideOrGetZero(data.cost, data.clicks);
    data.click_percentage = calculatePercentage(data.clicks, data.sent);
    data.unsubscribe_percent = calculatePercentage(
      data.unsubscribes,
      data.sent
    );
    data.complaint_percentage = calculatePercentage(data.complaints, data.sent);

    allAggregateData.push(data);
  }
  return allAggregateData;
}
const clientInfo = [
  { domain: "fjobs.co", name: "717 Ads" },
  // { domain: "pjobs.co", name: "Connexus Digital" },
  // { domain: "zjobs.co", name: "Bright Fire" },
  { domain: "djobs.co", name: "PJ Centerprise" },
  // { domain: "gjobs.co", name: "VHM Network" },
  // { domain: "kjobs.co", name: "Castline Media" },
  { domain: "qujob.co", name: "QuickJobFinder", internal: true },
  { domain: "rjobs.co", name: "Rocketjobs // Test Ground", internal: true },
  // { domain: "jgjob.co", name: "Conversion Media Group" },
  { domain: "jobwc.co", name: "Best Online Jobs - QRN Corporate" },
  { domain: "jobqe.co", name: "ExpertJobMatch - JOBQE", internal: true },
  { domain: "hirem.co", name: "RocketJobs", internal: true },
  { domain: "ejmj.co", name: "ExpertJobMatch - EJMJ", internal: true },
  { domain: "jobmm.co", name: "Jobmatic", internal: true },
  { domain: "job-u.co", name: "Botson" },
  { domain: "ajobb.co", name: "FinUnited" },
  // { domain: "jobll.co", name: "Voltron" },
  // { domain: "jobrs.co", name: "MG Solutions" },
  { domain: "lcjob.co", name: "Apptness" },
  { domain: "bjobu.co", name: "DMS" }
  // { domain: "jobsl.co", name: "Shift44" }
];

if (process?.env?.NODE_ENV === "development") {
  clientInfo.push({ domain: "localhost:3000", name: "LOCAL" });
}
const clientInfoMap = {};

for (let i = 0; i < clientInfo.length; i++) {
  const client = clientInfo[i];
  clientInfoMap[client.domain] = client;
}

const internalDomains = clientInfo
  .filter(entry => entry.internal)
  .map(entry => entry.domain);
const partnerDomains = clientInfo
  .filter(entry => !entry.internal)
  .map(entry => entry.domain);
const allDomains = clientInfo.map(entry => entry.domain);

export { clientInfoMap, internalDomains, partnerDomains, allDomains };

export default clientInfo;

import { calculatePercentage } from "./math";

export function calculateTotals(rows, fields) {
  const totals = {};
  for (const field of fields) {
    totals[field] = 0;
  }

  for (const row of rows) {
    for (const field of fields) {
      totals[field] += parseFloat(row[field]) || 0;
    }
  }

  return totals;
}

export function calculatePercentagesForStats(values, fields) {
  const percentages = {};

  const config = {
    insert: { dividend: "insert", divisor: "total_processed_leads" },
    rejected: { dividend: "rejected", divisor: "total_processed_leads" },
    delivery: { dividend: "delivered", divisor: "total_send" },
    fail: { dividend: "failed", divisor: "total_send" },
    unsub: { dividend: "unsub", divisor: "total_send" },
    welcome_click: { dividend: "welcome_click", divisor: "welcome_send" },
    alerts_click: { dividend: "alerts_click", divisor: "alerts_send" },
    total_click: { dividend: "total_click", divisor: "total_send" },
    time_inactivity: { dividend: "time_inactivity", divisor: "total_send" },

    welcome_open: { dividend: "welcome_open", divisor: "welcome_send" },
    alerts_open: { dividend: "alerts_open", divisor: "alerts_send" },
    total_open: { dividend: "total_open", divisor: "total_send" }
  };

  for (const field of fields) {
    const fieldConfig = config[field];
    percentages[`${field}_percentage`] = calculatePercentage(
      values[fieldConfig.dividend],
      values[fieldConfig.divisor]
    );
  }

  return percentages;
}

export function getAlternativeFields(obj) {
  const fields = {
    insert: obj.insert || obj.accepted || 0,

    welcome_send: obj.welcome_send || obj.welcome || 0,
    alerts_send: obj.alerts_send || obj.send || obj.alerts || 0,

    welcome_click: obj.welcome_click || 0,
    alerts_click: obj.alerts_click || obj.click || 0,

    purged_phones: obj.purged_phones || obj.blacklisted_and_purged_phones || 0,
    ping_type_reactivate:
      (obj.ping_type?.reactivate || 0) +
      (obj.ping_type?.duplicate_time_inactivity || 0)
  };

  fields.total_send =
    fields.total_send || fields.welcome_send + fields.alerts_send;
  fields.total_click =
    fields.total_click || fields.welcome_click + fields.alerts_click;

  return fields;
}

export function getAdditionalStats(data) {
  return (
    data.additional_stats || {
      revenue_per_record: data.revenue_per_record,
      ls_revenue_per_record: data.ls_revenue_per_record,
      partner_revenue_per_record: data.partner_revenue_per_record,
      revenue_per_mile: data.revenue_per_mile,
      ls_revenue_per_mile: data.ls_revenue_per_mile,
      partner_revenue_per_mile: data.partner_revenue_per_mile,
      earning_per_click: data.earning_per_click,
      ls_earning_per_click: data.ls_earning_per_click,
      partner_earning_per_click: data.partner_earning_per_click
    }
  );
}

import getConfig from "../services/config";

export const getNeuvooPopupURL = (userData, keyword) => {
  const { project_name = "NA" } = getConfig();
  const { zip } = userData;

  return encodeURI(`https://www.talent.com/jobs?k=${keyword}&l=${zip}&source=dtl_localstaffing&utm_source=dtl&subid=${project_name}`);
};


export const getTalrooPopupURL = (userData, keyword) => {
  const { project_name = "NA" } = getConfig();
  const { zip, list, revenueId } = userData;

  return encodeURI(`https://www.jobs2careers.com/results3.php?c1=67&q=${keyword}&l=${zip}&t1=${project_name}&t2=${list}&t3=${revenueId}&aff_id=7052`);
};


import React from "react";
import {
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography
} from "@material-ui/core";

import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  list: {
    paddingLeft: theme.spacing(3)
  }
}));

const SendingLeads = ({ features, token, apiUrl }) => {
  let View;

  if (features.includes("sms") && features.includes("email")) {
    View = <SendingLeadsToSMSAndEmail token={token} apiUrl={apiUrl} />;
  } else if (features.includes("sms")) {
    View = <SendingLeadsToSMS token={token} apiUrl={apiUrl} />;
  } else if (features.includes("email")) {
    View = <SendingLeadsToEmail token={token} apiUrl={apiUrl} />;
  }

  return View;
};

const SendingLeadsToSMS = ({ token, apiUrl }) => {
  const classes = useStyles();
  const endpoint = apiUrl + "/v2/leads/insert";

  return (
    <Card>
      <CardHeader title="Sending Leads" />

      <CardContent>
        <Box>
          <Typography component="span" variant="body1">
            We need to send a <strong>POST</strong> request to the endpoint with
            the lead data as a <strong>JSON</strong> payload. Each lead needs to
            have these following fields:
            <br />
            <br />
            <ul className={classes.list}>
              <li>
                <strong>first_name</strong> - First name of the user
              </li>

              <li>
                <strong>last_name</strong> - Last name of the user
              </li>

              <li>
                <strong>phone</strong> - Valid US phone number
              </li>

              <li>
                <strong>search</strong> - Job search keyword
              </li>

              <li>
                <strong>zip</strong> - Zip code of the user
              </li>
              <li>
                <strong>city</strong> - (optional) - City of the user
              </li>
              <li>
                <strong>state</strong> - (optional) - State of the user
              </li>

              <li>
                <strong>utm_source</strong> - UTM Source code for tracking lead
                acquisition
              </li>

              <li>
                <strong>domain</strong> - The domain name where the user signed
                up on
              </li>

              <li>
                <strong>company</strong> - (optional) - Preferred company for
                jobs. ie. Walmart
              </li>

              <li>
                <strong>ip_address</strong> - IP Address of the user
              </li>

              <li>
                <strong>signup_url</strong> - (optional) - The url where the
                user signed up at.
              </li>

              <li>
                <strong>tcpa_language</strong> - (optional) - The tcpa language
                that is currently being used on the site
              </li>
            </ul>
            <br />
            <br />
            Here's an example JSON snippet with a sample lead data:
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
    "first_name": "Bruce",
    "last_name": "Wayne",
    "phone": "0000000000",
    "ip_address": "99.203.0.62",
    "search": "customer service",
    "utm_source": "<source name>",
    "company": "walmart",
    "domain": "<domain>",
    "zip": "48341",
    "city": "Miami",
    "state": "Florida",
    "signup_url": "http://example.com/signup",
    "tcpa_language": "<tcpa message>"
}`}
            </SyntaxHighlighter>
            <br />
            <br />
            Each request needs to contain the <strong>Token</strong> header for
            authentication. The token displayed in the above section should be
            used as the value for the header.
            <br />
            <br />
            Here's an example with cURL:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`curl --location --request POST '${endpoint}' \
  --header 'Content-Type: application/json' \
  --header 'Token: ${token}' \
  --data-raw '{
      "first_name": "First Name",
      "last_name": "Last Name",
      "phone": "0000000000",
      "ip_address": "127.0.0.1",
      "search": "customer service",
      "utm_source": "<source name>",
      "domain": "<domain>",
      "zip": "48341",
      "city": "Miami",
      "state": "Florida",
      "signup_url": "http://example.com/signup",
      "tcpa_language": "<tcpa message>"
  }'`}
            </SyntaxHighlighter>
            <br />
            On successful lead insertion, a http status code of{" "}
            <strong>200 OK</strong> and status as "success" would be returned
            along with the lead data as the response.
            <br />
            <br />
            On error, http status code would be <strong>200 OK </strong> but the
            response body would contain status as "failed" and a list named{" "}
            <strong>errors</strong> which should describe any errors which have
            occurred.
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
  "data": {
      "first_name": "First Name",
      "last_name": "Last Name",
      "phone": "0000000000",
      "ip_address": "127.0.0.1",
      "search": "customer service",
      "utm_source": "<source name>",
      "domain": "<domain>",
      "zip": "48341",
      "city": "Miami",
      "state": "Florida",
      "signup_url": "http://example.com/signup",
      "tcpa_language": "<tcpa message>"
  },
  "sms": {
      "status": "success"
  }
}`}
            </SyntaxHighlighter>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const SendingLeadsToEmail = ({ token, apiUrl }) => {
  const classes = useStyles();
  const endpoint = apiUrl + "/v2/leads/insert";

  return (
    <Card>
      <CardHeader title="Sending Leads" />

      <CardContent>
        <Box>
          <Typography component="span" variant="body1">
            We need to send a <strong>POST</strong> request to the endpoint with
            the lead data as a <strong>JSON</strong> payload. Each lead needs to
            have these following fields:
            <br />
            <br />
            <ul className={classes.list}>
              <li>
                <strong>first_name</strong> - (optional) - First name of the
                user
              </li>

              <li>
                <strong>last_name</strong> - (optional) - Last name of the user
              </li>

              <li>
                <strong>email</strong> - Valid email address
              </li>

              <li>
                <strong>phone</strong> - (optional) - Valid US phone number
              </li>

              <li>
                <strong>search</strong> - (optional) - Job search keyword
              </li>

              <li>
                <strong>zip</strong> - (optional) - Zip code of the user
              </li>
              <li>
                <strong>city</strong> - (optional) - City of the user
              </li>
              <li>
                <strong>state</strong> - (optional) - State of the user
              </li>

              <li>
                <strong>utm_source</strong> - UTM Source code for tracking lead
                acquisition
              </li>

              <li>
                <strong>domain</strong> - (optional) - The domain name where the
                user signed up on
              </li>

              <li>
                <strong>company</strong> - (optional) - Preferred company for
                jobs. ie. Walmart
              </li>

              <li>
                <strong>ip_address</strong> - (optional) - IP Address of the
                user
              </li>

              <li>
                <strong>signup_url</strong> - (optional) - The url where the
                user signed up at.
              </li>

              <li>
                <strong>tcpa_language</strong> - (optional) - The tcpa language
                that is currently being used on the site
              </li>
            </ul>
            <br />
            <br />
            Here's an example JSON snippet with a sample lead data:
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
    "first_name": "Bruce",
    "last_name": "Wayne",
    "email": "myawesomeemail@gmail.com",
    "phone": "0000000000",
    "ip_address": "99.203.0.62",
    "search": "customer service",
    "utm_source": "<source name>",
    "company": "walmart",
    "domain": "<domain>",
    "zip": "48341",
    "city": "Miami",
    "state": "Florida",
    "signup_url": "http://example.com/signup",
    "tcpa_language": "<tcpa message>"
  }`}
            </SyntaxHighlighter>
            <br />
            <br />
            Each request needs to contain the <strong>Token</strong> header for
            authentication. The token displayed in the above section should be
            used as the value for the header.
            <br />
            <br />
            Here's an example with cURL:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`curl --location --request POST '${endpoint}' \
  --header 'Content-Type: application/json' \
  --header 'Token: ${token}' \
  --data-raw '{
      "first_name": "First Name",
      "last_name": "Last Name",
      "email": "myawesomeemail@gmail.com",
      "phone": "0000000000",
      "ip_address": "127.0.0.1",
      "search": "customer service",
      "utm_source": "<source name>",
      "domain": "<domain>",
      "zip": "48341",
      "city": "Miami",
      "state": "Florida",
      "signup_url": "http://example.com/signup",
      "tcpa_language": "<tcpa message>"
  }'`}
            </SyntaxHighlighter>
            <br />
            On successful lead insertion, a http status code of{" "}
            <strong>200 OK</strong> and status as "success" would be returned
            along with the lead data as the response.
            <br />
            <br />
            On error, http status code would be <strong>200 OK </strong> but the
            response body would contain status as "failed" and a list named{" "}
            <strong>errors</strong> which should describe any errors which have
            occurred.
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
  "data": {
      "first_name": "First Name",
      "last_name": "Last Name",
      "email": "myawesomeemail@gmail.com",
      "phone": "0000000000",
      "ip_address": "127.0.0.1",
      "search": "customer service",
      "utm_source": "<source name>",
      "domain": "<domain>",
      "zip": "48341",
      "city": "Miami",
      "state": "Florida",
      "signup_url": "http://example.com/signup",
      "tcpa_language": "<tcpa message>"
  },
  "email": {
      "status": "success"
  }
}`}
            </SyntaxHighlighter>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const SendingLeadsToSMSAndEmail = ({ token, apiUrl }) => {
  const classes = useStyles();
  const endpoint = apiUrl + "/v2/leads/insert";

  return (
    <Card>
      <CardHeader title="Sending Leads" />

      <CardContent>
        <Box>
          <Typography component="span" variant="body1">
            We need to send a <strong>POST</strong> request to the endpoint with
            the lead data as a <strong>JSON</strong> payload. Each lead needs to
            have these following fields:
            <br />
            <br />
            <ul className={classes.list}>
              <li>
                <strong>first_name</strong> - First name of the user
              </li>

              <li>
                <strong>last_name</strong> - Last name of the user
              </li>

              <li>
                <strong>phone</strong> - Valid US phone number
              </li>

              <li>
                <strong>email</strong> - Valid email address
              </li>

              <li>
                <strong>search</strong> - Job search keyword
              </li>

              <li>
                <strong>zip</strong> - Zip code of the user
              </li>
              <li>
                <strong>city</strong> - (optional) - City of the user
              </li>
              <li>
                <strong>state</strong> - (optional) - State of the user
              </li>

              <li>
                <strong>utm_source</strong> - UTM Source code for tracking lead
                acquisition
              </li>

              <li>
                <strong>domain</strong> - The domain name where the user signed
                up on
              </li>

              <li>
                <strong>company</strong> - (optional) - Preferred company for
                jobs. ie. Walmart
              </li>

              <li>
                <strong>ip_address</strong> - IP Address of the user
              </li>

              <li>
                <strong>signup_url</strong> - (optional) - The url where the
                user signed up at.
              </li>

              <li>
                <strong>tcpa_language</strong> - (optional) - The tcpa language
                that is currently being used on the site
              </li>
            </ul>
            <br />
            <br />
            Here's an example JSON snippet with a sample lead data:
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
    "first_name": "Bruce",
    "last_name": "Wayne",
    "phone": "0000000000",
    "email": "myawesomeemail@gmail.com",
    "ip_address": "99.203.0.62",
    "search": "customer service",
    "utm_source": "<source name>",
    "company": "walmart",
    "domain": "<domain>",
    "zip": "48341",
    "city": "Miami",
    "state": "Florida",
    "signup_url": "http://example.com/signup",
    "tcpa_language": "<tcpa message>"
}`}
            </SyntaxHighlighter>
            <br />
            <br />
            Each request needs to contain the <strong>Token</strong> header for
            authentication. The token displayed in the above section should be
            used as the value for the header.
            <br />
            <br />
            Here's an example with cURL:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`curl --location --request POST '${endpoint}' \
  --header 'Content-Type: application/json' \
  --header 'Token: ${token}' \
  --data-raw '{
      "first_name": "First Name",
      "last_name": "Last Name",
      "phone": "0000000000",
      "email": "myawesomeemail@gmail.com",
      "ip_address": "127.0.0.1",
      "search": "customer service",
      "utm_source": "<source name>",
      "domain": "<domain>",
      "zip": "48341",
      "city": "Miami",
      "state": "Florida",
      "signup_url": "http://example.com/signup",
      "tcpa_language": "<tcpa message>"
  }'`}
            </SyntaxHighlighter>
            <br />
            On successful lead insertion, a http status code of{" "}
            <strong>200 OK</strong> and status as "success" would be returned
            along with the lead data as the response.
            <br />
            <br />
            On error, http status code would be <strong>200 OK </strong> but the
            response body would contain status as "failed" and a list named{" "}
            <strong>errors</strong> which should describe any errors which have
            occurred.
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
  "data": {
      "first_name": "First Name",
      "last_name": "Last Name",
      "email": "myawesomeemail@gmail.com",
      "phone": "0000000000",
      "ip_address": "127.0.0.1",
      "search": "customer service",
      "utm_source": "<source name>",
      "domain": "<domain>",
      "zip": "48341",
      "city": "Miami",
      "state": "Florida",
      "signup_url": "http://example.com/signup",
      "tcpa_language": "<tcpa message>"
  },
  "phone": {
      "status": "success",
  },
  "email": {
      "status": "success"
  }
}`}
            </SyntaxHighlighter>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SendingLeads;

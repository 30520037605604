import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Typography
} from "@material-ui/core";

import DashboardIcon from "@material-ui/icons/Dashboard";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import PeopleIcon from "@material-ui/icons/People";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import EmailIcon from "@material-ui/icons/Email";
import MessageIcon from "@material-ui/icons/Message";
import BlockIcon from "@material-ui/icons/Block";

import { refreshAuth } from "../../../redux/auth";

import NavItem from "./NavItem";
import { getGravatarURL } from "../../../utils/user";
import { findAccessibleViews } from "../../../utils/permissions";

import getConfig from "../../../services/config";

const config = getConfig();

const menuItems = [
  {
    href: "/app/admin/dashboard",
    icon: DashboardIcon,
    title: "SMS Dashboard",
    name: "sms:dashboard"
  },

  {
    href: "/app/admin/lists",
    icon: ListAltIcon,
    title: "Lists",
    requireAdmin: true,
    name: "sms:list"
  },

  {
    href: "/app/admin/accounting",
    icon: AccountBalanceIcon,
    title: "Accounting",
    requireAdmin: true,
    name: "accounting"
  },

  // {
  //   href: "/app/admin/companies",
  //   icon: BusinessIcon,
  //   title: "Companies",
  //   requireAdmin: true,
  //   name: "sms:companies"
  // },

  {
    href: "/app/admin/health-check",
    icon: CloudDoneIcon,
    title: "Health Check",
    requireAdmin: true,
    name: "health_check"
  },

  {
    href: "/app/admin/graph",
    icon: ShowChartIcon,
    title: "Graphs",
    requireAdmin: true,
    name: "sms:graph"
  },

  {
    href: "/app/admin/stats/user",
    icon: PeopleIcon,
    title: "User Stats",
    requireAdmin: true,
    name: "sms:user_stats"
  },

  {
    href: "/app/admin/blacklist",
    icon: BlockIcon,
    title: "Blacklist",
    requireAdmin: true,
    name: "sms:user_stats"
  },

  {
    href: "/app/admin/incoming-messages",
    icon: MessageIcon,
    title: "Incoming Messages",
    requireAdmin: true,
    name: "sms:incoming_message"
  },

  {
    href: "/app/admin/docs",
    icon: DescriptionIcon,
    title: "Docs",
    name: "docs"
  }

  /*
  
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers'
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Products'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  },
  {
    href: '/login',
    icon: LockIcon,
    title: 'Login'
  },
  {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register'
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error'
  }

  */
];

if (config.features?.includes("email")) {
  menuItems.splice(2, 0, {
    href: "/app/admin/email-dashboard",
    icon: EmailIcon,
    title: "Email Dashboard",
    name: "email:dashboard"
  });
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)"
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();

  const userData = useSelector(state => state.auth);
  if (userData.refresh) {
    dispatch(refreshAuth({ token: userData.token }));
  }

  const accessibleViews = findAccessibleViews(userData.access);
  const items = [];
  for (const item of menuItems) {
    if (accessibleViews.includes(item.name)) {
      items.push(item);
    }
  }

  const user = {
    avatar: getGravatarURL(userData.email),
    jobTitle: "Admin",
    name: userData.name || "NA",
    company: userData.company || "NA"
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/app/account"
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {user.name}
        </Typography>

        <Typography className={classes.name} color="textPrimary" variant="h6">
          {user.company}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default NavBar;

import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { calculateTotals, getAlternativeFields } from "../../utils/stats";
import DataTable from "../../components/DataTable";
import { calculatePercentage } from "../../utils/math";

const Gateway = ({ className, totals: stats, ...rest }) => {
  // console.log(totals)

  if (!stats) {
    return null;
  }

  const populatedData = [];
  for (const [name, values] of Object.entries(stats)) {
    populatedData.push({ name, ...values, ...getAlternativeFields(values) });
  }

  const totalFields = [
    "cost",
    "total_send",
    "unsub",
    "time_inactivity",
    "total_unsub"
  ];
  const totals = calculateTotals(populatedData, totalFields);

  const adminColumns = [
    {
      name: "name",
      label: "Name"
    },

    {
      name: "welcome_send",
      label: "Welcome Send"
    },
    {
      name: "alerts_send",
      label: "Alerts Send"
    },
    {
      name: "total_send",
      label: "Total Send"
    },

    {
      name: "cost",
      label: "Cost",
      options: {
        footerData: `$${totals.cost.toFixed(4)}`
      }
    },
    {
      name: "unsub",
      label: "Unsub",
      options: {
        footerData: `${totals.unsub} (${calculatePercentage(
          totals.unsub,
          totals.total_send
        ).toFixed(2)}%)`
      }
    },
    {
      name: "time_inactivity",
      label: "Time Inactivity",
      options: {
        footerData: `${totals.time_inactivity} (${calculatePercentage(
          totals.time_inactivity,
          totals.total_send
        ).toFixed(2)}%)`
      }
    },
    {
      name: "total_unsub",
      label: "Total Unsub",
      options: {
        footerData: `${totals.total_unsub} (${calculatePercentage(
          totals.total_unsub,
          totals.total_send
        ).toFixed(2)}%)`
      }
    }
  ];

  const adminData = [];
  for (const row of populatedData) {
    adminData.push({
      name: row.name,

      welcome_send: row.welcome_send || 0,
      alerts_send: row.alerts_send || 0,
      total_send: row.total_send || 0,

      cost: `$${(row.cost || 0).toFixed(4)}`,
      unsub: `${row.unsub} (${calculatePercentage(
        row.unsub,
        row.total_send
      ).toFixed(2)}%)`,
      time_inactivity: `${row.time_inactivity} (${calculatePercentage(
        row.time_inactivity,
        row.total_send
      ).toFixed(2)}%)`,
      total_unsub: `${row.total_unsub} (${calculatePercentage(
        row.total_unsub,
        row.total_send
      ).toFixed(2)}%)`
    });
  }

  return (
    <PerfectScrollbar>
      <DataTable
        title="Gateway Stats"
        columns={adminColumns}
        data={adminData.reverse()}
      />
    </PerfectScrollbar>
  );
};

Gateway.propTypes = {};

export default Gateway;

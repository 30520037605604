import React from "react";
import {
  Grid,
  Container,
  makeStyles,
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  TextField
} from "@material-ui/core";
import { useSelector } from "react-redux";

import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Page from "src/components/Page";

import Ping from "./components/Ping";
import SendingLeads from "./components/SendingLeads";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  list: {
    paddingLeft: theme.spacing(3)
  }
}));

const APIDetails = ({ token, apiUrl }) => {
  const insertLeadEndpoint = apiUrl + "/v1/leads/insert";
  const stopLeadEndpoint = apiUrl + "/v1/partners/stop";

  return (
    <Card>
      <CardHeader title="API Token" />

      <CardContent>
        <Box>
          <TextField
            fullWidth
            helperText="Use this token to make API requests"
            label="Token"
            value={token}
            variant="outlined"
          />
        </Box>

        <br />

        {/* <Box>
          <TextField
            fullWidth
            helperText="Make a POST request with JSON payload here"
            label="Insert Lead"
            value={insertLeadEndpoint}
            variant="outlined"
          />
        </Box>

        <br />

        <Box>
          <TextField
            fullWidth
            helperText="Make a POST request with JSON payload here"
            label="Opt out & Blacklist"
            value={stopLeadEndpoint}
            variant="outlined"
          />
        </Box> */}
      </CardContent>
    </Card>
  );
};

const BlacklistingNumbers = ({ token, apiUrl }) => {
  const endpoint = apiUrl + "/v1/blacklist/phone";

  return (
    <Card>
      <CardHeader title="Blacklisting Numbers" />

      <CardContent>
        <Box>
          <Typography component="span" variant="body1">
            We allow our partners to opt out certain numbers and add them to the blacklist so in future, that number can
            never again be added to the list. This ensures permanent opt out from our list and often helps conforming to
            local laws.
            <br />
            <br />
            To opt out and blacklist a number, please send a <strong>POST</strong> request to the endpoint with the
            phone number wrapped in a <strong>JSON</strong> payload.
            <br />
            <br />
            Here's an example JSON snippet with a sample lead data:
            <br />
            <br />
            <SyntaxHighlighter language="json" style={darcula}>
              {`{
  "phone": "0000000000"
}`}
            </SyntaxHighlighter>
            <br />
            <br />
            Each request needs to contain the <strong>Token</strong> header for authentication. The token displayed in
            the above section should be used as the value for the header.
            <br />
            <br />
            Here's an example with cURL:
            <br />
            <br />
            <SyntaxHighlighter language="bash" style={darcula}>
              {`curl --location --request POST '${endpoint}' \
--header 'Content-Type: application/json' \
--header 'Token: ${token}' \
--data-raw '{
    "phone": "0000000000"
}'`}
            </SyntaxHighlighter>
            <br />
            <br />
            If the request is successful, we will remove that number from our active list and add it to a restricted
            blacklist. In future, if we get any leads with this number, we would reject it with an error message stating
            that this number is blacklisted and won't be accepted.
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const DocsView = () => {
  const classes = useStyles();
  const auth = useSelector((state) => state.auth);
  const config = useSelector((state) => state.config);

  // console.log(config);

  return (
    <Page className={classes.root} title="Docs">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={10} md={6} xl={4} xs={6}>
            <APIDetails token={auth.token} apiUrl={config.api_url} />
          </Grid>
        </Grid>

        {config.docs.show_ping ? (
          <Grid container spacing={3}>
            <Grid item lg={10} md={6} xl={4} xs={6}>
              <Ping pingClassification={config.docs.show_ping_classification} features={config.features}
                    token={auth.token} apiUrl={config.api_url} />
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        <Grid container spacing={3}>
          <Grid item lg={10} md={6} xl={4} xs={6}>
            <SendingLeads features={config.features} token={auth.token} apiUrl={config.api_url} />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item lg={10} md={6} xl={4} xs={6}>
            <BlacklistingNumbers token={auth.token} apiUrl={config.api_url} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DocsView;

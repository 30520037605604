import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import Chip from "@material-ui/core/Chip";
import { calculateTotals, getAlternativeFields } from "../../utils/stats";
import { divideOrGetZero } from "../../utils/math";
import DataTable from "../../components/DataTable";

const CardHeaderComponent = (text, internal) => {
  return (
    // TODO: Refactor style using class
    <div style={{ fontWeight: "bold", fontFamily: "Roboto" }}>
      {text} &nbsp;{" "}
      {internal ? <Chip label="Internal" color="secondary" /> : ""}
    </div>
  );
};

const Revenue = ({ client, ...rest }) => {
  const { revenue, name: clientName, domain, internal } = client;

  if (!revenue) {
    return null;
  }

  const populatedData = [];
  for (const row of revenue) {
    populatedData.push({ ...row, ...getAlternativeFields(row) });
  }

  const totalFields = [
    "insert",
    "cost",
    "revenue",
    "ls_revenue",
    "ls_profit",
    "partner_profit"
  ];
  const totals = calculateTotals(populatedData, totalFields);

  const adminColumns = [
    {
      name: "date",
      label: "Date"
    },
    {
      name: "insert",
      label: "Total Accepts"
    },
    {
      name: "welcome_send",
      label: "Welcome Send"
    },
    {
      name: "alerts_send",
      label: "Alerts Send"
    },
    {
      name: "total_send",
      label: "Total Send"
    },
    {
      name: "cost",
      label: "Cost",
      options: {
        footerData: `$${totals.cost.toFixed(4)}`
      }
    },
    {
      name: "revenue",
      label: "Revenue",
      options: {
        footerData: `$${totals.revenue.toFixed(4)}`
      }
    },
    {
      name: "partner_profit",
      label: "Partner Profit",
      options: {
        footerData: `$${totals.partner_profit.toFixed(4)}`
      }
    },
    {
      name: "ls_profit",
      label: "LS Profit",
      options: {
        footerData: `$${totals.ls_profit.toFixed(4)}`
      }
    },
    {
      name: "partner_rpl",
      label: "Partner RPL",
      options: {
        footerData: divideOrGetZero(
          totals.partner_profit,
          totals.insert
        ).toFixed(4)
      }
    },
    {
      name: "ls_rpl",
      label: "LS RPL",
      options: {
        footerData: divideOrGetZero(totals.ls_profit, totals.insert).toFixed(4)
      }
    }
  ];

  const adminData = [];
  for (const row of populatedData) {
    adminData.push({
      date: row.date,
      insert: row.insert || 0,

      welcome_send: row.welcome_send || 0,
      alerts_send: row.alerts_send || 0,
      total_send: row.total_send || 0,

      cost: `$${(row.cost || 0).toFixed(4)}`,
      revenue: `$${(row.revenue || 0).toFixed(4)}`,
      partner_profit: `$${(row.partner_profit || 0).toFixed(4)}`,
      ls_profit: `$${(row.ls_profit || 0).toFixed(4)}`,

      partner_rpl: divideOrGetZero(row.partner_profit, row.insert).toFixed(4),
      ls_rpl: divideOrGetZero(row.ls_profit, row.insert).toFixed(4)
    });
  }

  let cardHeaderText = `${clientName} (${domain})`;

  return (
    <PerfectScrollbar>
      <DataTable
        title={CardHeaderComponent(cardHeaderText, internal)}
        columns={adminColumns}
        data={adminData.reverse()}
        options={{ rowsPerPage: 30, rowsPerPageOptions: [30, 50, 100] }}
      />
    </PerfectScrollbar>
  );
};

Revenue.propTypes = {};

export default Revenue;

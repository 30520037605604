import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import moment from "moment";

import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";

import { useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Page from "src/components/Page";
import Ads from "src/views/lists/Ads";

import StatsBySource from "./StatsBySource";
import StatsByTemplate from "./StatsByTemplate";
import StatsByCarrier from "./StatsByCarrier";
import StatsByOperatingSystem from "./StatsByOperatingSystem";
import StatsByKeyword from "./StatsByKeyword";
import StatsByAdType from "./StatsByAdType";
import Aggregate from "./Aggregate";
import { fetchInformationForList } from "src/services/lists";
import DateRangePicker from "src/components/DateRangePicker";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const ListsDashboardView = () => {
  const classes = useStyles();
  // TODO: Will refactor
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const auth = useSelector(state => state.auth);
  const { token, access: userAccess } = auth;
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState(null);
  const { listId } = useParams();

  useEffect(() => {
    async function fetchListInformation() {
      setLoading(true);
      const list = await fetchInformationForList(token, listId);
      setList(list);
      setLoading(false);
    }

    fetchListInformation().then(() => {});
  }, [token, listId]);

  const [dateRange, setDateRange] = useState({
    start: moment()
      .subtract(15, "days")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD")
  });

  function refresh() {
    setRefreshTrigger(new Date());
  }

  if (loading) {
    return <LoadingSpinnerForStats title="List Information" />;
  }
  if (!list) {
    return <h1>List not found</h1>;
  }

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item md={1}>
            <Button
              component={NavLink}
              to="/app/admin/lists"
              color="primary"
              variant="contained"
              startIcon={<ArrowBackIcon />}
            />
          </Grid>

          <Grid item md={3}>
            <Typography variant="h4">{list.name}</Typography>
          </Grid>
        </Grid>

        <Grid item md={10}>
          <DateRangePicker
            start={dateRange.start}
            end={dateRange.end}
            onChange={values => {
              setDateRange(values);
            }}
          />
        </Grid>

        <Grid container spacing={2}>
          {userAccess["sms:list:aggregate"] ? (
            <Grid item md={12}>
              <Aggregate
                token={token}
                listId={listId}
                dateRange={dateRange}
                permission={userAccess["sms:list:aggregate"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:list:ads"] ? (
            <Grid item md={12}>
              <Ads
                token={token}
                listid={listId}
                dateRange={dateRange}
                refresh={refresh}
                refreshTrigger={refreshTrigger}
                permission={userAccess["sms:list:ads"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:list:stats_by_template"] ? (
            <Grid item md={12}>
              <StatsByTemplate
                token={token}
                listId={listId}
                dateRange={dateRange}
                refreshTrigger={refreshTrigger}
                permission={userAccess["sms:list:stats_by_template"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:list:stats_by_source"] ? (
            <Grid item md={12}>
              <StatsBySource
                token={token}
                listId={listId}
                dateRange={dateRange}
                permission={userAccess["sms:list:stats_by_source"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:list:stats_by_carrier"] ? (
            <Grid item md={12}>
              <StatsByCarrier
                token={token}
                listId={listId}
                dateRange={dateRange}
                permission={userAccess["sms:list:stats_by_carrier"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:list:stats_by_operating_system"] ? (
            <Grid item md={12}>
              <StatsByOperatingSystem
                token={token}
                listId={listId}
                dateRange={dateRange}
                permission={userAccess["sms:list:stats_by_operating_system"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:list:stats_by_keyword"] ? (
            <Grid item md={12}>
              <StatsByKeyword
                token={token}
                listId={listId}
                dateRange={dateRange}
                permission={userAccess["sms:list:stats_by_keyword"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:list:stats_by_ad_type"] ? (
            <Grid item md={12}>
              <StatsByAdType
                token={token}
                listId={listId}
                dateRange={dateRange}
                refreshTrigger={refreshTrigger}
                permission={userAccess["sms:list:stats_by_ad_type"]}
              />
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Page>
  );
};

export default ListsDashboardView;

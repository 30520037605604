import React, { useEffect, useState } from "react";
import { Container, Grid, Box, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";

import Page from "src/components/Page";
import DateRangePicker from "src/components/DateRangePicker";

import TopRow from "./TopRow";
import SecondRow from "./SecondRow";
import StatsBySource from "./StatsBySource";
import StatsByCarrier from "./StatsByCarrier";
import StatsByKeyword from "./StatsByKeyword";
import StatsByOperatingSystem from "./StatsByOperatingSystem";
import StatsByAdType from "./StatsByAdType";
import RecentLeads from "./RecentLeads";

import { fetchNotice } from "../../services/notice";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

// const refresh = (dispatch, auth, dateRange) => {
//   dispatch(
//     fetchRevenue({
//       start: dateRange.start,
//       end: dateRange.end,
//       token: auth.token
//     })
//   );
// };

const Dashboard = () => {
  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const userAccess = auth.access;
  const [notice, setNotice] = useState("");
  // const dispatch = useDispatch();

  // console.log(config);

  const [dateRange, setDateRange] = useState({
    start: moment()
      .subtract(15, "days")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD")
  });

  useEffect(() => {
    fetchNotice(auth.token)
      .then(data => {
        setNotice(data.notice || "");
      })
      .catch(err => {
        console.error(err);
      });
  });

  // Update redux store so children get refreshed
  // useEffect(() => {
  //   refresh(dispatch, auth, dateRange);
  // }, [dispatch, auth, dateRange]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <DateRangePicker
          start={dateRange.start}
          end={dateRange.end}
          onChange={values => {
            setDateRange(values);
          }}
        />

        {notice ? (
          <Box>
            <h4 style={{ textAlign: "center" }}>{notice}</h4>
            <br />
          </Box>
        ) : null}

        <Grid container spacing={3}>
          {userAccess["sms:aggregate"] ? (
            <Grid item md={12}>
              <TopRow
                token={auth.token}
                dateRange={dateRange}
                permission={userAccess["sms:aggregate"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:additional_stats"] ? (
            <Grid item md={12}>
              <SecondRow
                token={auth.token}
                dateRange={dateRange}
                permission={userAccess["sms:additional_stats"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:stats_by_source"] ? (
            <Grid item md={12}>
              <StatsBySource
                dateRange={dateRange}
                auth={auth}
                permission={userAccess["sms:stats_by_source"]}
              />
            </Grid>
          ) : null}

          {/* {config.features && config.features.includes("email") ? (
            <Grid item lg={12} md={12} xl={3} xs={12}>
              <EmailStats isAdmin={isAdmin} dateRange={dateRange} auth={auth} />
            </Grid>
          ) : null} */}
          {userAccess["sms:stats_by_carrier"] ? (
            <Grid item md={12}>
              <StatsByCarrier
                dateRange={dateRange}
                auth={auth}
                permission={userAccess["sms:stats_by_carrier"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:stats_by_keyword"] ? (
            <Grid item md={12}>
              <StatsByKeyword
                dateRange={dateRange}
                auth={auth}
                permission={userAccess["sms:stats_by_keyword"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:stats_by_operating_system"] ? (
            <Grid item md={12}>
              <StatsByOperatingSystem
                dateRange={dateRange}
                auth={auth}
                permission={userAccess["sms:stats_by_operating_system"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:stats_by_ad_type"] ? (
            <Grid item md={12}>
              <StatsByAdType
                dateRange={dateRange}
                auth={auth}
                permission={userAccess["sms:stats_by_ad_type"]}
              />
            </Grid>
          ) : null}

          {userAccess["sms:recent_leads"] ? (
            <Grid item md={12}>
              <RecentLeads
                token={auth.token}
                permission={userAccess["sms:recent_leads"]}
              />
            </Grid>
          ) : null}

          {/*
          
          {isAdmin ? (
            <Grid item lg={12} md={12} xl={9} xs={12}>
              <StatsByTemplate />
            </Grid>
          ) : null}

          {isAdmin ? (
            <Grid item lg={12} md={12} xl={9} xs={12}>
              <Ads isAdmin={isAdmin} />
            </Grid>
          ) : null} */}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;

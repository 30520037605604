import axios from "axios";

import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;


export async function fetchIncomingMessages(token, skip, limit, filters) {
  const endpoint = apiURL + "/v1/incoming-messages/list";
  const response = await axios.post(
    endpoint,
    {
      skip,
      limit,
      filters
    },
    { headers: { token } }
  );

  return response.data;
}

export async function deleteIncomingMessages(token, phone) {
  const endpoint = apiURL + "/v1/incoming-messages/delete";
  const response = await axios.post(
    endpoint,
    {
      phone
    },
    { headers: { token } }
  );

  return response.data;
}

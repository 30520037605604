import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  blockSource,
  getBlockedSources,
  unblockSource
} from "src/services/blacklist";
import { fetchStatsBySourceForDateRange } from "src/services/stats_by_source";
import DataTable from "../../components/DataTable";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const StatsBySource = ({ dateRange, auth, permission }) => {
  const [stats, setStats] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [blockedSources, setBlockedSources] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true);
      const sourceStats = await fetchStatsBySourceForDateRange(
        auth.token,
        dateRange.start,
        dateRange.end
      );
      setStats(sourceStats.stats);

      const blockedSourceData = {};
      const blockedData = await getBlockedSources(auth.token);
      for (const item of blockedData.sources) {
        const source = item.source;
        if (!blockedSourceData[source]) {
          blockedSourceData[source] = true;
        }
      }
      setBlockedSources(blockedSourceData);
      setLoading(false);
    }

    loadInitialData().then(() => {});
  }, [auth.token, dateRange, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Source" />;
  }
  if (!stats) {
    console.log("No Stats by source available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "source", label: "Source" },
      ...getCommonAdminHeaders(stats, { action: true })
    ];
  } else {
    columns = [
      { name: "source", label: "Source" },
      { name: "partner_profit", label: "Profit" }
    ];
  }

  let data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      const isBlocked = blockedSources[row.source] || false;
      const action = {
        isBlocked,
        block: e => {
          blockSource(auth.token, row.source).then(
            e => setRefreshTrigger(new Date()),
            e => setRefreshTrigger(new Date())
          );
        },
        unblock: e => {
          unblockSource(auth.token, row.source).then(
            e => setRefreshTrigger(new Date()),
            e => setRefreshTrigger(new Date())
          );
        }
      };

      data.push({
        source: row.source,
        ...getCommonAdminColumns(row, { action })
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        source: row.source,
        partner_profit: (row.partner_profit || 0).toFixed(4)
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Source" columns={columns} data={data} />
    </PerfectScrollbar>
  );
};
StatsBySource.propTypes = {
  className: PropTypes.string
};

export default StatsBySource;

import axios from "axios";
import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;

export async function fetchAggregateForDateRange(token, start, end) {
  const endpoint = apiURL + "/v1/aggregate/by-date-range";
  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchRevenueForDateRange(token, start, end) {
  const endpoint = apiURL + "/v1/revenue/by-date-range";
  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchRevenueForAllDomains(domains, start, end) {
  const promises = domains.map((domain) => {
    const config = getConfig(domain);
    const endpoint =
      config.api_url + "/v1/revenue/for-accounting-by-date-range";

    return axios.post(endpoint, {
      start,
      end,
    });
  });

  const results = await Promise.all(promises);

  return results.map((res) => res.data);
}

export async function fetchAggregateForAllDomains(domains, start, end) {
  //TODO: Ask Masnun vai if we can do this in the backend (hint: for all partners together)
  const results = {};

  const promises = domains.map((domain) => {
    const config = getConfig(domain);
    const endpoint = config.api_url + "/v1/aggregate/by-date-range";

    return axios.post(endpoint, {
      start,
      end,
    });
  });

  const allResults = await Promise.allSettled(promises);

  for (let i = 0; i < domains.length; i++) {
    const domain = domains[i];
    const result = allResults[i];

    if (result.status === "fulfilled") {
      results[domain] = result.value.data;
    }
  }

  return results;
}

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { getChartLabel } from "../config/chart";

const SMSStatsItemSelector = ({ chartItems, value, onChange, isAdmin,labelConfig = {} }) => {
  const menuItems = [];
  for (const item of chartItems) {
    menuItems.push({
      value: item,
      label: getChartLabel(item, labelConfig)
    });
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Chart Item</InputLabel>
      <Select
        value={value}
        label="Chart Item"
        onChange={e => {
          onChange(e.target.value);
        }}
      >
        {menuItems.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SMSStatsItemSelector;

const COLORS_RGB = [
  [128, 0, 0],
  [220, 20, 60],
  [255, 99, 71],
  [255, 127, 80],
  [205, 92, 92],
  [240, 128, 128],
  [233, 150, 122],
  [250, 128, 114],
  [255, 69, 0],
  [255, 140, 0],
  [255, 215, 0],
  [184, 134, 11],
  [218, 165, 32],
  [238, 232, 170],
  [189, 183, 107],
  [240, 230, 140],
  [128, 128, 0],
  [255, 255, 0],
  [154, 205, 50],
  [85, 107, 47],
  [107, 142, 35],
  [124, 252, 0],
  [173, 255, 47],
  [0, 100, 0],
  [0, 128, 0],
  [50, 205, 50],
  [152, 251, 152],
  [143, 188, 143],
  [0, 250, 154],
  [46, 139, 87],
  [102, 205, 170],
  [60, 179, 113],
  [32, 178, 170],
  [47, 79, 79],
  [0, 139, 139],
  [224, 255, 255],
  [0, 206, 209],
  [64, 224, 208],
  [176, 224, 230],
  [95, 158, 160],
  [70, 130, 180],
  [100, 149, 237],
  [173, 216, 230],
  [25, 25, 112],
  [0, 0, 255],
  [65, 105, 225],
  [138, 43, 226],
  [75, 0, 130],
  [72, 61, 139],
  [147, 112, 219],
  [128, 0, 128],
  [216, 191, 216],
  [221, 160, 221],
  [238, 130, 238],
  [255, 0, 255],
  [218, 112, 214],
  [199, 21, 133],
  [219, 112, 147],
  [255, 105, 180],
  [255, 192, 203],
  [250, 235, 215],
  [255, 228, 196],
  [255, 248, 220],
  [255, 250, 205],
  [255, 255, 224],
  [139, 69, 19],
  [210, 105, 30],
  [210, 180, 140],
  [188, 143, 143],
  [255, 228, 181],
  [255, 218, 185],
  [255, 228, 225],
  [255, 240, 245],
  [250, 240, 230],
  [245, 255, 250],
  [245, 255, 250],
  [176, 196, 222],
  [255, 250, 240],
  [240, 248, 255],
  [248, 248, 255],
  [240, 255, 240],
  [192, 192, 192],
];

export function getRandomColor(opacity = 0.75) {
  const r = parseInt(Math.floor(Math.random() * 225) + 25);
  const g = parseInt(Math.floor(Math.random() * 225) + 25);
  const b = parseInt(Math.floor(Math.random() * 225) + 25);

  const color = `rgb(${r}, ${g}, ${b})`;
  const colorWithOpacity = `rgba(${r}, ${g}, ${b}, ${opacity})`;

  return { color, colorWithOpacity };
}

export function getChartColors(length, opacity = 0.75) {
  const colors = [];
  const colorsWithOpacity = [];

  const fixedColorsLength = COLORS_RGB.length;

  for (let idx = 0; idx < length; idx++) {
    let color, colorWithOpacity;

    if (idx < fixedColorsLength) {
      let colorCode = COLORS_RGB[idx];
      let colorCodeWithOpacity = [...colorCode, opacity];

      color = `rgb(${colorCode.join(",")})`;
      colorWithOpacity = `rgba(${colorCodeWithOpacity.join(",")})`;
    } else {
      const randomColor = getRandomColor(opacity);
      color = randomColor.color;
      colorWithOpacity = randomColor.colorWithOpacity;
    }
    colors.push(color);
    colorsWithOpacity.push(colorWithOpacity);
  }

  return { colors, colorsWithOpacity };
}

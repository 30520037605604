import React from "react";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
} from "@material-ui/core";

import Chip from "@material-ui/core/Chip";
import HappyIcon from "@material-ui/icons/SentimentVerySatisfied";
import SadIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles(() => ({
  root: { margin: "30px" },
  actions: {
    justifyContent: "flex-end",
  },
}));

const HealthStatus = ({ data }) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root)}>
      <CardHeader title={`${data.domain} - ${data.name}`} />
      {data.components ? (
        <ValidCardContent data={data} />
      ) : (
        <InValidCardContent />
      )}
    </Card>
  );
};

const ValidCardContent = ({ data }) => {
  return (
    <CardContent>
      <Chip icon={<HappyIcon />} label="API" color="primary" />{" "}
      <Chip
        icon={data.components.mongo ? <HappyIcon /> : <SadIcon />}
        label="Mongo"
        color={data.components.mongo ? "primary" : "secondary"}
      />{" "}
      <Chip
        icon={data.components.redis ? <HappyIcon /> : <SadIcon />}
        label="Redis"
        color={data.components.redis ? "primary" : "secondary"}
      />{" "}
      <Chip
        icon={data.components.tracer ? <HappyIcon /> : <SadIcon />}
        label="Tracer"
        color={data.components.tracer ? "primary" : "secondary"}
      />
    </CardContent>
  );
};

const InValidCardContent = ({ data }) => {
  return (
    <CardContent>
      <Chip icon={<SadIcon />} label="API" color="secondary" />{" "}
      <Chip icon={<WarningIcon />} label="Mongo" />{" "}
      <Chip icon={<WarningIcon />} label="Redis" />{" "}
      <Chip icon={<WarningIcon />} label="Tracer" />{" "}
    </CardContent>
  );
};

export default HealthStatus;

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { fetchStatsByCampaignForDateRange } from "src/services/email/stats";
import DataTable from "../../components/DataTable";
import { getCommonAdminColumns } from "../../utils/email/stats_table";
import { getCommonAdminHeaders } from "../../utils/email/stats_table";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const StatsByCampaign = ({ dateRange, token, permission }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadStats() {
      setLoading(true);
      const campaignStats = await fetchStatsByCampaignForDateRange(
        token,
        dateRange.start,
        dateRange.end
      );
      setStats(campaignStats.stats);
      setLoading(false);
    }

    loadStats().then(() => {});
  }, [token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Campaign" />;
  }

  if (!stats) {
    console.log("No email stats by campaign available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "campaign", label: "Campaign" },
      ...getCommonAdminHeaders(stats, {})
    ];
  } else {
    columns = [{ name: "campaign", label: "Campaign" }];
  }

  const data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      data.push({
        campaign: row.campaign,
        ...getCommonAdminColumns(row)
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        campaign: row.campaign,
        revenue: (row.partner_profit || 0).toFixed(4)
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Campaign" columns={columns} data={data} />
    </PerfectScrollbar>
  );
};

export default StatsByCampaign;

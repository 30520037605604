import axios from "axios";
import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;

export async function fetchUserStats(token, date, order, skip, limit) {
  const endpoint = apiURL + "/v1/stats/by-user";
  const response = await axios.post(
    endpoint,
    {
      date,
      order,
      skip,
      limit
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchUserHistory(token, phone, skip, limit) {
  const endpoint = apiURL + "/v1/stats/user-history";
  const response = await axios.post(
    endpoint,
    {
      phone,
      skip,
      limit
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchUserEvents(token, phone, skip, limit) {
  const endpoint = apiURL + "/v1/stats/user-events";
  const response = await axios.post(
    endpoint,
    {
      phone,
      skip,
      limit
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchUserDetails(phone) {
  const endpoint = apiURL + "/v1/leads/info";
  const response = await axios.post(
    endpoint,
    {
      phone
    }
  );

  return response.data;
}


export async function updateUserDetails(token, phone, values, updateAll) {
  const endpoint = apiURL + "/v2/leads/update-all";
  const response = await axios.post(
    endpoint,
    {
      phone,
      values,
      update_all: updateAll
    },
    { headers: { token } }
  );

  return response.data;
}


export async function updateUserPreference(lead_id, values) {
  const endpoint = apiURL + "/v2/leads/update-preference";
  const response = await axios.post(
    endpoint,
    {
      lead_id,
      values

    }
  );

  return response.data;
}

import axios from "axios";
import getConfig from "../config";

const config = getConfig();
const apiURL = config.api_url;

export async function getAllLeads(token, limit = 50, order = -1) {
  const endpoint = `${apiURL}/v2/leads/recent/email?limit=${limit}&order=${order}`;
  const response = await axios.get(endpoint, { headers: { token } });

  return response.data;
}

import React from "react";
import { CirclesWithBar } from "react-loader-spinner";
import { Box, Card, CardContent, CardHeader } from "@material-ui/core";

const LoadingSpinnerForStats = ({ title }) => {
  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Box display="flex" justifyContent="center">
          <CirclesWithBar
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={1}
            strokeWidthSecondary={2000}
            color="#6495ED"
            secondaryColor="red"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default LoadingSpinnerForStats;

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";

import {
  blockCarrierOnList,
  getBlockedCarriersOnList,
  unblockCarrierOnList
} from "src/services/blacklist";
import DataTable from "../../components/DataTable";

import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";
import { fetchStatsByCarrierForList } from "../../services/lists";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const StatsByCarrier = ({ token, listId, dateRange }) => {
  let [blockedCarriers, setBlockedCarriers] = useState({});
  let [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true);

      const statsData = await fetchStatsByCarrierForList(
        token,
        listId,
        dateRange.start,
        dateRange.end
      );
      setStats(statsData.stats);

      const blockedData = await getBlockedCarriersOnList(token, listId);
      let blockedCarrierData = {};
      for (const item of blockedData.carriers) {
        const carrier = item.carrier;
        if (!blockedCarrierData[carrier]) {
          blockedCarrierData[carrier] = true;
        }
      }
      setBlockedCarriers(blockedCarrierData);
      setLoading(false);
    }

    loadInitialData().then(() => {});
  }, [listId, token, dateRange, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Carrier" />;
  }
  if (!stats) {
    return null;
  }

  const columns = [
    { name: "carrier", label: "Carrier" },
    ...getCommonAdminHeaders(stats, { action: true })
  ];

  const rows = [];
  for (const row of stats) {
    const isBlocked = blockedCarriers[row.carrier] || false;

    const action = {
      isBlocked,
      block: e => {
        blockCarrierOnList(token, row.carrier, listId).then(
          e => setRefreshTrigger(new Date()),
          e => setRefreshTrigger(new Date())
        );
      },
      unblock: e => {
        unblockCarrierOnList(token, row.carrier, listId).then(
          e => setRefreshTrigger(new Date()),
          e => setRefreshTrigger(new Date())
        );
      }
    };

    const config = { action };

    rows.push({
      carrier: row.carrier,
      ...getCommonAdminColumns(row, config)
    });
  }

  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Carrier" columns={columns} data={rows} />
    </PerfectScrollbar>
  );
};

StatsByCarrier.propTypes = {
  className: PropTypes.string
};

export default StatsByCarrier;

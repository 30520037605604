import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchRevenueForDateRange,
  fetchAggregateForDateRange,
} from "../services/revenue";

export const fetchRevenue = createAsyncThunk(
  "revenue/by-date-range",
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const { setRevenue } = revenueSlice.actions;
    const { start, end, token } = payload;

    const data = await fetchAggregateForDateRange(token, start, end);
    const { aggregate, error } = data;

    if (aggregate) {
      return dispatch(setRevenue(aggregate));
    } else {
      return rejectWithValue({ error });
    }
  }
);

const revenueSlice = createSlice({
  name: "revenue",
  initialState: {},
  reducers: {
    setRevenue: (state, action) => {
      const data = action.payload;
      state.data = data;
    },
  },

  extraReducers: {
    [fetchRevenue.rejected]: (state, action) => {
      const { error, payload } = action;

      if (!payload) {
        state.error = error.message || "Error occurred";
        return;
      }

      state.error = action.payload.error || "Error occurred";
    },
  },
});

export const { setRevenue } = revenueSlice.actions;
export default revenueSlice.reducer;

import axios from "axios";
import getConfig from "../config";

const config = getConfig();
const apiURL = config.api_url;

export async function fetchAggregateForDate(token, start, end) {
  const endpoint = `${apiURL}/v2/email-aggregate/by-date-range`;

  const response = await axios.post(
    endpoint,
    { start, end },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsByCampaignForDateRange(token, start, end) {
  const endpoint = apiURL + "/v2/email-stats/by-campaign";

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsByCompanyForDateRange(token, start, end) {
  const endpoint = apiURL + "/v2/email-stats/by-company";

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsByDomainForDateRange(token, start, end) {
  const endpoint = apiURL + "/v2/email-stats/by-domain";

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsByEmailProviderForDateRange(token, start, end) {
  const endpoint = apiURL + "/v2/email-stats/by-email-provider";

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsByOperatingSystemForDateRange(
  token,
  start,
  end
) {
  const endpoint = apiURL + "/v2/email-stats/by-operating-system";

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsByKeywordForDateRange(token, start, end) {
  const endpoint = apiURL + "/v2/email-stats/by-keyword";

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsBySourceForDateRange(token, start, end) {
  const endpoint = apiURL + "/v2/email-stats/by-source";

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsByTemplateForDateRange(token, start, end) {
  const endpoint = apiURL + "/v2/email-stats/by-template";

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsBySubjectForDateRange(token, start, end) {
  const endpoint = `${apiURL}/v2/email-stats/by-subject`;

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function fetchStatsByFromAddressForDateRange(token, start, end) {
  const endpoint = `${apiURL}/v2/email-stats/by-from-address`;

  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  withStyles
} from "@material-ui/core";

import Page from "src/components/Page";

import { fetchJobsForUser, recordImpression } from "src/services/serp";
import { loadPopUpAndPopUnder } from "src/services/popup";
import { updateUserPreference } from "src/services/user";

import _ from "lodash";
import { getNeuvooPopupURL, getTalrooPopupURL } from "../../utils/popup";

const REDIRECT_VIEW = ["ccf"];
const SINGLE_VIEW = ["nss", "ts", "omxs", "omxis", "oms", "l5nlgs", "l5des"];
const POPUP_MAP = {
  n: (data) => getNeuvooPopupURL(data, data.search),
  t: (data) => getTalrooPopupURL(data, data.search)
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  title: {
    color: "red"
  },
  companyLogo: {
    textAlign: "center",
    height: "50px",
    width: "50px"
  },
  logoImg: {
    maxWidth: "100%",
    height: "auto"
  },
  jobContainer: {
    marginBottom: 20
  },
  jobTitle: {
    fontSize: "16px",
    fontWeight: 700
  },
  jobSubTitle: {
    fontSize: "16px"
  },
  userWelcome: {
    fontSize: "16px",
    width: "100%",
    textAlign: "center"
  },
  sjItemLogo: {
    textAlign: "center",
    borderBottom: "1px solid #ddd",
    paddingBottom: 25,
    paddingTop: 20
  },
  sjItemTitle: {
    textAlign: "center",
    fontSize: "30px",
    color: "#d01616",
    fontWeight: 900,
    paddingTop: 20,
    paddingBottom: 40
  },

  updatePreference: {
    fontSize: "14px",
    width: "100%",
    textAlign: "center"
  }
}));

const ApplyButton = withStyles((theme) => ({
  root: {
    fontSize: "16px",
    textTransform: "capitalize",
    backgroundColor: "#dc3545",
    "&:hover": {
      backgroundColor: "#a8212e"
    }
  }
}))(Button);

const ApplyButtonBig = withStyles((theme) => ({
  root: {
    fontSize: "25px",
    height: 65,
    textTransform: "capitalize",
    backgroundColor: "#dc3545",
    "&:hover": {
      backgroundColor: "#a8212e"
    }
  }
}))(Button);

const ErrorView = ({ error }) => {
  return <Box color="red">{error}</Box>;
};

// Multi Jobs View
const JobListView = ({ data, popupURL }) => {
  const classes = useStyles();

  return (
    <Box>
      {/* User Info */}
      {/*<Grid container>*/}
      {/*  <Grid item xs={12}>*/}
      {/*    <Typography className={classes.userWelcome}>*/}
      {/*      Hi {data.user.first_name}, Here are a few jobs urgently hiring in{" "}*/}
      {/*      {data.user.zip}*/}
      {/*      <br />*/}
      {/*      <br />*/}
      {/*    </Typography>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}

      {/* Job List */}
      {data.jobs.map((job, i) => (
        <Grid key={i} container spacing={0} className={classes.jobContainer}>
          <Grid className={classes.logo} item xs={2}>
            <img
              src={job.logo}
              alt="Company Name"
              className={classes.companyLogo}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h3" className={classes.jobTitle}>
              <a href="javascript: false"
                 onClick={() => {
                   loadPopUpAndPopUnder(job.url, popupURL);
                 }}
              >
                {job.title}
              </a>
            </Typography>
            <Typography className={classes.jobSubTitle}>
              {job.company ? job.company : ""}
              {/*{job.city ? job.city + "," : ""} {job.state}*/}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <a
              onClick={() => {
                loadPopUpAndPopUnder(job.url, popupURL);
              }}
            >
              <ApplyButton variant="contained" fullWidth={true} color="primary">
                Apply
              </ApplyButton>
            </a>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

// Single Job View
const SingleJobView = ({ data, popupURL }) => {
  const classes = useStyles();

  const job = data.jobs[0];

  return (
    <Box>
      {/* Single Job */}
      <Grid container>
        <Grid item xs={12}>
          {/* Company Logo */}
          <div className={classes.sjItemLogo}>
            <img src={job.logo} alt="Company Name" width={120} height={120} />
          </div>

          {/* Job Title */}
          <Typography variant="h1" className={classes.sjItemTitle}>
            {job.title}
          </Typography>

          {/* Job Apply Button */}
          <div>
            <a
              onClick={() => {
                loadPopUpAndPopUnder(job.url, popupURL);
              }}
            >
              <ApplyButtonBig
                variant="contained"
                fullWidth={true}
                color="primary"
              >
                Click To Apply
              </ApplyButtonBig>
            </a>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

const PostResponseView = ({ data, type, popupURL, error }) => {
  if (!data) {
    return (
      <Container maxWidth="sm">
        <Typography>Sorry we didn't find any jobs for you</Typography>
      </Container>
    );
  }

  if (_.includes(REDIRECT_VIEW, type)) {
    window.location.href = data.jobs[0].url;
    return null;
  }

  if (_.includes(SINGLE_VIEW, type)) {
    return (
      <Container maxWidth="sm">
        <SingleJobView data={data} popupURL={popupURL} />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <JobListView data={data} popupURL={popupURL} />
    </Container>
  );
};

const UpdatePreferenceFooter = ({ data, searchId, type }) => {
  const classes = useStyles();

  if (!data || !data.user) return null;

  const lineBreaksCount = _.includes(SINGLE_VIEW, type) ? 5 : 2;


  return (<>

      {_.range(lineBreaksCount).map(() => (<br />))}

      <Typography className={classes.updatePreference}>
        <Button color="primary" variant="contained" onClick={() => {
          const url = window.location.protocol + "//" + window.location.host + "/app/user/company-categorization/" + data.user.phone;
          window.location.assign(url);
        }}>Update title & location?</Button>

        <br /><br />


        {data.user.send_text > 0 ? (
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {
              updateUserPreference(data.user._id, { send_text: 0 })
                .then(() => {
                  window.location.reload();
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            Pause Alerts
          </Button>
        ) : (
          <Button
            variant="contained"
            size="small"
            color="default"
            onClick={() => {
              updateUserPreference(data.user._id, { send_text: 1 })
                .then(() => {
                  window.location.reload();
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            Resume Alerts
          </Button>
        )}
      </Typography>
    </>
  );
};

const SearchJobsList = () => {
  const classes = useStyles();
  const { searchId, type, popup } = useParams();
  const [userId, sendId] = (searchId || "").split("_");

  const [data, setData] = useState(null);
  const [popupURL, setPopUpURL] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchJobsForUser(userId, sendId, type)
      .then((response) => {
        if (response.error) {
          setError(response.error);
        } else {
          response.jobs.forEach((job) => {
            if (job.impression_url) {
              recordImpression(job.impression_url)
                .then((res) => console.log(res))
                .catch((e) => console.error(e));
            }
          });

          setData(response);
          if (popup) {
            const popupURL = POPUP_MAP[popup](response.user);
            setPopUpURL(popupURL);
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [popup]);

  return (
    <Page className={classes.root} title="Jobs">
      <Box>
        {loading ? (
          <Container maxWidth="sm">Finding jobs for you!</Container>
        ) : (
          <>
            <PostResponseView
              type={type}
              popupURL={popupURL}
              data={data}
              error={error}
            />

            <UpdatePreferenceFooter
              data={data}
              searchId={searchId}
              type={type}
            />

            <br />
            <br />
          </>
        )}
      </Box>
    </Page>
  );
};
export default SearchJobsList;

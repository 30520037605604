import moment from "moment";

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Page from "src/components/Page";

import {
  Grid,
  Container,
  makeStyles,
  Typography,
  Card,
  CardHeader,
  Divider
} from "@material-ui/core";


import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Button from "@material-ui/core/Button";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";


import { fetchIncomingMessages, deleteIncomingMessages } from "src/services/incoming_messages";
import { blockPhoneGlobally } from "../../services/blacklist";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  chip: { margin: theme.spacing(0.5) },

  table: {
    minWidth: 650
  }
}));


const IncomingMessageListView = () => {
  const PER_PAGE = 20;
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [filters, setFilters] = useState({});

  const [showNext, setShowNext] = useState(true);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    const skip = (page - 1) * PER_PAGE;
    fetchIncomingMessages(auth.token, skip, PER_PAGE, filters).then((response) => {
      setMessages(response.messages);
      if (response.messages.length < PER_PAGE) {
        setShowNext(false);
      }
    });
  }, [page, auth.token, filters, refreshTrigger]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={3}>
          <Typography>
            <h3>Incoming Messages</h3>
          </Typography>
        </Grid>


      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={6}>
          <Button onClick={() => {
            setFilters({});
          }} variant="contained">All</Button> &nbsp;

          <Button onClick={() => {
            setFilters({ responded: true });
          }} variant="contained" color="primary">Responded</Button> &nbsp;
          <Button onClick={() => {
            setFilters({ responded: false });
          }} variant="contained" color="secondary">Not Responded</Button>


        </Grid>


      </Grid>


      <Grid container spacing={3}>
        <Grid item lg={9}>
          <TableView setRefreshTrigger={setRefreshTrigger} auth={auth} rows={messages} />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={9}>
          {page > 1 ? (
            <Button
              onClick={() => setPage(page - 1)}
              variant="contained"
              color="primary"
            >
              Prev
            </Button>
          ) : (
            ""
          )}
          &nbsp;(<strong>Page {page}</strong>)&nbsp;
          {showNext ? (
            <Button
              onClick={() => setPage(page + 1)}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const TableView = ({ rows, auth, setRefreshTrigger }) => {
  const classes = useStyles();

  // console.log(rows);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Phone</TableCell>
            <TableCell>Text</TableCell>
            <TableCell>When?</TableCell>
            <TableCell>Action</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {row.phone}
              </TableCell>
              <TableCell>{row.text || "-"}</TableCell>

              <TableCell>{moment(row.created_at).format("YYYY-MM-DD h:mm:ss a")}</TableCell>

              <TableCell>

                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<RemoveCircleIcon />}
                  onClick={e => {
                    blockPhoneGlobally(auth.token, row.phone).then(() => {
                        return deleteIncomingMessages(auth.token, row.phone).then(
                          e => setRefreshTrigger(new Date()),
                          e => setRefreshTrigger(new Date()));
                      }
                    );
                  }}
                >
                  Blacklist on All Account
                </Button>

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


const IncomingMessageView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Incoming Messages">
      <Container maxWidth={false}>

        <IncomingMessageListView />
      </Container>
    </Page>
  );
};

export default IncomingMessageView;

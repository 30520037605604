import React from 'react';
import { Box } from '@material-ui/core';
import { XOctagon } from 'react-feather';
import { Text } from 'src/components/Text';

const ErrorView = () => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column" padding="2rem">
      <XOctagon color="#3F4E4F" size="3rem" />
      <Text style={{ fontWeight: 'bold', paddingTop: '1rem' }}>Sorry, the user doesn't exist!</Text>
    </Box>
  );
};

export default ErrorView;

import axios from "axios";
import getConfig from "../config";

const config = getConfig();
const apiURL = config.api_url;

// Block
export async function block(token, endpoint, type, value) {
  const response = await axios.post(
    endpoint,
    {
      [type]: value,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockEmail(token, email) {
  const endpoint = `${apiURL}/v2/email-blacklist/email`;
  return await block(token, endpoint, "email", email);
}

export async function blockSource(token, source) {
  const endpoint = `${apiURL}/v2/email-blacklist/source`;
  return await block(token, endpoint, "source", source);
}

export async function blockKeyword(token, keyword) {
  const endpoint = `${apiURL}/v2/email-blacklist/keyword`;
  return await block(token, endpoint, "keyword", keyword);
}

export async function blockEmailProvider(token, emailProvider) {
  const endpoint = `${apiURL}/v2/email-blacklist/email-provider`;
  return await block(token, endpoint, "email_provider", emailProvider);
}

export async function blockOperatingSystem(token, operatingSystem) {
  const endpoint = `${apiURL}/v2/email-blacklist/operating-system`;
  return await block(token, endpoint, "operating_system", operatingSystem);
}

export async function blockDomain(token, domain) {
  const endpoint = `${apiURL}/v2/email-blacklist/domain`;
  return await block(token, endpoint, "domain", domain);
}

// Unblock
export async function unblock(token, endpoint, type, value) {
  const response = await axios.post(
    endpoint,
    {
      [type]: value,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function unblockSource(token, source) {
  const endpoint = `${apiURL}/v2/email-blacklist/unblock-source`;
  return await unblock(token, endpoint, "source", source);
}

export async function unblockKeyword(token, keyword) {
  const endpoint = `${apiURL}/v2/email-blacklist/unblock-keyword`;
  return await unblock(token, endpoint, "keyword", keyword);
}

export async function unblockEmailProvider(token, emailProvider) {
  const endpoint = `${apiURL}/v2/email-blacklist/unblock-email-provider`;
  return await unblock(token, endpoint, "email_provider", emailProvider);
}

export async function unblockOperatingSystem(token, operatingSystem) {
  const endpoint = `${apiURL}/v2/email-blacklist/unblock-operating-system`;
  return await unblock(token, endpoint, "operating_system", operatingSystem);
}

export async function unblockDomain(token, domain) {
  const endpoint = `${apiURL}/v2/email-blacklist/unblock-domain`;
  return await unblock(token, endpoint, "domain", domain);
}

// Get blocked items
export async function getBlockedItems(token, endpoint) {
  const response = await axios.get(endpoint, { headers: { token } });
  return response.data;
}

export async function getBlockedSources(token) {
  const endpoint = `${apiURL}/v2/email-blacklist/blocked-sources`;
  return await getBlockedItems(token, endpoint);
}

export async function getBlockedKeywords(token) {
  const endpoint = `${apiURL}/v2/email-blacklist/blocked-keywords`;
  return await getBlockedItems(token, endpoint);
}

export async function getBlockedEmailProviders(token) {
  const endpoint = `${apiURL}/v2/email-blacklist/blocked-email-providers`;
  return await getBlockedItems(token, endpoint);
}

export async function getBlockedOperatingSystems(token) {
  const endpoint = `${apiURL}/v2/email-blacklist/blocked-operating-systems`;
  return await getBlockedItems(token, endpoint);
}

export async function getBlockedDomains(token) {
  const endpoint = `${apiURL}/v2/email-blacklist/blocked-domains`;
  return await getBlockedItems(token, endpoint);
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAll, update, del, create } from "../services/lists";

export const fetchLists = createAsyncThunk(
  "lists/all",
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const { setLists } = listsSlice.actions;
    const { token } = payload;

    const data = await getAll(token);
    const { lists, error } = data;

    if (lists) {
      return dispatch(setLists(lists));
    } else {
      return rejectWithValue({ error });
    }
  }
);

export const updateList = createAsyncThunk(
  "lists/update",
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const {
      _id,
      token,
      name,
      brand,
      listTypes,
      intake,
      days,
      hour,
      minute,
      followupCount,
      unsubDays,
      directLink,
      gateway,
      rules,
      domain,
      fellOffList,
      reSignupList,
      useAsReSignupList,
      useAsFellOffList
    } = payload;

    const data = await update(token, {
      _id,
      name,
      brand,
      listTypes,
      gateway,
      intake,
      days,
      hour,
      minute,
      followupCount,
      unsubDays,
      directLink,
      rules,
      domain,
      fellOffList,
      reSignupList,
      useAsReSignupList,
      useAsFellOffList
    });

    const { result, error } = data;

    if (result) {
      return dispatch(fetchLists({ token }));
    } else {
      return rejectWithValue({ error });
    }
  }
);

export const createList = createAsyncThunk(
  "lists/create",
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const {
      token,
      name,
      brand,
      listTypes,
      intake,
      days,
      hour,
      minute,
      followupCount,
      unsubDays,
      directLink,
      gateway,
      rules,
      domain,
      fellOffList,
      reSignupList,
      useAsReSignupList,
      useAsFellOffList
    } = payload;

    const data = await create(token, {
      name,
      listTypes,
      brand,
      intake,
      days,
      hour,
      minute,
      followupCount,
      unsubDays,
      directLink,
      gateway,
      rules,
      domain,
      fellOffList,
      reSignupList,
      useAsReSignupList,
      useAsFellOffList
    });

    const { result, error } = data;

    if (result) {
      return dispatch(fetchLists({ token }));
    } else {
      return rejectWithValue({ error });
    }
  }
);

export const deleteList = createAsyncThunk(
  "lists/delete",
  async (payload, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI;
    const { _id, token } = payload;

    const data = await del(token, _id);
    const { result, error } = data;

    if (result) {
      return dispatch(fetchLists({ token }));
    } else {
      return rejectWithValue({ error });
    }
  }
);

const listsSlice = createSlice({
  name: "lists",
  initialState: {},
  reducers: {
    setLists: (state, action) => {
      const data = action.payload;
      state.data = data;
    }
  },

  extraReducers: {
    [fetchLists.rejected]: (state, action) => {
      const { error, payload } = action;

      if (!payload) {
        state.error = error.message || "Error occurred";
        return;
      }

      state.error = action.payload.error || "Error occurred";
    }
  }
});

export const { setLists } = listsSlice.actions;
export default listsSlice.reducer;

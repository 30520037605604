import _ from "lodash";

const smsDashboardComponents = [
  "sms:aggregate",
  "sms:additional_stats",
  "sms:stats_by_source",
  "sms:stats_by_carrier",
  "sms:stats_by_keyword",
  "sms:stats_by_ad_type"
];
const smsListDashboardComponents = [
  "sms:list:aggregate",
  "sms:list:ads",
  "sms:list:stats_by_template",
  "sms:list:stats_by_source",
  "sms:list:stats_by_carrier",
  "sms:list:stats_by_keyword",
  "sms:list:stats_by_ad_type"
];
const emailDashboardComponents = [
  "email:aggregate",
  "email:stats_by_source",
  "email:stats_by_email_provider",
  "email:stats_by_keyword",
  "email:stats_by_domain",
  "email:stats_by_company",
  "email:stats_by_template",
  "email:stats_by_campaign"
];

const generalViews = [
  "sms:list",
  "sms:graph",
  "sms:user_stats",
  "sms:incoming_message",
  "sms:companies",

  "accounting",
  "health_check",
  "docs"
];

export function findAccessibleViews(access) {
  const views = [];
  const userComponents = Object.keys(access);
  if (_.intersection(smsDashboardComponents, userComponents).length) {
    views.push("sms:dashboard");
  }
  if (_.intersection(smsListDashboardComponents, userComponents).length) {
    views.push("sms:list:dashboard");
  }
  if (_.intersection(emailDashboardComponents, userComponents).length) {
    views.push("email:dashboard");
  }

  views.push(..._.intersection(generalViews, userComponents));

  return views;
}

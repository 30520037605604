import React from "react";
import clsx from "clsx";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: { margin: "10px" },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TopCard = ({ title, value, className, ...rest }) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={title.toUpperCase()} />

      <CardContent>
        <Typography variant="h3">{value} </Typography>
      </CardContent>
    </Card>
  );
};

export default TopCard;

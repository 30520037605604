import axios from "axios";
import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;

const endpoint = apiURL + "/v2/notice";

export async function fetchNotice(token) {
  const response = await axios.get(endpoint, { headers: { token } });
  return response.data;
}

import { Footer } from "./Footer";
import { useParams } from "react-router";
import { SelectTitles } from "./SelectTitles";
import { SelectCompany } from "./SelectCompany";
import React, { useEffect, useState } from "react";
import { fetchUserDetails } from "src/services/user";
import { searchCompanyDetails, ping } from "src/services/tagging";
import { Box, CircularProgress, Container } from "@material-ui/core";
import { getNeuvooPopupURL } from "src/utils/popup";
import ErrorView from "./Error";

const CompanyCategorizationView = () => {
  const { phone } = useParams();
  const [user, setUser] = useState();
  const [companyDetails, setCompanyDetails] = useState();
  const [isInvalidData, setIsInvalidData] = useState();

  //current user
  const getUser = async () => {
    const userRes = await fetchUserDetails(phone);
    if (userRes?.lead) {
      const { _id: id, phone, zip, preferred_company_categorization, search } = userRes.lead;
      if (preferred_company_categorization) {
        if (preferred_company_categorization.length > 0) {
          const { company, categories } = preferred_company_categorization[0];
          setUser({ id, company, phone, zip, categories });
        } else setUser({ id, phone, zip });
      } else {
        window.location.assign(getNeuvooPopupURL(userRes.lead, search));
      }
    } else setIsInvalidData(true);
  };

  const handlePreferredCompanyDetails = async () => {
    const companyDetails = await searchCompanyDetails(user.company);
    if (companyDetails) {
      ping(user.phone).then(() => {
        const preferredCompanyDetails = getExactCompany(user.company, companyDetails);
        setCompanyDetails(preferredCompanyDetails);
      }).catch((err) => {
        console.error("Error pinging", err);
      });
    }
  };

  //if search with company name returns more than one result
  const getExactCompany = (companyName, companyDetails) => {
    if (companyDetails?.length > 1) {
      return companyDetails.find(company => company?.name === companyName);
    } else if (companyDetails?.length > 0) return companyDetails[0];
  };

  const isValidCompany = company => company && company.toLowerCase() !== "default";

  useEffect(() => {
    if (isValidCompany(user?.company)) {
      handlePreferredCompanyDetails();
    }
  }, [user]);

  useEffect(() => {
    if (phone) getUser();
  }, [phone]);

  return (
    <Box display="flex" bgcolor="#fcf9f1" flexDirection="column">
      <Container maxWidth="md">
        <Box display="flex" flexDirection="column" minHeight="100vh">
          <Box
            flex={1}
            display="flex"
            padding="1rem 0"
            alignItems="center"
            flexDirection="column"
            justifyContent={{ xs: "center", md: "center" }}
          >
            <Box
              display="flex"
              alignItems="center"
              bgcolor="#fde5ca40"
              borderRadius="0.5rem"
              flexDirection="column"
              justifyContent="center"
              border="3px solid #fde5ca"
              padding={{ xs: "1rem", md: "1rem 2rem" }}
              minWidth={{ xs: "95vw", md: "600px" }}
            >
              {isInvalidData ? (
                <ErrorView />
              ) : companyDetails ? (
                <SelectTitles user={user} companyDetails={companyDetails} />
              ) : !isValidCompany(user?.company) && user?.id ? (
                <SelectCompany setCompanyDetails={setCompanyDetails} setUser={setUser} />
              ) : (
                <Box p="2rem">
                  <CircularProgress color="primary" size="3rem" />
                </Box>
              )}
            </Box>
          </Box>
          <Footer />
        </Box>
      </Container>
    </Box>
  );
};

export default CompanyCategorizationView;

import React, { useEffect, useState } from "react";
import { Button, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

import { fetchAggregateForAllDomains } from "src/services/revenue";
import getConfig from "src/services/config";

import Page from "src/components/Page";
import DateRangePicker from "src/components/DateRangePicker";

import Revenue from "./Revenue";
import TopCard from "./TopCard";
import Gateway from "./Gateway";

import { allDomains, clientInfoMap, internalDomains, partnerDomains } from "src/services/client";
import CarrierGroup from "./CarrierGroup";
import { getAlternativeFields } from "../../utils/stats";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const refresh = (dateRange, domains, setData, setIsLoading) => {

  setIsLoading(true);

  fetchAggregateForAllDomains(
    domains,
    dateRange.start,
    dateRange.end
  )
    .then(response => {


      const stats = {};
      const globalTotals = {
        total_cost: 0,
        total_send: 0,
        total_profit: 0,
        partner_profit: 0,
        ls_profit: 0,
        total_revenue: 0,
        total_insert: 0
      };

      const gatewayTotals = {};
      const carrierGroupTotals = {};

      const partnersData = [];
      for (const [domain, val] of Object.entries(response)) {
        const info = clientInfoMap[domain];
        const config = getConfig(domain);

        const totals = {
          total_cost: 0,
          total_send: 0,
          partner_profit: 0,
          ls_profit: 0,
          insert: 0,
          total_profit: 0,
          total_revenue: 0
        };

        const revenue = val.aggregate.map(agg => {
          totals.total_cost += agg.cost;
          totals.partner_profit += agg.partner_profit;
          totals.ls_profit += agg.ls_profit;
          totals.total_send += agg.total_send;
          totals.insert += agg.insert || agg.accepted || 0;
          totals.total_profit += agg.profit;
          totals.total_revenue += agg.revenue;

          // extract gateway
          const gatewayData = agg.by_gateway;
          if (gatewayData) {
            const gateways = Object.keys(gatewayData);

            for (const gateway of gateways) {
              if (!gatewayTotals[gateway]) {
                gatewayTotals[gateway] = {
                  welcome_send: 0,
                  alerts_send: 0,
                  total_send: 0,

                  cost: 0,
                  unsub: 0,
                  time_inactivity: 0,
                  total_unsub: 0
                };
              }

              gatewayTotals[gateway].welcome_send +=
                gatewayData[gateway].welcome_send ||
                gatewayData[gateway].welcome ||
                0;
              gatewayTotals[gateway].alerts_send +=
                gatewayData[gateway].alerts_send ||
                gatewayData[gateway].send ||
                0;
              gatewayTotals[gateway].total_send =
                gatewayTotals[gateway].welcome_send +
                gatewayTotals[gateway].alerts_send;

              gatewayTotals[gateway].cost += gatewayData[gateway].cost;
              gatewayTotals[gateway].unsub += gatewayData[gateway].unsub;
              gatewayTotals[gateway].time_inactivity +=
                gatewayData[gateway].time_inactivity;
              gatewayTotals[gateway].total_unsub +=
                gatewayData[gateway].total_unsub;
            }
          }

          // extract carrier group
          const carrierGroupData = agg.by_carrier_group;
          if (carrierGroupData) {
            updateCarrierGroupTotals(carrierGroupTotals, carrierGroupData);
          }

          return {
            ...agg
          };
        });

        globalTotals.total_cost += totals.total_cost;
        globalTotals.partner_profit += totals.partner_profit;
        globalTotals.ls_profit += totals.ls_profit;
        globalTotals.total_send += totals.total_send;
        globalTotals.total_profit += totals.total_profit;
        globalTotals.total_revenue += totals.total_revenue;
        globalTotals.total_insert += totals.insert;

        val["revenue"] = revenue;
        val["totals"] = totals;

        delete val["aggregate"];

        partnersData.push({
          ...val,
          ...info,
          ...config
        });
      }

      stats["totals"] = globalTotals;
      stats["partners_data"] = partnersData;
      stats["gateway_totals"] = gatewayTotals;
      stats["carrier_group_totals"] = Object.values(carrierGroupTotals);

      setData(stats);
      setIsLoading(false);
    })
    .catch(err => {
      console.log(err);
      setIsLoading(false);
    });
};

const AccountingView = () => {
  const classes = useStyles();

  const defaultDateRange = {
    start: moment()
      .subtract(15, "days")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD")
  };


  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [domains, setDomains] = useState([]);

  const [data, setData] = useState({
    partners_data: [],
    totals: {
      total_cost: 0,
      partner_profit: 0,
      ls_profit: 0,
      total_send: 0,
      insert: 0,
      total_profit: 0,
      total_revenue: 0,
      total_insert: 0
    }
  });


  const [view, setView] = useState("All");


  useEffect(() => {
    let domains = allDomains;

    switch (view) {
      case "Internal":
        domains = internalDomains;
        break;

      case "Partners":
        domains = partnerDomains;
        break;

      case "All":
      default:
        domains = allDomains;
        break;

    }

    setDomains(domains);
  }, [view]);


  useEffect(() => {

    if (!domains.length) return;

    refresh(dateRange, domains, setData, setIsLoading);
  }, [dateRange, domains]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <DateRangePicker
          start={dateRange.start}
          end={dateRange.end}
          onChange={values => {
            setDateRange(values);
          }}
        />

        <br /><br />

        <Grid container>
          <Grid item>
            <Typography variant="h4">Current View: {view} Revenue (From {dateRange.start} to {dateRange.end}) </Typography><br />
            <Button color="primary" disabled={view === "All"} onClick={() => setView("All")}
                    variant="contained">All</Button> &nbsp;
            <Button color="primary" disabled={view === "Internal"} onClick={() => setView("Internal")}
                    variant="contained">Internal</Button> &nbsp;
            <Button color="primary" disabled={view === "Partners"} onClick={() => setView("Partners")}
                    variant="contained">Partners</Button>
          </Grid>
        </Grid>

        <br /><br />

        {isLoading ? <CircularProgress /> : (<div>
          <Grid container>
            <Grid item lg={3}>
              <TopCard title="Send" value={data.totals.total_send} />
            </Grid>

            <Grid item lg={3}>
              <TopCard
                title="Cost"
                value={"$" + data.totals.total_cost.toFixed(4)}
              />
            </Grid>

            <Grid item lg={3}>
              <TopCard
                title="Total Revenue"
                value={"$" + data.totals.total_revenue.toFixed(4)}
              />
            </Grid>

            <Grid item lg={3}>
              <TopCard
                title="Total Profit"
                value={"$" + data.totals.total_profit.toFixed(4)}
              />
            </Grid>

            <Grid item lg={3}>
              <TopCard
                title="Partner Profit"
                value={"$" + data.totals.partner_profit.toFixed(4)}
              />
            </Grid>

            <Grid item lg={3}>
              <TopCard
                title="LocalStaffing Profit"
                value={"$" + data.totals.ls_profit.toFixed(4)}
              />
            </Grid>

            <Grid item lg={3}>
              <TopCard title="Total Accepted" value={data.totals.total_insert} />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item md={12}>
              <Gateway totals={data.gateway_totals} />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item md={12}>
              <CarrierGroup totals={data.carrier_group_totals} />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {data.partners_data.map(client => {
              return (
                <Grid item md={12} key={client.domain}>
                  <Revenue key={client.domain} client={client} />
                </Grid>
              );
            })}
          </Grid>
        </div>)}

      </Container>
    </Page>
  );
};

function updateCarrierGroupTotals(totals, data) {
  const carriers = Object.keys(data);
  const populatedData = {};
  for (const [carrier, carrierData] of Object.entries(data)) {
    populatedData[carrier] = {
      ...carrierData,
      ...getAlternativeFields(carrierData)
    };
  }

  for (const carrier of carriers) {
    if (!totals[carrier]) {
      totals[carrier] = {
        carrier,
        insert: 0,

        welcome_send: 0,
        alerts_send: 0,
        total_send: 0,

        delivered: 0,
        failed: 0,

        welcome_click: 0,
        alerts_click: 0,
        total_click: 0,

        cost: 0,
        revenue: 0,
        profit: 0
      };
    }

    totals[carrier].insert += populatedData[carrier].insert || 0;

    totals[carrier].welcome_send += populatedData[carrier].welcome_send || 0;
    totals[carrier].alerts_send += populatedData[carrier].alerts_send || 0;
    totals[carrier].total_send =
      totals[carrier].welcome_send + totals[carrier].alerts_send;

    totals[carrier].delivered += populatedData[carrier].delivered || 0;
    totals[carrier].failed += populatedData[carrier].failed || 0;

    totals[carrier].welcome_click += populatedData[carrier].welcome_click || 0;
    totals[carrier].alerts_click += populatedData[carrier].alerts_click || 0;
    totals[carrier].total_click =
      totals[carrier].welcome_click + totals[carrier].alerts_click;

    totals[carrier].cost += populatedData[carrier].cost || 0;
    totals[carrier].revenue += populatedData[carrier].revenue || 0;
    totals[carrier].profit += populatedData[carrier].profit || 0;
  }
}

export default AccountingView;

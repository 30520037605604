import axios from "axios";
import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;

export async function ping(phone) {
  const endpoint = apiURL + "/v1/tagging/ping";
  const response = await axios.post(endpoint, { phone });
  return response.data;
}


export async function updateCompanyCategorization(phone, preferred_company_categorization) {
  const endpoint = apiURL + "/v1/tagging/update-company-categorization";
  const response = await axios.post(endpoint, { phone, preferred_company_categorization });

  return response.data;
}

export async function searchCompanyDetails(searchStr = "") {
  const endpoint = apiURL + "/v1/companies/all";
  try {
    const response = await axios({
      method: "GET",
      url: endpoint,
      params: { company: searchStr }
    });

    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

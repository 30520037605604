import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  makeStyles,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  Chip,
  Input,
  MenuItem,
  Card,
  CardContent,
  Divider,
  CardHeader,
  Checkbox
} from "@material-ui/core";

import getConfig from "src/services/config";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AddIcon from "@material-ui/icons/Add";
import TextsmsIcon from "@material-ui/icons/Textsms";

import { NavLink } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import {
  createList,
  fetchLists,
  updateList,
  deleteList
} from "src/redux/lists";

import Page from "src/components/Page";
import Dialog from "src/components/Dialog";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const rulesNames = [
  "is_mike",

  "is_reactivate",
  "is_unique",
  "is_duplicate_active",

  "is_cdl",
  "is_pr_number",
  "not_pr_number",

  "is_verizon",
  "not_verizon",
  "is_tmobile",
  "not_tmobile",
  "not_verizon_tmobile",
  "is_cingular",
  "not_cingular",
  "reject_incoming_leads",
  "no_first_name_last_name",

  "no_spanish_keywords",
  "has_spanish_keywords"
];

const dayNames = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];
const hours = _.range(24);
const minutes = _.range(0, 60, 5);
const availableListTypes = ["default", "two-way", "weighted", "click-timed"];

const CreateList = ({ open, close }) => {
  const [name, setName] = useState();
  const [brand, setBrand] = useState();
  const [listTypes, setListTypes] = useState(["default"]);
  const [directLink, setDirectLink] = useState();
  const [intake, setIntake] = useState();
  const [days, setDays] = useState([]);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [unsubDays, setUnsubDays] = useState();
  const [followupCount, setFollowupCount] = useState();
  const [gateway, setGateway] = useState({
    name: "plivo"
  });
  const [rules, setRules] = useState([]);
  const [domain, setDomain] = useState();
  const [fellOffList, setFellOffList] = useState(null);
  const [reSignupList, setReSignupList] = useState(null);
  const [useAsReSignupList, setUseAsResignUpList] = useState(false);
  const [useAsFellOffList, setUseAsFellOffList] = useState(false);
  const token = useSelector(state => state.auth.token);
  const lists = useSelector(state => state.lists.data) || [];
  const reSignupLists = lists.filter(list => list.useAsReSignupList);
  const fellOffLists = lists.filter(list => list.useAsFellOffList);

  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      title="Create List"
      content={
        <Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <Typography>
                  <strong>Name:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={name}
                  onChange={e => {
                    setName(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Brand:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={brand}
                  onChange={e => {
                    setBrand(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Type:</strong>
                </Typography>

                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={listTypes}
                  onChange={e => {
                    setListTypes(e.target.value);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {availableListTypes.map(listType => (
                    <MenuItem key={listType} value={listType}>
                      {listType}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Gateway:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={JSON.stringify(gateway)}
                  onChange={e => {
                    setGateway(JSON.parse(e.target.value));
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Direct Link:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={directLink}
                  onChange={e => {
                    setDirectLink(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Intake:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={intake}
                  onChange={e => {
                    setIntake(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Domain:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={name}
                  onChange={e => {
                    setDomain(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Days:</strong>
                </Typography>

                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={days}
                  onChange={e => {
                    setDays(e.target.value);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {dayNames.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Hour:</strong>
                </Typography>

                <Select
                  native
                  value={hour}
                  onChange={e => {
                    setHour(e.target.value);
                  }}
                >
                  {hours.map(h => (
                    <option key={h} value={h}>
                      {h}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Minute:</strong>
                </Typography>

                <Select
                  native
                  value={minute}
                  onChange={e => {
                    setMinute(e.target.value);
                  }}
                >
                  {minutes.map(m => (
                    <option key={m} value={m}>
                      {m}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Followup:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={followupCount}
                  onChange={e => {
                    setFollowupCount(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Unsub after (days):</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={unsubDays}
                  onChange={e => {
                    setUnsubDays(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Rules:</strong>
                </Typography>

                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={rules}
                  onChange={e => {
                    setRules(e.target.value);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {rulesNames.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Grid item md={8}>
                  <Typography>
                    <strong>Re-signup list:</strong>
                  </Typography>

                  <Select
                    native
                    value={reSignupList}
                    onChange={e => {
                      setReSignupList(e.target.value);
                    }}
                  >
                    <option key="none" value="">
                      None
                    </option>
                    {reSignupLists.map(item => (
                      <option key={item._id} value={item._id}>
                        {`${item.name} - ${item._id}`}
                      </option>
                    ))}
                  </Select>
                </Grid>

                <Typography>
                  <strong>Fell off list:</strong>
                </Typography>

                <Select
                  native
                  value={fellOffList}
                  onChange={e => {
                    setFellOffList(e.target.value);
                  }}
                >
                  <option key="none" value="">
                    None
                  </option>
                  {fellOffLists.map(item => (
                    <option key={item._id} value={item._id}>
                      {`${item.name} - ${item._id}`}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Use as re-signup list:</strong>
                </Typography>

                <Checkbox
                  color="primary"
                  checked={useAsReSignupList}
                  onChange={e => {
                    setUseAsResignUpList(e.target.checked);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Use as fell off list:</strong>
                </Typography>

                <Checkbox
                  color="primary"
                  checked={useAsFellOffList}
                  onChange={e => {
                    setUseAsFellOffList(e.target.checked);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <br />
          <br />
        </Box>
      }
      actions={
        <Box>
          <Button
            color="primary"
            onClick={() => {
              dispatch(
                createList({
                  token,
                  name,
                  brand,
                  listTypes,
                  intake,
                  days,
                  hour,
                  minute,
                  followupCount,
                  unsubDays,
                  directLink,
                  gateway,
                  rules,
                  domain,
                  fellOffList,
                  reSignupList,
                  useAsReSignupList,
                  useAsFellOffList
                })
              );

              close();
            }}
          >
            Save
          </Button>
          <Button color="primary" onClick={() => close()}>
            Cancel
          </Button>
        </Box>
      }
    />
  );
};

const EditList = ({ open, close, list }) => {
  const currentListType = list.listType || "default";

  const [name, setName] = useState(list.name);
  const [brand, setBrand] = useState(list.brand);
  const [listTypes, setListTypes] = useState(
    list.listTypes || [currentListType]
  );
  const [directLink, setDirectLink] = useState(list.directLink);
  const [intake, setIntake] = useState(list.intake);
  const [days, setDays] = useState(list.days);
  const [hour, setHour] = useState(list.hour);
  const [minute, setMinute] = useState(list.minute);
  const [unsubDays, setUnsubDays] = useState(list.unsubDays);
  const [followupCount, setFollowupCount] = useState(list.followupCount);
  const [fellOffList, setFellOffList] = useState(list.fellOffList || null);
  const [reSignupList, setReSignupList] = useState(list.reSignupList || null);
  const [useAsReSignupList, setUseAsResignUpList] = useState(
    list.useAsReSignupList || false
  );
  const [useAsFellOffList, setUseAsFellOffList] = useState(
    list.useAsFellOffList || false
  );

  let gatewayValue = list.gateway;
  const lists = useSelector(state => state.lists.data) || [];
  const reSignupLists = lists.filter(
    item => item.useAsReSignupList && item._id !== list._id
  );
  const fellOffLists = lists.filter(
    item => item.useAsFellOffList && item._id !== list._id
  );

  if (!list.gateway) {
    gatewayValue = { name: "plivo", powerpack: list.powerpack };
  }

  const [gateway, setGateway] = useState(gatewayValue);

  const [rules, setRules] = useState(list.rules || []);

  const [domain, setDomain] = useState(list.domain);

  const token = useSelector(state => state.auth.token);
  const dispatch = useDispatch();

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      title="Update List"
      content={
        <Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={8}>
                <Typography>
                  <strong>Name:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={name}
                  onChange={e => {
                    setName(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Brand:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={brand}
                  onChange={e => {
                    setBrand(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Type:</strong>
                </Typography>

                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={listTypes}
                  onChange={e => {
                    setListTypes(e.target.value);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {availableListTypes.map(listType => (
                    <MenuItem key={listType} value={listType}>
                      {listType}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Gateway:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={JSON.stringify(gateway)}
                  onChange={e => {
                    try {
                      let gateway = JSON.parse(e.target.value);
                      setGateway(gateway);
                    } catch (e) {
                      console.error("Invalid Gateway");
                    }
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Direct Link:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={directLink}
                  onChange={e => {
                    setDirectLink(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Intake:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={intake}
                  onChange={e => {
                    setIntake(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Domain:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={domain}
                  onChange={e => {
                    setDomain(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Days:</strong>
                </Typography>

                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={days}
                  onChange={e => {
                    setDays(e.target.value);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {dayNames.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Hour:</strong>
                </Typography>

                <Select
                  native
                  value={hour}
                  onChange={e => {
                    setHour(e.target.value);
                  }}
                >
                  {hours.map(h => (
                    <option key={h} value={h}>
                      {h}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Minute:</strong>
                </Typography>

                <Select
                  native
                  value={minute}
                  onChange={e => {
                    setMinute(e.target.value);
                  }}
                >
                  {minutes.map(m => (
                    <option key={m} value={m}>
                      {m}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Followup:</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={followupCount}
                  onChange={e => {
                    setFollowupCount(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Unsub after (days):</strong>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  defaultValue={unsubDays}
                  onChange={e => {
                    setUnsubDays(e.target.value);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Rules:</strong>
                </Typography>

                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={rules}
                  onChange={e => {
                    setRules(e.target.value);
                  }}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {selected.map(value => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {rulesNames.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Re-signup list:</strong>
                </Typography>

                <Select
                  native
                  value={reSignupList}
                  onChange={e => {
                    setReSignupList(e.target.value);
                  }}
                >
                  <option key="none" value="">
                    None
                  </option>
                  {reSignupLists.map(item => (
                    <option key={item._id} value={item._id}>
                      {`${item.name} - ${item._id}`}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Fell off list:</strong>
                </Typography>

                <Select
                  native
                  value={fellOffList}
                  onChange={e => {
                    setFellOffList(e.target.value);
                  }}
                >
                  <option key="none" value="">
                    None
                  </option>
                  {fellOffLists.map(item => (
                    <option key={item._id} value={item._id}>
                      {`${item.name} - ${item._id}`}
                    </option>
                  ))}
                </Select>
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Use as re-signup list:</strong>
                </Typography>

                <Checkbox
                  color="primary"
                  checked={useAsReSignupList}
                  onChange={e => {
                    setUseAsResignUpList(e.target.checked);
                  }}
                />
              </Grid>

              <Grid item md={8}>
                <Typography>
                  <strong>Use as fell off list:</strong>
                </Typography>

                <Checkbox
                  color="primary"
                  checked={useAsFellOffList}
                  onChange={e => {
                    setUseAsFellOffList(e.target.checked);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <br />
          <br />
        </Box>
      }
      actions={
        <Box>
          <Button
            color="primary"
            onClick={() => {
              dispatch(
                updateList({
                  _id: list._id,
                  token,
                  name,
                  brand,
                  listTypes,
                  gateway,
                  intake,
                  days,
                  hour,
                  minute,
                  followupCount,
                  unsubDays,
                  directLink,
                  rules,
                  domain,
                  fellOffList,
                  reSignupList,
                  useAsReSignupList,
                  useAsFellOffList
                })
              );

              close();
            }}
          >
            Save
          </Button>
          <Button color="primary" onClick={() => close()}>
            Cancel
          </Button>
        </Box>
      }
    />
  );
};

const SingleList = ({ list, token }) => {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  const config = getConfig();

  const oldListType = list.listType || "default";
  const listTypes = (list.listTypes || [oldListType]).join(",");

  return (
    <Box>
      <EditList
        list={list}
        open={isEditing}
        close={() => setIsEditing(false)}
      />

      <Card>
        <CardHeader title={list.name} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography>
                <strong>List ID:</strong> {list._id} <br />
                <strong>Brand:</strong> {list.brand} <br />
                <strong>Type:</strong> {listTypes} <br />
                <strong>Active Leads:</strong> {list.activeLeadsCount} <br />
                <strong>Gateway:</strong>{" "}
                {JSON.stringify(list.gateway) || list.powerpack} <br />
                <strong>Direct Link:</strong> {list.directLink || "-"} <br />
                <strong>Intake:</strong> {list.intake}% <br />
                <strong>Domain:</strong> {list.domain || "Default"}
                <br />
                <strong>Days:</strong> {list.days.join(", ")} <br />
                <strong>Hour:</strong> {list.hour} (24HR)
                <br />
                <strong>Minute:</strong> {list.minute}
                <br />
                <strong>Followup Count:</strong> {list.followupCount} <br />
                <strong>Unsub After:</strong> {list.unsubDays} days <br />
                <strong>Rules:</strong>{" "}
                {list.rules && list.rules.length
                  ? list.rules.join(", ")
                  : "No rules applied"}
                <br />
                <strong>Incoming Webhook URL:</strong>
                {`${config.api_url}/v1/lists/${list._id}/incoming`}
                <br />
                <strong>Fell Off List:</strong> {list.fellOffList || "None"}
                <br />
                <strong>Re-signup List:</strong> {list.reSignupList || "None"}
                <br />
                <strong>Use As Re-signup List:</strong>{" "}
                {`${list.useAsReSignupList || false}`}
                <br />
                <strong>Use As Fell Off List:</strong>{" "}
                {`${list.useAsFellOffList || false}`}
                <br />
              </Typography>
            </Grid>

            <Grid item md={6} xs={12}>
              <Button
                color="primary"
                size="small"
                variant="contained"
                component={NavLink}
                to={`/app/admin/lists/${list._id}`}
                state={{ list }}
                startIcon={<DashboardIcon />}
              >
                Dashboard
              </Button>

              <br />
              <br />

              <Button
                color="primary"
                size="small"
                variant="contained"
                component={NavLink}
                to={`/app/admin/lists/${list._id}/burst`}
                state={{ list }}
                startIcon={<TextsmsIcon />}
              >
                Burst SMS
              </Button>

              <br />
              <br />

              <Button
                color="primary"
                size="small"
                variant="contained"
                onClick={() => {
                  setIsEditing(true);
                }}
                startIcon={<EditIcon />}
              >
                Edit
              </Button>
              <br />
              <br />

              <Button
                color="secondary"
                size="small"
                variant="contained"
                startIcon={<DeleteIcon />}
                onClick={() => {
                  if (window.confirm("Delete list? You can not undo this!")) {
                    dispatch(deleteList({ _id: list._id, token }));
                  }
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
    </Box>
  );
};

const refresh = (dispatch, token) => {
  dispatch(fetchLists({ token }));
};

const ListsView = () => {
  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const lists = useSelector(state => state.lists.data) || [];

  const dispatch = useDispatch();
  const [createListDialogOpen, setCreateListDialogOpen] = useState(false);

  useEffect(() => {
    refresh(dispatch, auth.token);
  }, [auth.token]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setCreateListDialogOpen(true);
          }}
        >
          Add New
        </Button>

        <br />
        <br />

        <CreateList
          open={createListDialogOpen}
          close={() => {
            setCreateListDialogOpen(false);
          }}
        />

        {lists.map(list => (
          <SingleList key={list._id} list={list} token={auth.token} />
        ))}
      </Container>
    </Page>
  );
};

export default ListsView;

import React, { useEffect, useState } from "react";
import { Grid, Container, makeStyles, Typography } from "@material-ui/core";


import Page from "src/components/Page";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { blockPhoneGlobally } from "../../services/blacklist";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },

  userName: { marginRight: theme.spacing(1) },
  chip: { margin: theme.spacing(0.3) },

  table: {
    minWidth: 650
  }
}));


const BlacklistView = () => {
  const [phone, setPhone] = useState();
  const [requestId, setRequestId] = useState();
  const auth = useSelector((state) => state.auth);

  const classes = useStyles();

  return (
    <Page className={classes.root} title="Blacklist">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={6}>
            <TextField
              id="phone"
              label="Phone"
              onChange={e => {
                setPhone(e.target.value);
              }}
            />

            <br />
            <br />

            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                blockPhoneGlobally(auth.token, phone).then((response) => {
                  setRequestId(response.data.request_id);
                }).catch((err) => {
                  console.error(err);
                });
              }}
            >Blacklist</Button>

            <br />
            <br />

            {requestId && (
              <a target="_blank" href={"https://my.papertrailapp.com/events?q=" + requestId}>Check Progress</a>)}


          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default BlacklistView;

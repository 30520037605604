import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { fetchStatsByAdTypeForDateRange } from "src/services/stats_by_ad_type";
import DataTable from "../../components/DataTable";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { AD_TYPES } from "../../config/ad";

const StatsByAdType = ({ dateRange, auth, permission }) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadStats() {
      setLoading(true);
      const adTypeStats = await fetchStatsByAdTypeForDateRange(
        auth.token,
        dateRange.start,
        dateRange.end
      );
      setStats(adTypeStats.stats);
      setLoading(false);
    }

    loadStats().then(() => {});
  }, [auth.token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Ad Type" />;
  }

  if (!stats) {
    console.log("No Stats by ad type available");
    return null;
  }

  const headerConfig = {
    insert: { display: "excluded" },
    rejected: { display: "excluded" },
    time_inactivity: { display: "excluded" },
    revenue_per_record: { display: "excluded" },
    ls_revenue_per_record: { display: "excluded" },
    partner_revenue_per_record: { display: "excluded" }
  };

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "ad_type", label: "Ad Type" },
      ...getCommonAdminHeaders(stats, headerConfig)
    ];
  } else {
    columns = [
      { name: "ad_type", label: "Ad Type" },
      { name: "revenue", label: "Revenue" }
    ];
  }

  const data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      data.push({
        ad_type: AD_TYPES[row.ad_type] || row.ad_type,
        ...getCommonAdminColumns(row, {})
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        ad_type: AD_TYPES[row.ad_type] || row.ad_type,
        revenue: (row.partner_profit || 0).toFixed(4)
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Ad Type" columns={columns} data={data} />
    </PerfectScrollbar>
  );
};

StatsByAdType.propTypes = {
  className: PropTypes.string
};

export default StatsByAdType;

import { createSlice } from "@reduxjs/toolkit";
import getConfig from "../services/config";

const configSlice = createSlice({
  name: "config",
  initialState: getConfig(),
  reducers: {
    updateConfig(state, action) {
      const { config, value } = action.payload;
      state[config] = value;
    },
  },
});

export const { updateConfig } = configSlice.actions;

export default configSlice.reducer;

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";

import { Box, Card, CardContent } from "@material-ui/core";

import DataTable from "../../components/DataTable";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders
} from "../../utils/datatable";
import {
  calculateTotals,
  getAdditionalStats,
  getAlternativeFields
} from "../../utils/stats";
import MultilineChart from "../../components/charts/Multiline";

import { getChartLabel } from "../../config/chart";
import { fetchAggregateForDateRange } from "../../services/revenue";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { calculatePercentage } from "../../utils/math";

const TopRow = ({ dateRange, token, permission }) => {
  const [aggregate, setAggregate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadAggregateData() {
      setLoading(true);
      const aggregateData = await fetchAggregateForDateRange(
        token,
        dateRange.start,
        dateRange.end
      );
      setAggregate(aggregateData.aggregate);
      setLoading(false);
    }

    loadAggregateData().then(() => {});
  }, [token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="Quick Look" />;
  }
  if (!aggregate) {
    return null;
  }

  const stats = [];
  for (const revRow of aggregate) {
    const row = { ...revRow, ...getAlternativeFields(revRow) };
    stats.push({ ...row, additional_stats: getAdditionalStats(row) });
  }
  const totals = calculateTotals(stats, ["accepted_ping", "rejected_ping"]);
  totals.ping = totals.accepted_ping + totals.rejected_ping;

  let chartItems, chartLabelConfig;
  if (permission.view === "internal") {
    chartItems = [
      "insert",
      "accepted_ping",
      "rejected_ping",
      "ping_type_reactivate",
      "source_blacklisted",
      "carrier_blacklisted",
      "keyword_blacklisted",
      "blacklisted_phones",
      "purged_phones",

      "welcome_send",
      "alerts_send",
      "total_send",

      "delivered",
      "failed",
      "unsub",
      "time_inactivity",

      "welcome_click",
      "alerts_click",
      "total_click",

      "cost",

      "revenue",
      "ls_revenue",
      "partner_revenue",

      "profit",
      "ls_profit",
      "partner_profit",

      "revenue_per_record",
      "ls_revenue_per_record",
      "partner_revenue_per_record",

      "revenue_per_mile",
      "ls_revenue_per_mile",
      "partner_revenue_per_mile",

      "earning_per_click",
      "ls_earning_per_click",
      "partner_earning_per_click"
    ];
    chartLabelConfig = {};
  } else {
    chartItems = ["insert", "partner_profit"];
    chartLabelConfig = { partner_profit: "Profit", insert: "Total New Users" };
  }
  const labels = Object.values(stats).map(item => item.date);

  const chartData = [];
  for (const chartItem of chartItems) {
    const row = Object.values(stats).map(
      value => value[chartItem] || value?.additional_stats?.[chartItem]
    );
    chartData.push({
      label: getChartLabel(chartItem, chartLabelConfig),
      data: row,
      hidden: chartItem !== "insert"
    });
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "date", label: "Date" },

      {
        name: "accepted_ping",
        label: "Accepted Ping",
        options: {
          display: false,
          footerData: `${totals.accepted_ping} (${calculatePercentage(
            totals.accepted_ping,
            totals.ping
          ).toFixed(2)}%)`
        }
      },
      {
        name: "rejected_ping",
        label: "Rejected Ping",
        options: {
          display: false,
          footerData: `${totals.rejected_ping} (${calculatePercentage(
            totals.rejected_ping,
            totals.ping
          ).toFixed(2)}%)`
        }
      },

      {
        name: "ping_type_reactivate",
        label: "Reactivate",
        options: {
          display: false
        }
      },

      {
        name: "ping_type_unique_insert",
        label: "Unique Accepted",
        options: {
          display: false
        }
      },

      {
        name: "ping_type_reactivate_insert",
        label: "Reactivate Accepted",
        options: {
          display: false
        }
      },
      {
        name: "ping_type_duplicate_active_insert",
        label: "Duplicate Active Accepted",
        options: {
          display: false
        }
      },
      {
        name: "ping_type_duplicate_soft_stop_insert",
        label: "Duplicate Soft Stop Accepted",
        options: {
          display: false
        }
      },

      {
        name: "source_blacklisted",
        label: "Source Rejected",
        options: { display: false }
      },
      {
        name: "carrier_blacklisted",
        label: "Carrier Rejected",
        options: {
          display: false
        }
      },
      {
        name: "operating_system_blacklisted",
        label: "OS Rejected",
        options: {
          display: false
        }
      },
      {
        name: "keyword_blacklisted",
        label: "Keyword Rejected",
        options: {
          display: false
        }
      },

      {
        name: "blacklisted_phones",
        label: "Blacklisted Phones",
        options: { display: false }
      },
      {
        name: "purged_phones",
        label: "Purged Phones",
        options: {
          display: false
        }
      },

      ...getCommonAdminHeaders(stats, { action: false }),

      {
        name: "company_categorization_page_view",
        label: "Company Categorization Page Views",
        options: {
          display: false
        }
      },
      {
        name: "company_categorization_keyword_updates",
        label: "Company Categorization Total Keyword Updates",
        options: {
          display: false
        }
      },

      {
        name: "average_profit_per_record_for_7_days",
        label: "7-Day Avg PPR",
        options: {
          display: false,
          footerData: ""
        }
      },
      {
        name: "average_profit_per_record_for_14_days",
        label: "14-Day Avg PPR",
        options: {
          display: false,
          footerData: ""
        }
      },
      {
        name: "average_profit_per_record_for_30_days",
        label: "30-Day Avg PPR",
        options: {
          display: false,
          footerData: ""
        }
      },
      {
        name: "average_profit_per_record_for_45_days",
        label: "45-Day Avg PPR",
        options: {
          display: false,
          footerData: ""
        }
      },
      {
        name: "average_profit_per_record_for_60_days",
        label: "60-Day Avg PPR",
        options: {
          display: false,
          footerData: ""
        }
      },
      {
        name: "average_profit_per_record_for_90_days",
        label: "90-Day Avg PPR",
        options: {
          display: false,
          footerData: ""
        }
      }
    ];
  } else {
    columns = [
      { name: "date", label: "Date" },
      {
        name: "insert",
        label: "Total New Users"
      },
      { name: "partner_profit", label: "Profit" },
      {
        name: "partner_revenue_per_record",
        label: "RPR",
        options: {
          footerData: ""
        }
      }
    ];
  }

  let data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      const acceptedPing =
        row.accepted_ping || row.accepted_ping_for_phone || 0;
      const rejectedPing =
        row.rejected_ping || row.rejected_ping_for_phone || 0;
      const totalPing = acceptedPing + rejectedPing;

      data.push({
        date: row.date,
        accepted_ping: `${acceptedPing} (${calculatePercentage(
          acceptedPing,
          totalPing
        ).toFixed(2)}%)`,
        rejected_ping: `${rejectedPing} (${calculatePercentage(
          rejectedPing,
          totalPing
        ).toFixed(2)}%)`,
        ping_type_reactivate:
          (row.ping_type?.reactivate || 0) +
          (row.ping_type?.duplicate_time_inactivity || 0),

        ping_type_unique_insert: row.ping_type?.unique_insert || 0,
        ping_type_reactivate_insert: row.ping_type?.reactivate_insert || 0,
        ping_type_duplicate_active_insert:
          row.ping_type?.duplicate_active_insert || 0,
        ping_type_duplicate_soft_stop_insert:
          row.ping_type?.duplicate_soft_stop_insert || 0,

        source_blacklisted: row.source_blacklisted || 0,
        carrier_blacklisted: row.carrier_blacklisted || 0,
        operating_system_blacklisted: row.operating_system_blacklisted || 0,
        keyword_blacklisted: row.keyword_blacklisted || 0,

        blacklisted_phones: row.blacklisted_phones || 0,
        purged_phones: row.purged_phones || 0,

        ...getCommonAdminColumns(row, { action: false }),

        company_categorization_page_view:
          row.company_categorization_page_view || 0,
        company_categorization_keyword_updates:
          row.company_categorization_keyword_updates || 0,

        average_profit_per_record_for_7_days: (
          row?.additional_stats?.average_profit_per_record_for_7_days || 0
        ).toFixed(4),
        average_profit_per_record_for_14_days: (
          row?.additional_stats?.average_profit_per_record_for_14_days || 0
        ).toFixed(4),
        average_profit_per_record_for_30_days: (
          row?.additional_stats?.average_profit_per_record_for_30_days || 0
        ).toFixed(4),
        average_profit_per_record_for_45_days: (
          row?.additional_stats?.average_profit_per_record_for_45_days || 0
        ).toFixed(4),
        average_profit_per_record_for_60_days: (
          row?.additional_stats?.average_profit_per_record_for_60_days || 0
        ).toFixed(4),
        average_profit_per_record_for_90_days: (
          row?.additional_stats?.average_profit_per_record_for_90_days || 0
        ).toFixed(4)
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        date: row.date,
        insert: row.insert || 0,
        partner_profit: (row.partner_profit || 0).toFixed(4),
        partner_revenue_per_record: (
          row.additional_stats?.partner_revenue_per_record || 0
        ).toFixed(4)
      });
    }
  }

  return (
    <Card>
      <CardContent>
        <PerfectScrollbar>
          <DataTable
            title="Quick Look"
            columns={columns}
            data={data.reverse()}
          />

          {stats.length ? (
            <Box m={2}>
              <MultilineChart labels={labels} datasets={chartData} />
            </Box>
          ) : null}
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};
TopRow.propTypes = {
  className: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default TopRow;

import axios from "axios";
import getConfig from "./config";

export async function fetchHealthCheckDataForAllDomains(domains, start, end) {
  const results = {};

  const promises = domains.map((domain) => {
    const config = getConfig(domain);
    const endpoint = config.api_url + "/v1/health-check";

    return axios.get(endpoint);
  });

  const allResults = await Promise.allSettled(promises);

  for (let i = 0; i < domains.length; i++) {
    const domain = domains[i];
    const result = allResults[i];

    if (result.status === "fulfilled") {
      results[domain] = result.value.data;
    } else {
      results[domain] = {};
    }
  }

  return results;
}

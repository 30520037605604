import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { fetchStatsBySourceForDateRange } from "src/services/email/stats";
import DataTable from "../../components/DataTable";
import {
  blockSource,
  getBlockedSources,
  unblockSource,
} from "src/services/email/blacklist";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders,
  getHeadersForEvent,
} from "../../utils/email/stats_table";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

import { EVENT_TYPES } from "src/config/email/events";

const StatsBySource = ({ dateRange, token, permission }) => {
  const [stats, setStats] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [blockedSources, setBlockedSources] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadStats() {
      setLoading(true);
      const sourceStats = await fetchStatsBySourceForDateRange(
        token,
        dateRange.start,
        dateRange.end
      );
      setStats(sourceStats.stats);

      const blockedSourceData = {};
      const blockedData = await getBlockedSources(token);
      for (const item of blockedData.sources) {
        const source = item.source;
        blockedSourceData[source] = true;
      }
      setBlockedSources(blockedSourceData);
      setLoading(false);
    }

    loadStats().then(() => {});
  }, [token, dateRange, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Source" />;
  }
  if (!stats) {
    console.log("No email stats by source available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "source", label: "Source" },
      ...getHeadersForEvent({
        event: "insert",
        eventName: "Accepted",
        EVENT_TYPES,
      }),
      ...getCommonAdminHeaders(stats, { action: true }),
    ];
  } else {
    columns = [{ name: "source", label: "Source" }];
  }

  const data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      const isBlocked = blockedSources[row.source] || false;
      let action;
      if (isBlocked) {
        action = async (e) => {
          await unblockSource(token, row.source);
          setRefreshTrigger(new Date());
        };
      } else {
        action = async (e) => {
          await blockSource(token, row.source);
          setRefreshTrigger(new Date());
        };
      }

      data.push({
        source: row.source,
        "insert.total":
          (row?.insert?.unique?.total || 0) +
          (row?.insert?.duplicate?.total || 0) +
          (row?.insert?.inbox_ally?.total || 0),
        "insert.unique.total": row?.insert?.unique?.total || 0,
        "insert.duplicate.total": row?.insert?.duplicate?.total || 0,
        "insert.inbox_ally.total": row?.insert?.inbox_ally?.total || 0,
        ...getCommonAdminColumns(row, { action, isBlocked }),
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        source: row.source,
        revenue: (row.partner_profit || 0).toFixed(4),
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable
        title="Stats By Source"
        columns={columns}
        data={data}
        // options={{ selectableRows: "multiple" }}
      />
    </PerfectScrollbar>
  );
};

export default StatsBySource;

import axios from "axios";
import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;

export async function blockPhone(token, phone) {
  const endpoint = apiURL + "/v1/blacklist/phone";
  const response = await axios.post(
    endpoint,
    {
      phone,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockPhoneGlobally(token, phone) {
  const endpoint = "https://ejmsms.com/s/w/blacklist";
  const response = await axios.post(
    endpoint,
    {
      phone,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockSource(token, source) {
  const endpoint = apiURL + "/v1/blacklist/source";
  const response = await axios.post(
    endpoint,
    {
      source,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockCarrier(token, carrier) {
  const endpoint = apiURL + "/v1/blacklist/carrier";
  const response = await axios.post(
    endpoint,
    {
      carrier,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockOperatingSystem(token, operatingSystem) {
  const endpoint = `${apiURL}/v1/blacklist/operating-system`;
  const response = await axios.post(
    endpoint,
    {
      operating_system: operatingSystem,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockKeyword(token, keyword) {
  const endpoint = apiURL + "/v1/blacklist/keyword";
  const response = await axios.post(
    endpoint,
    {
      keyword,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockSourceOnList(token, source, list_id) {
  const endpoint = apiURL + "/v1/blacklist/list-source";
  const response = await axios.post(
    endpoint,
    {
      source,
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockCarrierOnList(token, carrier, list_id) {
  const endpoint = apiURL + "/v1/blacklist/list-carrier";
  const response = await axios.post(
    endpoint,
    {
      carrier,
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockOperatingSystemOnList(
  token,
  operatingSystem,
  list_id
) {
  const endpoint = `${apiURL}/v1/blacklist/list-operating-system`;
  const response = await axios.post(
    endpoint,
    {
      operating_system: operatingSystem,
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function blockKeywordOnList(token, keyword, list_id) {
  const endpoint = apiURL + "/v1/blacklist/list-keyword";
  const response = await axios.post(
    endpoint,
    {
      keyword,
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function unblockSource(token, source) {
  const endpoint = apiURL + "/v1/blacklist/unblock-source";
  const response = await axios.post(
    endpoint,
    {
      source,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function unblockCarrier(token, carrier) {
  const endpoint = apiURL + "/v1/blacklist/unblock-carrier";
  const response = await axios.post(
    endpoint,
    {
      carrier,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function unblockOperatingSystem(token, operatingSystem) {
  const endpoint = `${apiURL}/v1/blacklist/unblock-operating-system`;
  const response = await axios.post(
    endpoint,
    {
      operating_system: operatingSystem,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function unblockKeyword(token, keyword) {
  const endpoint = apiURL + "/v1/blacklist/unblock-keyword";
  const response = await axios.post(
    endpoint,
    {
      keyword,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function unblockSourceOnList(token, source, list_id) {
  const endpoint = apiURL + "/v1/blacklist/unblock-list-source";
  const response = await axios.post(
    endpoint,
    {
      source,
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function unblockCarrierOnList(token, carrier, list_id) {
  const endpoint = apiURL + "/v1/blacklist/unblock-list-carrier";
  const response = await axios.post(
    endpoint,
    {
      carrier,
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function unblockOperatingSystemOnList(
  token,
  operatingSystem,
  list_id
) {
  const endpoint = `${apiURL}/v1/blacklist/unblock-list-operating-system`;
  const response = await axios.post(
    endpoint,
    {
      operating_system: operatingSystem,
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function unblockKeywordOnList(token, keyword, list_id) {
  const endpoint = apiURL + "/v1/blacklist/unblock-list-keyword";
  const response = await axios.post(
    endpoint,
    {
      keyword,
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function getBlockedSources(token) {
  const endpoint = apiURL + "/v1/blacklist/blocked-sources";
  const response = await axios.get(endpoint, { headers: { token } });

  return response.data;
}

export async function getBlockedCarriers(token) {
  const endpoint = apiURL + "/v1/blacklist/blocked-carriers";
  const response = await axios.get(endpoint, { headers: { token } });

  return response.data;
}

export async function getBlockedOperatingSystems(token) {
  const endpoint = `${apiURL}/v1/blacklist/blocked-operating-systems`;
  const response = await axios.get(endpoint, { headers: { token } });

  return response.data;
}

export async function getBlockedKeywords(token) {
  const endpoint = apiURL + "/v1/blacklist/blocked-keywords";
  const response = await axios.get(endpoint, { headers: { token } });

  return response.data;
}

export async function getBlockedSourcesOnList(token, list_id) {
  const endpoint = apiURL + "/v1/blacklist/blocked-list-sources";
  const response = await axios.post(
    endpoint,
    {
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function getBlockedCarriersOnList(token, list_id) {
  const endpoint = apiURL + "/v1/blacklist/blocked-list-carriers";
  const response = await axios.post(
    endpoint,
    {
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function getBlockedOperatingSystemsOnList(token, list_id) {
  const endpoint = `${apiURL}/v1/blacklist/blocked-list-operating-systems`;
  const response = await axios.post(
    endpoint,
    {
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

export async function getBlockedKeywordsOnList(token, list_id) {
  const endpoint = apiURL + "/v1/blacklist/blocked-list-keywords";
  const response = await axios.post(
    endpoint,
    {
      list_id,
    },
    { headers: { token } }
  );

  return response.data;
}

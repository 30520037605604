import React from 'react';
import { Box, Typography } from '@material-ui/core';
import Page from 'src/components/Page';

const HomeView = () => {
  return (
    <Page title="Hello World!">
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        {/*<Typography variant="h2">Hi there stranger, nice to meet you!</Typography>*/}
      </Box>
    </Page>
  );
};

export default HomeView;

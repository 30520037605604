import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";

import { divideOrGetZero } from "../../utils/math";
import DataTable from "src/components/DataTable";
import { fetchAggregateForDateRange } from "../../services/revenue";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const SecondRow = ({ token, dateRange }) => {
  const [aggregate, setAggregate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadAggregateData() {
      setLoading(true);
      const aggregateData = await fetchAggregateForDateRange(
        token,
        dateRange.start,
        dateRange.end
      );
      setAggregate(aggregateData.aggregate);
      setLoading(false);
    }

    loadAggregateData().then(() => {});
  }, [token, dateRange]);

  if (loading) {
    return <LoadingSpinnerForStats title="More Stats" />;
  }
  if (!aggregate) {
    return null;
  }

  const columns = [
    { name: "date", label: "Date" },
    { name: "revenue", label: "Revenue" },
    { name: "network_unique_revenue", label: "Unique Revenue" },
    { name: "network_wide_unique", label: "Network Wide Unique" },
    {
      name: "revenue_per_network_wide_unique",
      label: "Rev/NWU",
      options: { footerData: "" }
    },
    { name: "network_duplicate_revenue", label: "Dups Revenue" },
    { name: "network_wide_duplicates", label: "Network Wide Duplicates" },
    {
      name: "revenue_per_network_wide_duplicate",
      label: "Rev/NWD",
      options: { footerData: "" }
    }
  ];

  const data = [];

  for (const row of aggregate) {
    data.push({
      date: row.date,
      revenue: (row.revenue || 0).toFixed(4),
      network_unique_revenue: (row.network_unique_revenue || 0).toFixed(4),
      network_wide_unique: (row.network_wide_unique || 0).toFixed(4),
      revenue_per_network_wide_unique: divideOrGetZero(
        row.network_unique_revenue,
        row.network_wide_unique
      ).toFixed(4),
      network_duplicate_revenue: (row.network_duplicate_revenue || 0).toFixed(
        4
      ),
      network_wide_duplicates: row.network_wide_duplicates || 0,
      revenue_per_network_wide_duplicate: divideOrGetZero(
        row.network_duplicate_revenue,
        row.network_wide_duplicates
      ).toFixed(4)
    });
  }

  return (
    <PerfectScrollbar>
      <DataTable title="More Stats" columns={columns} data={data.reverse()} />
    </PerfectScrollbar>
  );
};

SecondRow.propTypes = {
  className: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default SecondRow;

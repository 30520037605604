import React, { useEffect, useState } from "react";
import { Grid, Container, makeStyles } from "@material-ui/core";
import moment from "moment";

import { fetchAggregateForAllDomains } from "src/services/revenue";
import getConfig from "src/services/config";

import Page from "src/components/Page";
import ChartView from "./ChartView";

import { clientInfoMap } from "src/services/client";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const refresh = (setData, dateRange) => {
  fetchAggregateForAllDomains(
    Object.keys(clientInfoMap),
    dateRange.start,
    dateRange.end
  )
    .then((response) => {
      const stats = {};

      for (const [domain, data] of Object.entries(response)) {
        const config = getConfig(domain);
        const info = clientInfoMap[domain];

        stats[info.domain] = {
          ...data,
          ...info,
          ...config,
        };
      }

      setData(stats);
    })
    .catch((err) => {
      console.log(err);
    });
};

const GraphView = () => {
  const classes = useStyles();
  const [data, setData] = useState({});

  const dateRange = {
    start: moment()
      .subtract(60, "days")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };

  useEffect(() => {
    refresh(setData, dateRange);
  }, []);

  return (
    <Page className={classes.root} title="Graph">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={10} md={12} xl={9} xs={12}>
            {Object.keys(data).length ? (
              <ChartView data={data} onChange={(stat) => console.log(stat)} />
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default GraphView;

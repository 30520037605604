export const CHART_ITEM_TO_LABEL_MAP = {
  insert: "Accepted",

  send: "Job Alert Send",

  cost: "Sending Cost",
  revenue: "Total Revenue",
  profit: "Total Profit",

  revenue_per_record: "RPR",
  ls_revenue_per_record: "LSRPR",
  partner_revenue_per_record: "PRPR",

  earning_per_click: "EPC",
  ls_earning_per_click: "LSEPC",
  partner_earning_per_click: "PEPC"
};

function getDefaultLabel(item) {
  return item
    .split("_")
    .map(value => value.charAt(0).toUpperCase() + value.slice(1))
    .join(" ");
}

export function getChartLabel(item, config = {}) {
  return config[item] || CHART_ITEM_TO_LABEL_MAP[item] || getDefaultLabel(item);
}

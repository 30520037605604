import axios from "axios";
import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;

const endpoint = `${apiURL}/v1/stats/by-operating-system`;

export async function fetchStatsByOperatingSystemForDateRange(
  token,
  start,
  end
) {
  const response = await axios.post(
    endpoint,
    {
      start,
      end,
    },
    { headers: { token } }
  );

  return response.data;
}

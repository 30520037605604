import React from "react";
import { Navigate } from "react-router-dom";

// Layout
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import SERPLayout from "./layouts/SERPLayout";
import { CompanyCategorizationLayout } from "./layouts/CompanyCategorizationLayout";

// Our view
import HomeView from "src/views/home/HomeView";
import NotFoundView from "src/views/errors/NotFoundView";
import LoginView from "src/views/auth/LoginView";
import DashboardView from "src/views/dashboard/DashboardView";
import DocsView from "src/views/docs/DocsView";
import SearchJobsList from "./views/search/SearchJobsList";
import AccountingView from "src/views/accounting/AccountingView";
import ListsView from "src/views/lists/ListsView";
import ListsDashboardView from "src/views/lists/ListsDashboardView";
import ListsBurstSMSView from "src/views/lists/BurstSMSView";
import GraphView from "src/views/graph/GraphView";
import UserStatsView from "src/views/user-stats/UserStatsView";
import UserHistoryView from "src/views/user-stats/UserHistoryView";
import HealthCheckView from "src/views/health-check/HealthCheckView";
import EmailDashboard from "src/views/email-dashboard";
import IncomingMessagesView from "src/views/incoming-messages/IncomingMessagesView";
import CompanyCategorizationView from "./views/user/CompanyCategorization";
import BlacklistView from "./views/blacklist/BlacklistView";

const routes = [
  {
    path: "app/admin",
    element: <DashboardLayout />,
    children: [
      { path: "blacklist", element: <BlacklistView /> },
      { path: "dashboard", element: <DashboardView /> },
      { path: "email-dashboard", element: <EmailDashboard /> },
      { path: "graph", element: <GraphView /> },
      { path: "docs", element: <DocsView /> },
      { path: "accounting", element: <AccountingView /> },
      { path: "health-check", element: <HealthCheckView /> },
      { path: "lists", element: <ListsView /> },
      { path: "stats/user", element: <UserStatsView /> },
      { path: "incoming-messages", element: <IncomingMessagesView /> },
      { path: "stats/user/:phone", element: <UserHistoryView /> },
      { path: "lists/:listId", element: <ListsDashboardView /> },
      { path: "lists/:listId/burst", element: <ListsBurstSMSView /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  },

  {
    path: "app/search",
    element: <SERPLayout />,
    children: [
      { path: ":searchId/:type", element: <SearchJobsList /> },
      { path: ":searchId/:type/:popup", element: <SearchJobsList /> }
    ]
  },

  {
    path: "app/user/company-categorization",
    element: <CompanyCategorizationLayout />,
    children: [{ path: ":phone", element: <CompanyCategorizationView /> }]
  },

  {
    path: "app",
    element: <MainLayout />,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "404", element: <NotFoundView /> },
      // { path: '', element: <Navigate to="/app/admin/dashboard" /> },
      { path: "", element: <HomeView /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  makeStyles,
  Card,
  CardHeader,
  CardActions,
  Button,
} from "@material-ui/core";
import Page from "src/components/Page";
import _ from "lodash";

import { fetchHealthCheckDataForAllDomains } from "src/services/health_check";

import { clientInfoMap } from "src/services/client";

import HealthStatus from "./HealthStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const HealthCheckView = () => {
  const classes = useStyles();
  const [healthCheckData, setHealthCheckData] = useState({});
  const [downServices, setDownServices] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(new Date());

  useEffect(() => {
    (async function() {
      const down = [];
      const results = await fetchHealthCheckDataForAllDomains(
        Object.keys(clientInfoMap)
      );

      for (const domain of Object.keys(results)) {
        results[domain] = { ...results[domain], ...clientInfoMap[domain] };

        const components = results[domain].components;
        if (
          !components ||
          !components.redis ||
          !components.mongo ||
          !components.tracer
        ) {
          down.push(domain);
        }
      }

      setDownServices(down);

      setHealthCheckData(results);
    })();
  }, [refreshFlag]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={10} md={12} xl={9} xs={12}>
            <Card>
              <CardHeader
                title={`${downServices.length || "No"} services down`}
              />

              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => setRefreshFlag(new Date())}
                >
                  Refresh
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item lg={10} md={12} xl={9} xs={12}>
            {Object.values(healthCheckData).map((data) => {
              return <HealthStatus key={data.domain} data={data} />;
            })}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default HealthCheckView;

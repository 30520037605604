// noinspection JSUnresolvedVariable
import React from "react";
import { Button } from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import { CONTENT_PROVIDERS_FOR_EMAIL } from "../../config/content_providers";
import {
  getContentProviderColumnValue,
  getContentProviderHeaders,
} from "./content_providers";
import { calculateTotals } from "../stats";
import { calculatePercentage } from "../math";
import { capitalize } from "../text";

const { EVENT_TYPES, TEMPLATE_TYPES } = require("../../config/email/events");

const allTypes = [];
for (const templateType of TEMPLATE_TYPES) {
  for (const eventType of EVENT_TYPES) {
    allTypes.push(`${eventType}.${templateType}`);
  }
  allTypes.push(`${templateType}`);
}

for (const eventType of EVENT_TYPES) {
  allTypes.push(`${eventType}_total`);
}

// TODO: reformat
export function formatLabel(label) {
  return label
    .split("_")
    .map((word) => capitalize(word))
    .join(" ");
}

export function getHeadersForEvent({
  event,
  eventName,
  EVENT_TYPES = [],
  TEMPLATE_TYPES = [],
  totals,
  divisor,
}) {
  eventName = eventName || formatLabel(event);
  const headers = [{ name: `${event}.total`, label: `Total ${eventName}` }];

  for (const eventType of EVENT_TYPES) {
    const formattedEventType = formatLabel(eventType);

    const rootEvent = `${event}.${eventType}.total`;
    headers.push({
      name: rootEvent,
      label: `${formattedEventType} ${eventName}`,
    });

    for (const templateType of TEMPLATE_TYPES) {
      const templateEvent = `${event}.${eventType}.${templateType}.total`;
      headers.push({
        name: templateEvent,
        label: `${formattedEventType} ${capitalize(templateType)} ${eventName}`,
      });
    }
    // for (const templateType of TEMPLATE_TYPES) {
    //   const subEvent = `${event}.${eventType}.${templateType}.total`;
    //   headers.push({
    //     [subEvent]: {
    //       label: `${formattedEventType} ${capitalize(
    //         templateType
    //       )} ${eventName}`,
    //     },
    //   });
    // }
  }
  return headers;
}

export function getCommonAdminHeaders(stats, config = {}) {
  const { action } = config;

  // TODO: add more params
  const basicEvents = [
    "send",
    "open",
    "click",
    "unsubscribes",
    "complaint",
    "soft_bounce",
    "hard_bounce",
    "resubscribe",
  ];
  const totalFields = [];
  for (const event of basicEvents) {
    for (const eventType of ["welcome", "alert", "total"]) {
      totalFields.push(`${eventType}_${event}`);
    }
  }

  const totals = calculateTotals(stats, totalFields);

  const headers = [
    ...getHeadersForEvent({ event: "send", EVENT_TYPES, TEMPLATE_TYPES }),
    ...getHeadersForEvent({
      event: "open",
      EVENT_TYPES,
      TEMPLATE_TYPES,
      totals,
      divisor: "send",
    }),
    ...getHeadersForEvent({
      event: "click",
      EVENT_TYPES,
      TEMPLATE_TYPES,
      totals,
      divisor: "send",
    }),
    ...getHeadersForEvent({
      event: "revenue",
      EVENT_TYPES,
      TEMPLATE_TYPES,
    }),
    { name: "revenue.local_staffing", label: "LS Revenue" },
    { name: "revenue.partner", label: "Partner Revenue" },
    ...getContentProviderHeaders(
      CONTENT_PROVIDERS_FOR_EMAIL,
      "revenue",
      "Total Revenue",
      {
        display: false,
      }
    ),
    ...getHeadersForEvent({
      event: "sending_cost",
      EVENT_TYPES,
      TEMPLATE_TYPES,
    }),
    ...getHeadersForEvent({
      event: "profit",
      EVENT_TYPES,
      TEMPLATE_TYPES,
    }),
    { name: "profit.local_staffing", label: "LS Profit" },
    { name: "profit.partner", label: "Partner Profit" },
    ...getHeadersForEvent({
      event: "revenue_per_record",
      eventName: "RPR",
      EVENT_TYPES,
    }),
    { name: "revenue_per_record.local_staffing", label: "LS RPR" },
    { name: "revenue.partner", label: "Partner RPR" },

    ...getHeadersForEvent({
      event: "revenue_per_mile",
      eventName: "RPM",
      EVENT_TYPES,
      TEMPLATE_TYPES,
    }),

    ...getHeadersForEvent({
      event: "earning_per_click",
      eventName: "EPC",
      EVENT_TYPES,
      TEMPLATE_TYPES,
    }),

    ...getHeadersForEvent({
      event: "unsubscribe",
      EVENT_TYPES,
      TEMPLATE_TYPES,
      totals,
      divisor: "send",
    }),

    ...getHeadersForEvent({
      event: "complaint",
      EVENT_TYPES,
      TEMPLATE_TYPES,
      totals,
      divisor: "send",
    }),
    ...getHeadersForEvent({
      event: "soft_bounce",
      EVENT_TYPES,
      totals,
      divisor: "send",
    }),
    ...getHeadersForEvent({
      event: "hard_bounce",
      EVENT_TYPES,
      totals,
      divisor: "send",
    }),
  ];
  // const headers = [];
  // for (const [field, fieldConfig] of Object.entries(allFields)) {
  //   const rowConfig = { name: field };
  //   rowConfig.label =
  //     fieldConfig.label ||
  //     rowConfig.name
  //       .split("_")
  //       .map((value) => value.charAt(0).toUpperCase() + value.slice(1))
  //       .join(" ");
  //
  //   rowConfig.options = fieldConfig.options || config[rowConfig.name] || {};
  //   if (fieldConfig.dividend && fieldConfig.divisor) {
  //     rowConfig.options.footerData = `${
  //       fieldConfig.dividend
  //     } (${calculatePercentage(
  //       fieldConfig.dividend,
  //       fieldConfig.divisor
  //     ).toFixed(2)}%)`;
  //   }
  //   headers.push(rowConfig);
  // }

  if (action) {
    headers.push({
      name: "action",
      label: "Action",
      options: { filter: false, sort: false, download: false, footerData: "" },
    });
  }

  return headers;
}

function loadCellValue(value, divisor) {
  if (divisor === undefined || divisor === null) {
    return value;
  }
  return `${value} (${calculatePercentage(value, divisor).toFixed(2)}%)`;
}

export function getValuesForEvent({ event, row, divisor, toFixed }) {
  const values = {};

  values[`${event}.total`] = loadCellValue(
    row[event]?.total || 0,
    divisor?.total
  );

  for (const eventType of EVENT_TYPES) {
    values[`${event}.${eventType}.total`] = loadCellValue(
      row[event]?.[eventType]?.total || 0,
      divisor?.[eventType]?.total
    );

    for (const templateType of TEMPLATE_TYPES) {
      values[`${event}.${eventType}.${templateType}.total`] = loadCellValue(
        row[event]?.[eventType]?.[templateType]?.total || 0,
        divisor?.[eventType]?.[templateType]?.total
      );
    }
  }

  // TODO: simplify
  if (toFixed && !divisor) {
    for (const [key, value] of Object.entries(values)) {
      values[key] = value.toFixed(toFixed);
    }
  }
  return values;
}

export function getCommonAdminColumns(row, config = {}) {
  const { isBlocked, action } = config;
  const contentProviders = {};

  for (const contentProvider of Object.keys(CONTENT_PROVIDERS_FOR_EMAIL)) {
    const values = row.content_providers?.[contentProvider] || {};
    contentProviders[contentProvider] = { ...values };
  }

  const columns = {
    ...getValuesForEvent({ event: "send", row }),
    ...getValuesForEvent({ event: "open", row, divisor: row.send }),
    ...getValuesForEvent({ event: "click", row, divisor: row.send }),
    ...getValuesForEvent({ event: "revenue", row, toFixed: 4 }),
    "revenue.local_staffing": (row.revenue?.local_staffing || 0).toFixed(4),
    "revenue.partner": (row.revenue?.partner || 0).toFixed(4),
    ...getContentProviderColumnValue("revenue", contentProviders, {
      toFixed: 4,
    }),

    ...getValuesForEvent({ event: "sending_cost", row, toFixed: 4 }),
    ...getValuesForEvent({ event: "profit", row, toFixed: 4 }),
    "profit.local_staffing": (row.profit?.local_staffing || 0).toFixed(4),
    "profit.partner": (row.profit?.partner || 0).toFixed(4),

    ...getValuesForEvent({ event: "revenue_per_record", row, toFixed: 4 }),
    "revenue_per_record.local_staffing": (
      row.revenue_per_record?.local_staffing || 0
    ).toFixed(4),
    "revenue_per_record.partner": (
      row.revenue_per_record?.partner || 0
    ).toFixed(4),

    ...getValuesForEvent({ event: "revenue_per_mile", row, toFixed: 4 }),
    ...getValuesForEvent({ event: "earning_per_click", row, toFixed: 4 }),

    ...getValuesForEvent({ event: "unsubscribe", row, divisor: row.send }),
    ...getValuesForEvent({ event: "complaint", row, divisor: row.send }),
    ...getValuesForEvent({ event: "soft_bounce", row, divisor: row.send }),
    ...getValuesForEvent({ event: "hard_bounce", row, divisor: row.send }),
    ...getValuesForEvent({ event: "resubscribe", row, divisor: row.send }),
  };

  if (action) {
    columns.action = (
      <Button
        color="primary"
        variant="contained"
        startIcon={isBlocked ? <BackspaceIcon /> : <RemoveCircleIcon />}
        onClick={action}
      >
        {isBlocked ? "Unblock" : "Block"}
      </Button>
    );
  }

  return columns;
}

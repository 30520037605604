import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import configReducer from "./config";
import revenueReducer from "./revenue";
import listsReducer from "./lists";

const store = configureStore({
  reducer: {
    auth: authReducer,
    config: configReducer,
    revenue: revenueReducer,
    lists: listsReducer
  }
});

export default store;

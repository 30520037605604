import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import Dialog from "src/components/Dialog";
import Nunjucks from "nunjucks";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/Send";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayCircleFilled";

import { useSelector } from "react-redux";
import {
  createAdCopyForList,
  fetchAllAdsForList,
  sendTestMessageWithAdCopy,
  setAdCopyStatus,
  updateAdCopy
} from "src/services/lists";

import { AD_TYPES } from "../../config/ad";

import moment from "moment";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const useStyles = makeStyles(theme => ({
  root: {},
  buttons: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  list: {
    paddingLeft: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const adData = {
  first_name: "John",
  last_name: "Doe",
  phone: "1011001000",
  ip_address: "99.203.0.62",
  search: "Warehouse",
  utm_source: "facebook",
  company: "UPS",
  zip: "48341",
  city: "Miami",
  state: "Florida",
  job_title: "Warehouse Job - UPS",
  domain: "example.com",
  day: moment().format("dddd"),
  date: moment().format("YYYY-MM-DD")
};

const RenderedAd = ({ content }) => {
  let renderedContent = null;
  let error = false;

  if (content === "") {
    content = "Type a new ad copy to see preview here";
  }

  try {
    renderedContent = Nunjucks.configure(null, {
      throwOnUndefined: true
    }).renderString(content, adData);
    error = false;
  } catch (e) {
    renderedContent = content;
    error = true;
  }

  return error ? (
    <Alert severity="error">{renderedContent}</Alert>
  ) : (
    <Alert severity="info">{renderedContent}</Alert>
  );
};

const CreateAd = ({ open, listid, close }) => {
  const [adType, setAdType] = useState("default-1click-talent");
  const [content, updateContent] = useState("");
  const [adName, updateAdName] = useState("");
  const [cpc, updateCPC] = useState("");
  const token = useSelector(state => state.auth.token);

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      title="Create Ad"
      content={
        <Box>
          <Typography gutterBottom>
            Create a new ad copy. The available variables are listed below. Use
            the live preview to see how the ad will look like when sent.
          </Typography>

          <br />
          <RenderedAd content={content} />
          <br />

          <Box>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  defaultValue={adName}
                  label="Name"
                  variant="outlined"
                  onChange={e => updateAdName(e.target.value)}
                />
              </Grid>

              <Grid item md={4}>
                <Select
                  label="Ad Type"
                  variant="outlined"
                  native
                  value={adType}
                  onChange={e => {
                    setAdType(e.target.value);
                  }}
                >
                  {Object.entries(AD_TYPES).map(([value, label]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  defaultValue={cpc}
                  label="CPC"
                  variant="outlined"
                  onChange={e => updateCPC(e.target.value)}
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  fullWidth
                  label="Ad Copy"
                  variant="outlined"
                  defaultValue={content}
                  onChange={e => {
                    updateContent(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <br />
          <br />

          <Typography component="div" gutterBottom>
            <div>
              Available variables:
              <ul className={classes.list}>
                {Object.keys(adData)
                  .map(v => `{{${v}}}`)
                  .map(v => (
                    <li key={v}>{v}</li>
                  ))}
              </ul>
            </div>
          </Typography>
        </Box>
      }
      actions={
        <Box>
          <Button
            color="primary"
            onClick={() => {
              if (adName == "" || content == "") {
                alert("Please enter ad name and content");
                return;
              }

              createAdCopyForList(token, listid, adName, content, cpc, adType)
                .then(response => {
                  close();
                })
                .catch(e => {
                  console.error(e);
                  alert("Error saving ad");
                });
            }}
          >
            Save
          </Button>
          <Button color="primary" onClick={() => close()}>
            Cancel
          </Button>
        </Box>
      }
    />
  );
};

const TestAdCopy = ({ ad, open, close }) => {
  const token = useSelector(state => state.auth.token);

  const [phone, setPhone] = useState("6102034119");
  const [zip, setZip] = useState("33131");
  const [search, setSearch] = useState("warehouse");

  return (
    <Dialog
      open={open}
      title="Send test message"
      content={
        <Box>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  defaultValue={phone}
                  label="Phone"
                  variant="outlined"
                  onChange={e => setPhone(e.target.value)}
                />
              </Grid>

              <Grid item md={4}>
                <TextField
                  fullWidth
                  defaultValue={zip}
                  label="Zip"
                  variant="outlined"
                  onChange={e => setZip(e.target.value)}
                />
              </Grid>
              <Grid item md={4}>
                <TextField
                  fullWidth
                  defaultValue={search}
                  label="Search"
                  variant="outlined"
                  onChange={e => setSearch(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>

          <br />
          <br />
        </Box>
      }
      actions={
        <Box>
          <Button
            color="primary"
            onClick={() => {
              sendTestMessageWithAdCopy(token, {
                listId: ad.list,
                adId: ad._id,
                phone,
                zip,
                search
              })
                .then(response => {
                  console.log(response);
                })
                .catch(err => {
                  console.error(err);
                })
                .finally(() => {
                  close();
                });

              // console.log(phone, zip, search);
            }}
          >
            Send
          </Button>
          <Button color="primary" onClick={() => close()}>
            Cancel
          </Button>
        </Box>
      }
    />
  );
};

const ShowAd = ({ ad, edit, del, pause, resume, send }) => {
  return (
    <Box key={ad._id}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography>
            <strong>{ad.ad}</strong>({AD_TYPES[ad.type]}): {ad.content} (
            {ad.cpc || "-"})
          </Typography>
        </Grid>
        <Grid item>
          {ad.status === "active" && (
            <IconButton
              onClick={() => {
                send();
              }}
              size="small"
              color="primary"
            >
              <SendIcon fontSize="small" />
            </IconButton>
          )}

          {ad.status !== "deleted" && (
            <IconButton onClick={() => edit(true)} size="small" color="primary">
              <EditIcon fontSize="small" />
            </IconButton>
          )}

          {ad.status === "active" && (
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                if (window.confirm(`Are you sure to pause ${ad.ad}?`)) {
                  pause(ad._id);
                }
              }}
            >
              <PauseIcon fontSize="small" />
            </IconButton>
          )}

          {ad.status !== "deleted" && (
            <IconButton
              onClick={() => {
                if (window.confirm(`Delete ${ad.ad}? You can't undo it!`)) {
                  del(ad._id);
                }
              }}
              size="small"
              color="secondary"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}

          {ad.status === "paused" && (
            <Tooltip title="Resume ad">
              <IconButton
                size="small"
                color="primary"
                onClick={() => {
                  if (window.confirm(`Are you sure to resume ${ad.ad}?`)) {
                    resume(ad._id);
                  }
                }}
              >
                <PlayArrowIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const EditAd = ({ ad, save, cancel }) => {
  const [adType, setAdType] = useState(ad.type);
  const [content, updateContent] = useState(ad.content);
  const [adName, updateAdName] = useState(ad.ad);
  const [cpc, updateCPC] = useState(ad.cpc);
  const classes = useStyles();

  return (
    <Box key={ad._id} minWidth={800}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <RenderedAd content={content} />
        </Grid>

        <Grid item md={2} xs={4}>
          <TextField
            fullWidth
            label="Ad Name"
            defaultValue={ad.ad}
            variant="outlined"
            onChange={e => updateAdName(e.target.value)}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <Select
            label="Ad Type"
            variant="outlined"
            native
            value={adType}
            onChange={e => {
              setAdType(e.target.value);
            }}
          >
            {Object.entries(AD_TYPES).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>

        <Grid item md={2} xs={4}>
          <TextField
            fullWidth
            label="CPC"
            defaultValue={ad.cpc}
            variant="outlined"
            onChange={e => updateCPC(e.target.value)}
          />
        </Grid>

        <Grid item md={10} xs={12}>
          <TextField
            fullWidth
            label="Ad Copy"
            defaultValue={ad.content}
            variant="outlined"
            onChange={e => updateContent(e.target.value)}
          />
        </Grid>

        <Grid item md={6} xs={4}>
          <Button
            className={classes.buttons}
            onClick={() => save(ad._id, adName, content, cpc, adType)}
            color="primary"
            size="small"
            variant="contained"
          >
            Save
          </Button>
          &nbsp;
          <Button
            className={classes.buttons}
            onClick={() => cancel()}
            color="secondary"
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const SingleAd = ({ ad, refresh }) => {
  const [editing, setEditing] = useState(false);
  const [adTestingUIVisbile, setAdTestingUIVisible] = useState(false);
  const token = useSelector(state => state.auth.token);

  if (editing) {
    return (
      <EditAd
        save={(_id, adName, content, cpc, type) => {
          updateAdCopy(token, ad.list, _id, adName, content, cpc, type).then(
            () => {
              setEditing(false);
              refresh();
            }
          );
        }}
        cancel={() => setEditing(false)}
        key={ad._id}
        ad={ad}
      />
    );
  }

  return (
    <Box>
      <TestAdCopy
        open={adTestingUIVisbile}
        ad={ad}
        close={() => {
          setAdTestingUIVisible(false);
        }}
      />
      <ShowAd
        send={phone => {
          // sendTestMessageWithAdCopy(token, ad.list, ad._id, phone).then(() => {
          //   alert(`${phone} will receive the text message soon`);
          // });

          setAdTestingUIVisible(true);
        }}
        del={_id => {
          setAdCopyStatus(token, _id, "deleted").then(() => {
            refresh();
          });
        }}
        pause={_id => {
          setAdCopyStatus(token, _id, "paused").then(() => {
            refresh();
          });
        }}
        resume={_id => {
          setAdCopyStatus(token, _id, "active").then(() => {
            refresh();
          });
        }}
        key={ad._id}
        ad={ad}
        edit={setEditing}
      />
    </Box>
  );
};

const Ads = ({
  className,
  listid,
  token,
  refresh,
  refreshTrigger,
  ...rest
}) => {
  const classes = useStyles();
  const [createAdOpen, setCreateAdOpen] = useState(false);
  const [ads, setAds] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadAds() {
      setLoading(true);
      const adsData = await fetchAllAdsForList(token, listid);
      setAds(adsData);
      setLoading(false);
    }

    loadAds().then(() => {});
  }, [listid, token, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Ads" />;
  }

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        title="Ads"
        action={
          <IconButton
            onClick={() => {
              setCreateAdOpen(true);
            }}
            aria-label="add"
          >
            <AddIcon />
          </IconButton>
        }
      />
      <Divider />

      <CardContent>
        {ads?.active && (
          <Card>
            <CardHeader title="Active" />
            <CardContent>
              {ads.active.map(ad => (
                <SingleAd refresh={refresh} key={ad._id} ad={ad} />
              ))}
            </CardContent>
          </Card>
        )}
        <br />

        {ads?.paused && (
          <Card>
            <CardHeader title="Paused" />
            <CardContent>
              {ads.paused.map(ad => (
                <SingleAd refresh={refresh} key={ad._id} ad={ad} />
              ))}
            </CardContent>
          </Card>
        )}
        <br />

        {ads?.deleted && (
          <Card>
            <CardHeader title="Deleted" />
            <CardContent>
              {ads.deleted.map(ad => (
                <SingleAd refresh={refresh} key={ad._id} ad={ad} />
              ))}
            </CardContent>
          </Card>
        )}

        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <CreateAd
              listid={listid}
              open={createAdOpen}
              close={() => {
                setCreateAdOpen(false);
                refresh();
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Ads.propTypes = {
  className: PropTypes.string,
  isAdmin: PropTypes.bool
};

export default Ads;

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { Card, CardContent, CardHeader, Divider } from "@material-ui/core";
import DataTable from "../../components/DataTable";
import {
  calculatePercentagesForStats,
  calculateTotals,
} from "../../utils/stats";
import { calculatePercentage } from "../../utils/math";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { fetchStatsByTemplateForList } from "../../services/lists";

const GroupStats = ({ stats, title }) => {
  //Calculate totals
  const totalFields = [
    "welcome_send",
    "alerts_send",
    "total_send",

    "welcome_click",
    "alerts_click",
    "total_click",
  ];
  const totals = calculateTotals(stats, totalFields);

  const percentageFields = ["welcome_click", "alerts_click", "total_click"];
  const totalPercentages = calculatePercentagesForStats(
    totals,
    percentageFields
  );

  const columns = [
    { name: "template", label: "Template" },

    { name: "welcome_send", label: "Welcome Send" },
    { name: "alerts_send", label: "Alerts Send" },
    { name: "total_send", label: "Total Send" },

    {
      name: "welcome_click",
      label: "Welcome Click",
      options: {
        footerData: `${
          totals.welcome_click
        } (${totalPercentages.welcome_click_percentage.toFixed(2)}%)`,
      },
    },
    {
      name: "alerts_click",
      label: "Alerts Click",
      options: {
        footerData: `${
          totals.alerts_click
        } (${totalPercentages.alerts_click_percentage.toFixed(2)}%)`,
      },
    },
    {
      name: "total_click",
      label: "Total Click",
      options: {
        footerData: `${
          totals.total_click
        } (${totalPercentages.total_click_percentage.toFixed(2)}%)`,
      },
    },

    { name: "cpc", label: "CPC" },
    { name: "revenue", label: "Revenue" },
  ];

  const data = [];
  for (const row of stats) {
    data.push({
      template: row.template,

      welcome_send: row.welcome_send || 0,
      alerts_send: row.alerts_send || 0,
      total_send: row.total_send || 0,

      welcome_click: `${row.welcome_click || 0} (${calculatePercentage(
        row.welcome_click,
        row.welcome_send
      ).toFixed(2)}%)`,
      alerts_click: `${row.alerts_click || 0} (${calculatePercentage(
        row.alerts_click,
        row.alerts_send
      ).toFixed(2)}%)`,
      total_click: `${row.total_click || 0} (${calculatePercentage(
        row.total_click,
        row.total_send
      ).toFixed(2)}%)`,

      cpc: row.cpc || "-",
      revenue: row.cpc && row.click ? (row.cpc * row.click).toFixed(4) : "-",
    });
  }

  return (
    <Card>
      <Divider />
      <PerfectScrollbar>
        <DataTable title={title} columns={columns} data={data} />
      </PerfectScrollbar>
    </Card>
  );
};
const StatsByTemplate = ({
  token,
  listId,
  dateRange,
  refreshTrigger,
  adType,
}) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true);

      const templateStats = await fetchStatsByTemplateForList(
        token,
        listId,
        dateRange.start,
        dateRange.end,
        adType
      );
      setStats(templateStats.stats);
      setLoading(false);
    }

    loadInitialData().then(() => {});
  }, [listId, token, dateRange, refreshTrigger]);
  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Template" />;
  }

  if (!stats) {
    return null;
  }

  return (
    <Card>
      <CardHeader title="Stats By Templates" />
      <CardContent>
        {stats.active && <GroupStats stats={stats.active} title="Active" />}
        <br />
        {stats.paused && <GroupStats stats={stats.paused} title="Paused" />}
        <br />
        {stats.deleted && <GroupStats stats={stats.deleted} title="Deleted" />}
      </CardContent>
    </Card>
  );
};

StatsByTemplate.propTypes = {
  className: PropTypes.string,
};

export default StatsByTemplate;

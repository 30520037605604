import React from "react";
import { Text } from "src/components/Text";
import { useEffect, useState } from "react";
import { getNeuvooPopupURL } from "src/utils/popup";
import { loadPopUpAndPopUnder } from "src/services/popup";
import { updateCompanyCategorization } from "src/services/tagging";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Input
} from "@material-ui/core";

export const SelectTitles = ({ user, companyDetails }) => {
  const [otherCat, setOtherCat] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedPositions, setSelectedPositions] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const popUpUnder = () => {
    const { protocol, host } = window.location;
    const comcatFlowURL = `${protocol}//${host}/app/search/${user.id}/ccf`;
    const popupWindowURL = getNeuvooPopupURL(user, Object.keys(selectedPositions)[0]);
    loadPopUpAndPopUnder(comcatFlowURL, popupWindowURL);
  };

  const submit = async () => {
    setLoading(true);
    const selectedTitles = Object.keys(selectedPositions).filter(
      key => selectedPositions[key] === true
    );
    const otherIdx = selectedTitles.indexOf("otherCat");
    if (otherIdx !== -1) selectedTitles[otherIdx] = otherCat;

    const res = await updateCompanyCategorization(user.phone, [
      { company: user?.company, categories: selectedTitles }
    ]);

    if (res) {
      setLoading(false);
      popUpUnder();
    } else setLoading(false);
  };

  useEffect(() => {
    if (user?.categories?.length > 0) {
      const pos = {};

      user.categories
        .filter(cat => companyDetails?.titles.includes(cat))
        .forEach(category => (pos[category] = true));
      setSelectedPositions({ ...pos });
    } else if (user) setSelectedPositions({});
  }, [user]);

  useEffect(() => {
    if (selectedPositions) {
      const selectionValues = Object.values(selectedPositions);
      setIsDisabled(selectionValues.length < 1 || !selectionValues.includes(true));
    } else setIsDisabled(true);
  }, [selectedPositions]);

  return selectedPositions ? (
    <>
      <Box display="flex" alignItems="center" flexDirection="column">
        <img
          style={{
            width: "7rem",
            height: "7rem",
            objectFit: "contain",
            borderRadius: "50% 50%",
            backgroundColor: "#fff"
          }}
          src={companyDetails?.logo}
        />
        <Box padding="0.5rem" />
        <Text variant="h3" align="center" style={{ fontWeight: "bold", color: "#0e57b4" }}>
          Which {user?.company} jobs are you interested in?
        </Text>
      </Box>
      <Box
        width="100%"
        display="flex"
        maxWidth="450px"
        flexDirection="column"
        padding="2rem 1rem 1rem 1rem"
      >
        <Text style={{ fontWeight: "bold", color: "gray" }} variant="overline">
          Select job titles
        </Text>
        <FormGroup>
          {companyDetails?.titles?.map((title, idx) => (
            <Box
              key={idx}
              margin="0.1rem 0"
              padding="0 0.5rem"
              borderRadius="0.3rem"
              bgcolor={selectedPositions[title] && "#c6cee730"}
            >
              <FormControlLabel
                style={{ width: "100%" }}
                control={
                  <Checkbox
                    onChange={e => {
                      setSelectedPositions({
                        ...selectedPositions,
                        [title]: e.target?.checked
                      });
                    }}
                    name={title}
                    color="primary"
                    defaultChecked={selectedPositions[title]}
                  />
                }
                label={<Text style={{ fontWeight: "bold", userSelect: "none" }}>{title}</Text>}
              />
            </Box>
          ))}
          <Box
            key={-1}
            margin="0.1rem 0"
            padding="0 0.5rem"
            borderRadius="0.3rem"
            bgcolor={selectedPositions["otherCat"] && "#c6cee730"}
          >
            <FormControlLabel
              style={{ width: "100%" }}
              control={
                <Checkbox
                  onChange={e => {
                    setSelectedPositions({
                      ...selectedPositions,
                      ["otherCat"]: e.target?.checked
                    });
                  }}
                  name={"otherCat"}
                  color="primary"
                />
              }
              label={<Input placeholder="Other" onChange={e => setOtherCat(e?.target?.value)} />}
            />
          </Box>
        </FormGroup>
        <Box padding="0.5rem" />
        <Button
          color="primary"
          onClick={submit}
          variant="contained"
          disabled={isDisabled}
          startIcon={loading && <CircularProgress color="#fff" size="1rem" />}
          style={{ fontSize: "20px" }}
        >
          Continue
        </Button>
      </Box>
    </>
  ) : (
    ""
  );
};

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { fetchStatsByKeywordForDateRange } from "src/services/email/stats";
import DataTable from "../../components/DataTable";
import {
  blockKeyword,
  getBlockedKeywords,
  unblockKeyword
} from "../../services/email/blacklist";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders, getHeadersForEvent
} from "../../utils/email/stats_table";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { EVENT_TYPES } from "../../config/email/events";

const StatsByKeyword = ({ dateRange, token, permission }) => {
  const [stats, setStats] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [blockedKeywords, setBlockedKeywords] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadStats() {
      setLoading(true);
      const keywordStats = await fetchStatsByKeywordForDateRange(
        token,
        dateRange.start,
        dateRange.end
      );
      setStats(keywordStats.stats);

      const blockedKeywordData = {};
      const blockedData = await getBlockedKeywords(token);
      for (const item of blockedData.keywords) {
        const keyword = item.keyword;
        blockedKeywordData[keyword] = true;
      }
      setBlockedKeywords(blockedKeywordData);
      setLoading(false);
    }

    loadStats().then(() => {});
  }, [token, dateRange, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Keyword" />;
  }
  if (!stats) {
    console.log("No email stats by keyword available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "keyword", label: "Keyword" },
      ...getHeadersForEvent({
        event: "insert",
        eventName: "Accepted",
        EVENT_TYPES,
      }),
      ...getCommonAdminHeaders(stats, { action: true })
    ];
  } else {
    columns = [{ name: "keyword", label: "Keyword" }];
  }

  const data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      const isBlocked = blockedKeywords[row.keyword] || false;
      let action;
      if (isBlocked) {
        action = async e => {
          await unblockKeyword(token, row.keyword);
          setRefreshTrigger(new Date());
        };
      } else {
        action = async e => {
          await blockKeyword(token, row.keyword);
          setRefreshTrigger(new Date());
        };
      }
      data.push({
        keyword: row.keyword,
        "insert.total":
          (row?.insert?.unique?.total || 0) +
          (row?.insert?.duplicate?.total || 0) +
          (row?.insert?.inbox_ally?.total || 0),
        "insert.unique.total": row?.insert?.unique?.total || 0,
        "insert.duplicate.total": row?.insert?.duplicate?.total || 0,
        "insert.inbox_ally.total": row?.insert?.inbox_ally?.total || 0,
        ...getCommonAdminColumns(row, { action, isBlocked })
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        keyword: row.keyword,
        revenue: (row.partner_profit || 0).toFixed(4)
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable title="Stats By Keyword" columns={columns} data={data} />
    </PerfectScrollbar>
  );
};

export default StatsByKeyword;

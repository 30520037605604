import axios from "axios";
import getConfig from "./config";

const config = getConfig();
const apiURL = config.api_url;

export async function getAll(token) {
  const endpoint = apiURL + "/v1/lists/all";
  const response = await axios.get(endpoint, { headers: { token } });
  return response.data;
}

export async function update(token, data) {
  const endpoint = apiURL + "/v1/lists/update";
  const response = await axios.post(endpoint, data, { headers: { token } });
  return response.data;
}

export async function del(token, _id) {
  const endpoint = apiURL + "/v1/lists/delete";
  const response = await axios.post(endpoint, { _id }, { headers: { token } });
  return response.data;
}

export async function create(token, data) {
  const endpoint = apiURL + "/v1/lists/create";
  const response = await axios.post(endpoint, data, { headers: { token } });
  return response.data;
}

export async function fetchAllAdsForList(token, listId) {
  const endpoint = apiURL + `/v1/lists/${listId}/ads/all`;
  const response = await axios.get(endpoint, { headers: { token } });
  return response.data;
}

export async function fetchInformationForList(token, listId) {
  const endpoint = apiURL + `/v1/lists/${listId}/info`;
  const response = await axios.get(endpoint, { headers: { token } });
  return response.data;
}

export async function updateAdCopy(token, listId, _id, ad, content, cpc, type) {
  const endpoint = apiURL + `/v1/lists/${listId}/ads/update`;
  const response = await axios.post(
    endpoint,
    { _id, ad, content, cpc, type },
    { headers: { token } }
  );
  return response.data;
}

export async function setAdCopyStatus(token, _id, status) {
  const endpoint = `${apiURL}/v1/lists/ads/${_id}/status`;
  const response = await axios.post(
    endpoint,
    { status },
    { headers: { token } }
  );
  return response.data;
}

export async function createAdCopyForList(
  token,
  listId,
  ad,
  content,
  cpc,
  type
) {
  const endpoint = apiURL + `/v1/lists/${listId}/ads/create`;
  const response = await axios.post(
    endpoint,
    { ad, content, cpc, type },
    { headers: { token } }
  );
  return response.data;
}

export async function sendTestMessageWithAdCopy(token, data) {
  const { listId, adId, phone, zip, search } = data;

  const endpoint = apiURL + `/v1/lists/${listId}/ads/test-send`;
  const response = await axios.post(
    endpoint,
    { adId, phone, zip, search },
    { headers: { token } }
  );
  return response.data;
}

export async function fetchStatsBySourceForList(token, listId, start, end) {
  const endpoint = apiURL + `/v1/lists/${listId}/stats/by-source`;
  const response = await axios.post(
    endpoint,
    { start, end },
    { headers: { token } }
  );
  return response.data;
}

export async function fetchStatsByKeywordForList(token, listId, start, end) {
  const endpoint = apiURL + `/v1/lists/${listId}/stats/by-keyword`;
  const response = await axios.post(
    endpoint,
    { start, end },
    { headers: { token } }
  );
  return response.data;
}

export async function fetchStatsByCarrierForList(token, listId, start, end) {
  const endpoint = apiURL + `/v1/lists/${listId}/stats/by-carrier`;
  const response = await axios.post(
    endpoint,
    { start, end },
    { headers: { token } }
  );
  return response.data;
}

export async function fetchStatsByOperatingSystemForList(
  token,
  listId,
  start,
  end
) {
  const endpoint = `${apiURL}/v1/lists/${listId}/stats/by-operating-system`;
  const response = await axios.post(
    endpoint,
    { start, end },
    { headers: { token } }
  );
  return response.data;
}

export async function fetchStatsByTemplateForList(token, listId, start, end) {
  const endpoint = apiURL + `/v1/lists/${listId}/stats/by-template`;
  const response = await axios.post(
    endpoint,
    { start, end },
    { headers: { token } }
  );
  return response.data;
}

export async function fetchStatsByAdTypeForList(token, listId, start, end) {
  const endpoint = apiURL + `/v1/lists/${listId}/stats/by-ad-type`;
  const response = await axios.post(
    endpoint,
    { start, end },
    { headers: { token } }
  );
  return response.data;
}

export async function fetchAggregateForList(token, listId, start, end) {
  const endpoint = apiURL + "/v1/aggregate/by-date-range";
  const response = await axios.post(
    endpoint,
    { start, end },
    { headers: { token } }
  );

  const listsData = [];
  for (const row of response.data.aggregate) {
    const list = row.by_list?.[listId];
    if (list) {
      listsData.push({ date: row.date, ...list });
    }
  }
  return listsData;
}

export async function sendBurstSMS(token, listId, adName, content, link) {
  const endpoint = apiURL + `/v1/tasks/lists_burst_queue`;
  const response = await axios.post(
    endpoint,
    { ad: { ad: adName, content, link }, listId },
    { headers: { token } }
  );
  return response.data;
}

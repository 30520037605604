// noinspection JSUnresolvedVariable
import { CONTENT_PROVIDERS } from "../config/content_providers";

import {
  getContentProviderColumnValue,
  getContentProviderHeaders
} from "./content_providers";
import { Button } from "@material-ui/core";
import BackspaceIcon from "@material-ui/icons/Backspace";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React from "react";
import {
  calculatePercentagesForStats,
  calculateTotals,
  getAlternativeFields
} from "./stats";
import { calculatePercentage } from "./math";

export function getCommonAdminHeaders(stats, config = {}) {
  const { action = false } = config;
  const populatedRows = [];

  // TODO: Move to caller function
  for (const row of stats) {
    populatedRows.push({ ...row, ...getAlternativeFields(row) });
  }

  const totalFields = [
    "insert",
    "rejected",

    "welcome_send",
    "alerts_send",
    "total_send",

    "delivered",
    "failed",
    "unsub",

    "welcome_click",
    "alerts_click",
    "total_click",

    "time_inactivity"
  ];

  let totals = calculateTotals(populatedRows, totalFields);
  totals.total_processed_leads = (totals.insert || 0) + (totals.rejected || 0);

  const percentageFields = [
    "insert",
    "rejected",
    "delivery",
    "fail",
    "unsub",
    "welcome_click",
    "alerts_click",
    "total_click",
    "time_inactivity"
  ];
  const percentages = calculatePercentagesForStats(totals, percentageFields);

  totals = { ...totals, ...percentages };

  const headers = [
    {
      name: "insert",
      label: "Accepted",
      options: {
        footerData: `${totals.insert} (${totals.insert_percentage.toFixed(2)}%)`
      }
    },
    {
      name: "rejected",
      label: "Rejected",
      options: {
        footerData: `${totals.rejected} (${totals.rejected_percentage.toFixed(
          2
        )}%)`
      }
    },
    {
      name: "re_signup_insert",
      label: "Re-signup Accepted",
      options: {
        display: false
      }
    },
    {
      name: "fell_off_insert",
      label: "Fell Off Move",
      options: {
        display: false
      }
    },
    {
      name: "soft_stop",
      label: "Soft Stop"
    },
    {
      name: "hard_stop",
      label: "Hard Stop"
    },
    {
      name: "welcome_send",
      label: "Welcome Send",
      options: {
        display: false
      }
    },
    ...getContentProviderHeaders(
      CONTENT_PROVIDERS,
      "welcome_send",
      "Welcome Send",
      {
        display: false
      }
    ),

    {
      name: "alerts_send",
      label: "Alerts Send",
      options: {
        display: false
      }
    },
    ...getContentProviderHeaders(
      CONTENT_PROVIDERS,
      "alerts_send",
      "Alerts Send",
      {
        display: false
      }
    ),

    { name: "total_send", label: "Total Send" },
    ...getContentProviderHeaders(
      CONTENT_PROVIDERS,
      "total_send",
      "Total Send",
      {
        display: false
      }
    ),

    {
      name: "delivered",
      label: "Delivered",
      options: {
        footerData: `${totals.delivered} (${totals.delivery_percentage.toFixed(
          2
        )}%)`
      }
    },
    {
      name: "failed",
      label: "Failed",
      options: {
        footerData: `${totals.failed} (${totals.fail_percentage.toFixed(2)}%)`
      }
    },
    {
      name: "unsub",
      label: "Unsub",
      options: {
        footerData: `${totals.unsub} (${totals.unsub_percentage.toFixed(2)}%)`,
        ...(config.unsub || {})
      }
    },
    {
      name: "time_inactivity",
      label: "Inactivity",
      options: {
        display: false,
        footerData: `${
          totals.time_inactivity
        } (${totals.time_inactivity_percentage.toFixed(2)}%)`,
        ...(config.time_inactivity || {})
      }
    },

    //Click
    {
      name: "welcome_click",
      label: "Welcome Click",
      options: {
        display: false,
        footerData: `${
          totals.welcome_click
        } (${totals.welcome_click_percentage.toFixed(2)}%)`
      }
    },
    ...getContentProviderHeaders(
      CONTENT_PROVIDERS,
      "welcome_click",
      "Welcome Click",
      {
        display: false
      }
    ),

    {
      name: "alerts_click",
      label: "Alerts Click",
      options: {
        display: false,
        footerData: `${
          totals.alerts_click
        } (${totals.alerts_click_percentage.toFixed(2)}%)`
      }
    },
    ...getContentProviderHeaders(
      CONTENT_PROVIDERS,
      "alerts_click",
      "Alerts Click",
      {
        display: false
      }
    ),

    {
      name: "total_click",
      label: "Total Click",
      options: {
        footerData: `${
          totals.total_click
        } (${totals.total_click_percentage.toFixed(2)}%)`
      }
    },
    ...getContentProviderHeaders(
      CONTENT_PROVIDERS,
      "total_click",
      "Total Click",
      {
        display: false
      }
    ),

    { name: "cost", label: "Sending Cost" },

    { name: "revenue", label: "Total Revenue" },
    { name: "ls_revenue", label: "LS Revenue", options: { display: false } },
    {
      name: "partner_revenue",
      label: "Partner Revenue",
      options: { display: false }
    },
    {
      name: "job_boards_revenue_from_operation_media_ads",
      label: "Job Boards Revenue From OM Ads",
      options: {
        display: false
      }
    },
    {
      name: "job_boards_revenue_from_operation_media_xml",
      label: "Job Boards Revenue From OM XML",
      options: {
        display: false
      }
    },
    {
      name: "job_boards_revenue_from_lead5",
      label: "Job Boards Revenue From Upward",
      options: {
        display: false
      }
    },
    ...getContentProviderHeaders(CONTENT_PROVIDERS, "revenue", "Revenue", {
      display: false
    }),

    { name: "profit", label: "Total Profit" },
    { name: "ls_profit", label: "LS Profit" },
    {
      name: "partner_profit",
      label: "Partner Profit"
    },
    ...getContentProviderHeaders(CONTENT_PROVIDERS, "profit", "Profit", {
      display: false
    }),

    {
      name: "revenue_per_record",
      label: "RPR",
      options: {
        footerData: "",
        ...(config.revenue_per_record || {})
      }
    },
    {
      name: "ls_revenue_per_record",
      label: "LSRPR",
      options: {
        display: false,
        footerData: "",
        ...(config.ls_revenue_per_record || {})
      }
    },
    {
      name: "partner_revenue_per_record",
      label: "PRPR",
      options: {
        footerData: "",
        ...(config.partner_revenue_per_record || {})
      }
    },

    {
      name: "revenue_per_mile",
      label: "RPM",
      options: { display: false, footerData: "" }
    },
    {
      name: "ls_revenue_per_mile",
      label: "LSRPM",
      options: { display: false, footerData: "" }
    },
    {
      name: "partner_revenue_per_mile",
      label: "PRPM",
      options: { display: false, footerData: "" }
    },
    ...getContentProviderHeaders(CONTENT_PROVIDERS, "revenue_per_mile", "RPM", {
      display: false,
      footerData: ""
    }),

    { name: "earning_per_click", label: "EPC", options: { footerData: "" } },
    {
      name: "ls_earning_per_click",
      label: "LSEPC",
      options: { display: false, footerData: "" }
    },
    {
      name: "partner_earning_per_click",
      label: "PEPC",
      options: { display: false, footerData: "" }
    },
    ...getContentProviderHeaders(
      CONTENT_PROVIDERS,
      "earning_per_click",
      "EPC",
      {
        display: false,
        footerData: ""
      }
    )
  ];
  if (action) {
    headers.push({
      name: "action",
      label: "Action",
      options: { filter: false, sort: false, download: false, footerData: "" }
    });
  }

  return headers;
}

export function getCommonAdminColumns(row, config = {}) {
  const { action } = config;

  const alternativeFields = getAlternativeFields(row);
  const data = { ...row, ...alternativeFields };
  data.total_processed_leads = (data.insert || 0) + (data.rejected || 0);

  const additionalStats = data.additional_stats || {
    revenue_per_record: data.revenue_per_record,
    ls_revenue_per_record: data.ls_revenue_per_record,
    partner_revenue_per_record: data.partner_revenue_per_record,
    revenue_per_mile: data.revenue_per_mile,
    ls_revenue_per_mile: data.ls_revenue_per_mile,
    partner_revenue_per_mile: data.partner_revenue_per_mile,
    earning_per_click: data.earning_per_click,
    ls_earning_per_click: data.ls_earning_per_click,
    partner_earning_per_click: data.partner_earning_per_click
  };

  const contentProviders = {};
  for (const contentProvider of Object.keys(CONTENT_PROVIDERS)) {
    const values = row.content_providers?.[contentProvider] || {};
    contentProviders[contentProvider] = {
      ...values,
      ...getAlternativeFields(values)
    };
  }

  const columns = {
    insert: `${data.insert || 0} (${calculatePercentage(
      data.insert,
      data.total_processed_leads
    ).toFixed(2)}%)`,
    re_signup_insert: data.re_signup_insert || 0,
    fell_off_insert: data.fell_off_insert || 0,
    soft_stop: data.soft_stop || 0,
    hard_stop: data.hard_stop || 0,

    welcome_send: data.welcome_send || 0,
    ...getContentProviderColumnValue("welcome_send", contentProviders),

    alerts_send: data.alerts_send || 0,
    ...getContentProviderColumnValue("alerts_send", contentProviders),

    total_send: data.total_send || 0,
    ...getContentProviderColumnValue("total_send", contentProviders),

    delivered: `${data.delivered || 0} (${calculatePercentage(
      data.delivered,
      data.total_send
    ).toFixed(2)}%)`,
    failed: `${data.failed || 0} (${calculatePercentage(
      data.failed,
      data.total_send
    ).toFixed(2)}%)`,
    rejected: `${data.rejected || 0} (${calculatePercentage(
      data.rejected,
      data.total_processed_leads
    ).toFixed(2)}%)`,
    unsub: `${data.unsub || 0} (${calculatePercentage(
      data.unsub,
      data.total_send
    ).toFixed(2)}%)`,
    time_inactivity: `${data.time_inactivity || 0} (${calculatePercentage(
      data.time_inactivity,
      data.total_send
    ).toFixed(2)}%)`,

    //Click
    welcome_click: `${data.welcome_click || 0} (${calculatePercentage(
      data.welcome_click,
      data.welcome_send
    ).toFixed(2)}%)`,
    ...getContentProviderColumnValue("welcome_click", contentProviders),

    alerts_click: `${data.alerts_click || 0} (${calculatePercentage(
      data.alerts_click,
      data.alerts_send
    ).toFixed(2)}%)`,
    ...getContentProviderColumnValue("alerts_click", contentProviders),

    total_click: `${data.total_click || 0} (${calculatePercentage(
      data.total_click,
      data.total_send
    ).toFixed(2)}%)`,
    ...getContentProviderColumnValue("total_click", contentProviders),

    //Cost, Revenue, Profit
    cost: (data.cost || 0).toFixed(4),

    revenue: (data.revenue || 0).toFixed(4),
    ls_revenue: (data.ls_revenue || 0).toFixed(4),
    partner_revenue: (data.partner_revenue || 0).toFixed(4),
    job_boards_revenue_from_operation_media_ads: (
      row.job_boards_stats?.operation_media_ads?.revenue || 0
    ).toFixed(4),
    job_boards_revenue_from_operation_media_xml: (
      row.job_boards_stats?.operation_media_xml?.revenue || 0
    ).toFixed(4),
    job_boards_revenue_from_lead5: (
      row.job_boards_stats?.lead5?.revenue || 0
    ).toFixed(4),
    ...getContentProviderColumnValue("revenue", contentProviders, {
      toFixed: 4
    }),

    profit: (data.profit || 0).toFixed(4),
    ls_profit: (data.ls_profit || 0).toFixed(4),
    partner_profit: (data.partner_profit || 0).toFixed(4),
    ...getContentProviderColumnValue("profit", contentProviders, {
      toFixed: 4
    }),

    revenue_per_record: (additionalStats.revenue_per_record || 0).toFixed(4),
    ls_revenue_per_record: (additionalStats.ls_revenue_per_record || 0).toFixed(
      4
    ),
    partner_revenue_per_record: (
      additionalStats.partner_revenue_per_record || 0
    ).toFixed(4),

    revenue_per_mile: (additionalStats.revenue_per_mile || 0).toFixed(4),
    ls_revenue_per_mile: (additionalStats.ls_revenue_per_mile || 0).toFixed(4),
    partner_revenue_per_mile: (
      additionalStats.partner_revenue_per_mile || 0
    ).toFixed(4),
    ...getContentProviderColumnValue("revenue_per_mile", contentProviders, {
      toFixed: 4
    }),

    earning_per_click: (additionalStats.earning_per_click || 0).toFixed(4),
    ls_earning_per_click: (additionalStats.ls_earning_per_click || 0).toFixed(
      4
    ),
    partner_earning_per_click: (
      additionalStats.partner_earning_per_click || 0
    ).toFixed(4),
    ...getContentProviderColumnValue("earning_per_click", contentProviders, {
      toFixed: 4
    })
  };

  if (action) {
    const { isBlocked, block, unblock } = action;

    let actionButton;
    if (isBlocked) {
      actionButton = (
        <Button
          color="primary"
          variant="contained"
          startIcon={<BackspaceIcon />}
          onClick={unblock}
        >
          Unblock
        </Button>
      );
    } else {
      actionButton = (
        <Button
          color="primary"
          variant="contained"
          startIcon={<RemoveCircleIcon />}
          onClick={block}
        >
          Block
        </Button>
      );
    }

    columns["action"] = actionButton;
  }

  return columns;
}

import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { getChartColors } from "./helpers/color";

const BarChart = ({ title, labels, data }) => {
  const { colors, colorsWithOpacity } = getChartColors(labels.length);

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: data,
        backgroundColor: colorsWithOpacity,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Bar data={chartData} height={80} />
      </Grid>
    </Grid>
  );
};

export default BarChart;

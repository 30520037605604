import React, { useState } from "react";
import { Box, TextField, Grid, Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DateRangePicker = ({ start, end, onChange, text="OK" }) => {
  const classes = useStyles();

  const [state, setState] = useState({ start, end });

  return (
    <Grid container spacing={3}>
      <Grid item lg={2} md={2} xl={2} xs={6}>
        <TextField
          id="date"
          label="Start"
          type="date"
          defaultValue={state.start}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setState({ start: e.target.value, end: state.end })}
        />
      </Grid>
      <Grid item lg={2} md={1} xl={2} xs={1}>
        <TextField
          id="date"
          label="End"
          type="date"
          defaultValue={state.end}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) =>
            setState({ end: e.target.value, start: state.start })
          }
        />
      </Grid>

      <Grid item lg={2} md={1} xl={2} xs={1}>
        <Box p={2}>
          <Button
            onClick={() => onChange(state)}
            variant="contained"
            size="small"
            color="primary"
          >
            {text}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DateRangePicker;

import React from "react";

import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";

import { TableCell, TableRow, TableFooter } from "@material-ui/core";

const DataTable = ({ title, data, columns, options = {} }) => {
  const customSort = (data, colIndex, order, meta) => {
    return data.sort((a, b) => {
      let first = a.data[colIndex];
      let second = b.data[colIndex];

      if (typeof first === "string") {
        first = first.trim();
        if (first.includes(" ")) {
          first = first.split(" ")[0];
        }
      }

      if (typeof second === "string") {
        second = second.trim();
        if (second.includes(" ")) {
          second = second.split(" ")[0];
        }
      }

      if (!isNaN(first) && !isNaN(second)) {
        first = parseFloat(first);
        second = parseFloat(second);
      }

      return (first < second ? -1 : 1) * (order === "asc" ? 1 : -1);
    });
  };
  const customTableBodyFooterRender = (opts) => {
    function calculateColumnSum(name) {
      let sum = 0;
      let isText = false;

      for (const row of data) {
        if (isNaN(row[name])) {
          isText = true;
          break;
        }
        sum += parseFloat(row[name]);
      }

      if (isText) {
        return "";
      }

      if (!(sum % 1 === 0)) {
        sum = sum.toFixed(4);
      }

      return sum;
    }

    return (
      <TableFooter>
        <TableRow>
          {opts.columns.map((col, index) => {
            if (col.display !== "true") {
              return null;
            }

            let data;
            if (col.footerData !== undefined) {
              data = col.footerData;
            } else if (index === 0) {
              data = "Total";
            } else {
              data = calculateColumnSum(col.name);
            }

            return (
              <TableCell key={index}>
                <strong style={{ fontSize: 14, color: "black" }}>{data}</strong>
              </TableCell>
            );
          })}
        </TableRow>
      </TableFooter>
    );
  };

  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={{
        responsive: "standard",
        filterType: "textField",
        jumpToPage: true,
        selectableRows: "none",
        sort: true,
        customSort,
        customTableBodyFooterRender,
        print: false,
        textLabels: {
          body: {
            noMatch: "",
          },
        },
        ...options,
      }}
    />
  );
};

DataTable.propTypes = {
  className: PropTypes.string,
};

export default DataTable;

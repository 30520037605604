import React, { useEffect, useState } from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import DataTable from "../../components/DataTable";
import { getAllLeads } from "../../services/email/leads";

import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const RecentLeads = ({ token, permission }) => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchLeads() {
      setLoading(true);
      const data = await getAllLeads(token, 50, -1);
      const leadsData = data.leads;
      for (const row of leadsData) {
        row.accepted_at = moment(row.created_at)
          .local()
          .format("YYYY-MM-DD, h:mm:ss a");
        row.site_wide_unique_email = row.site_wide_unique_email ? "Yes" : "No";
        row.network_wide_unique_email = row.network_wide_unique_email
          ? "Yes"
          : "No";
      }
      setLeads(leadsData);
      setLoading(false);
    }

    fetchLeads().then(() => {});
  }, [token]);

  if (loading) {
    return <LoadingSpinnerForStats title="Recent Leads" />;
  }

  const columns = [
    { name: "accepted_at", label: "Accepted At", options: { footerData: "" } },
    { name: "email", label: "Email", options: { footerData: "" } },
    { name: "phone", label: "Phone", options: { footerData: "" } },
    { name: "first_name", label: "First Name", options: { footerData: "" } },
    { name: "last_name", label: "Last Name", options: { footerData: "" } },
    { name: "search", label: "Keyword", options: { footerData: "" } },
    {
      name: "original_user_search",
      label: "Original User Search",
      options: { footerData: "" }
    },
    { name: "utm_source", label: "Source", options: { footerData: "" } },
    { name: "company", label: "Company", options: { footerData: "" } },
    { name: "signup_url", label: "Signup URL", options: { footerData: "" } },
    {
      name: "site_wide_unique_email",
      label: "Side Wide Unique",
      options: { footerData: "" }
    },
    {
      name: "network_wide_unique_email",
      label: "Network Wide Unique",
      options: { footerData: "" }
    },
    { name: "ip_address", label: "IP", options: { footerData: "" } },
    { name: "domain", label: "Domain", options: { footerData: "" } },
    { name: "zip", label: "zip", options: { footerData: "" } },
    { name: "city", label: "City", options: { footerData: "" } },
    { name: "state", label: "State", options: { footerData: "" } }
  ];

  return (
    <PerfectScrollbar>
      <DataTable
        title="Recent Leads"
        columns={columns}
        data={leads}
        options={{ rowsPerPage: 25, rowsPerPageOptions: [25, 50] }}
      />
    </PerfectScrollbar>
  );
};

export default RecentLeads;

import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { fetchStatsByOperatingSystemForDateRange } from "src/services/email/stats";
import DataTable from "../../components/DataTable";
import {
  blockOperatingSystem,
  getBlockedOperatingSystems,
  unblockOperatingSystem,
} from "../../services/email/blacklist";
import {
  getCommonAdminColumns,
  getCommonAdminHeaders, getHeadersForEvent
} from "../../utils/email/stats_table";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";
import { EVENT_TYPES } from "../../config/email/events";

const StatsByOperatingSystem = ({ dateRange, token, permission }) => {
  const [stats, setStats] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [blockedOperatingSystems, setBlockedOperatingSystems] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadStats() {
      setLoading(true);
      const operatingSystemStats =
        await fetchStatsByOperatingSystemForDateRange(
          token,
          dateRange.start,
          dateRange.end
        );
      setStats(operatingSystemStats.stats);

      const blockedOperatingSystemData = {};
      const blockedData = await getBlockedOperatingSystems(token);
      for (const item of blockedData.operating_systems) {
        const operatingSystem = item.operating_system;
        blockedOperatingSystemData[operatingSystem] = true;
      }
      setBlockedOperatingSystems(blockedOperatingSystemData);
      setLoading(false);
    }

    loadStats().then(() => {});
  }, [token, dateRange, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Operating System" />;
  }
  if (!stats) {
    console.log("No email stats by operating system available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "operating_system", label: "Operating System" },
      ...getHeadersForEvent({
        event: "insert",
        eventName: "Accepted",
        EVENT_TYPES,
      }),
      ...getCommonAdminHeaders(stats, { action: true }),
    ];
  } else {
    columns = [{ name: "operating_system", label: "Operating System" }];
  }

  const data = [];
  if (permission.view === "internal") {
    for (const row of stats) {
      const isBlocked = blockedOperatingSystems[row.operating_system] || false;
      let action;
      if (isBlocked) {
        action = async () => {
          await unblockOperatingSystem(token, row.operating_system);
          setRefreshTrigger(new Date());
        };
      } else {
        action = async () => {
          await blockOperatingSystem(token, row.operating_system);
          setRefreshTrigger(new Date());
        };
      }
      data.push({
        operating_system: row.operating_system,
        "insert.total":
          (row?.insert?.unique?.total || 0) +
          (row?.insert?.duplicate?.total || 0) +
          (row?.insert?.inbox_ally?.total || 0),
        "insert.unique.total": row?.insert?.unique?.total || 0,
        "insert.duplicate.total": row?.insert?.duplicate?.total || 0,
        "insert.inbox_ally.total": row?.insert?.inbox_ally?.total || 0,
        ...getCommonAdminColumns(row, { action, isBlocked }),
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        operating_system: row.operating_system,
        revenue: (row.partner_profit || 0).toFixed(4),
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable
        title="Stats By Operating System"
        columns={columns}
        data={data}
      />
    </PerfectScrollbar>
  );
};

export default StatsByOperatingSystem;

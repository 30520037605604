import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";

import { fetchStatsByOperatingSystemForDateRange } from "src/services/stats_by_operating_system";

import {
  blockOperatingSystem,
  getBlockedOperatingSystems,
  unblockOperatingSystem,
} from "src/services/blacklist";
import DataTable from "../../components/DataTable";

import {
  getCommonAdminColumns,
  getCommonAdminHeaders,
} from "../../utils/datatable";
import LoadingSpinnerForStats from "../../components/LoadingSpinnerForStats";

const StatsByOperatingSystem = ({ dateRange, auth, permission }) => {
  let [stats, setStats] = useState(null);
  let [blockedOperatingSystems, setBlockedOperatingSystems] = useState({});
  let [refreshTrigger, setRefreshTrigger] = useState(new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInitialData() {
      setLoading(true);
      const operatingSystemStats =
        await fetchStatsByOperatingSystemForDateRange(
          auth.token,
          dateRange.start,
          dateRange.end
        );
      setStats(operatingSystemStats.stats);

      const blockedData = await getBlockedOperatingSystems(auth.token);
      const blockedOperatingSystemData = {};
      for (const item of blockedData.operating_systems) {
        const operatingSystem = item.operating_system;
        if (!blockedOperatingSystemData[operatingSystem]) {
          blockedOperatingSystemData[operatingSystem] = true;
        }
      }
      setBlockedOperatingSystems(blockedOperatingSystemData);
      setLoading(false);
    }

    loadInitialData().then(() => {});
  }, [auth.token, dateRange, refreshTrigger]);

  if (loading) {
    return <LoadingSpinnerForStats title="Stats By Operating System" />;
  }

  if (!stats) {
    console.log("No Stats by operating system available");
    return null;
  }

  let columns;
  if (permission.view === "internal") {
    columns = [
      { name: "operating_system", label: "Operating System" },
      ...getCommonAdminHeaders(stats, { action: true }),
    ];
  } else {
    columns = [
      { name: "operating_system", label: "Operating System" },
      { name: "revenue", label: "Revenue" },
    ];
  }

  let data = [];

  if (permission.view === "internal") {
    for (const row of stats) {
      const isBlocked = blockedOperatingSystems[row.operating_system] || false;
      const action = {
        isBlocked,
        block: (e) => {
          blockOperatingSystem(auth.token, row.operating_system).then(
            (e) => setRefreshTrigger(new Date()),
            (e) => setRefreshTrigger(new Date())
          );
        },
        unblock: (e) => {
          unblockOperatingSystem(auth.token, row.operating_system).then(
            (e) => setRefreshTrigger(new Date()),
            (e) => setRefreshTrigger(new Date())
          );
        },
      };

      data.push({
        operating_system: row.operating_system,
        ...getCommonAdminColumns(row, { action }),
      });
    }
  } else {
    for (const row of stats) {
      data.push({
        operating_system: row.operating_system,
        revenue: (row.partner_profit || 0).toFixed(4),
      });
    }
  }
  return (
    <PerfectScrollbar>
      <DataTable
        title="Stats By Operating System"
        columns={columns}
        data={data}
      />
    </PerfectScrollbar>
  );
};

StatsByOperatingSystem.propTypes = {
  className: PropTypes.string,
};

export default StatsByOperatingSystem;

import React, { useEffect, useState } from "react";

import moment from "moment";

import _ from "lodash";

import { makeStyles, Grid } from "@material-ui/core";

import { Line } from "react-chartjs-2";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const today = moment().format("YYYY-MM-DD");
const yesterday = moment()
  .subtract(1, "day")
  .format("YYYY-MM-DD");
const sdlw = moment()
  .subtract(7, "days")
  .format("YYYY-MM-DD");

const useStyles = makeStyles(() => ({
  root: { margin: "30px" },
  actions: {
    justifyContent: "flex-end",
  },
}));

const getAvailableLists = (data, sites) => {
  const availableLists = [];

  for (const site of sites) {
    const { domain } = site;
    const aggregateForSite = data[domain].aggregate;
    const aggregateForToday = aggregateForSite.filter(
      (element) => element.date === yesterday
    )[0];

    const lists = aggregateForToday.by_list;

    for (const id of Object.keys(lists)) {
      const name = lists[id].list_data.name;
      availableLists.push({ id, name, domain });
    }
  }

  return availableLists;
};

const getDataForSelectedLists = (data, selectedLists, date) => {
  const dateTotals = {};
  const todayTotals = {};
  const yesterdayTotals = {};
  const sdlwTotals = {};

  for (let i = 0; i < 24; i++) {
    todayTotals[i] = {
      send: 0,
      click: 0,
    };

    yesterdayTotals[i] = {
      send: 0,
      click: 0,
    };

    sdlwTotals[i] = {
      send: 0,
      click: 0,
    };

    dateTotals[i] = {
      send: 0,
      click: 0,
    };
  }

  for (const list of selectedLists) {
    const { domain, id } = list;

    const aggregateForSite = data[domain].aggregate;

    if (!aggregateForSite) continue;

    // Today's data
    const aggregateForToday = aggregateForSite.filter(
      (element) => element.date === today
    )[0];

    if (aggregateForToday) {
      let todayLists = aggregateForToday.by_list;
      let selectedListToday = todayLists[id];

      if (selectedListToday) {
        const hourlyDataToday = selectedListToday.by_hour;
        for (const [hour, values] of Object.entries(hourlyDataToday)) {
          todayTotals[hour].send += values.send || 0;
          todayTotals[hour].click += values.click || 0;
        }
      }
    }

    // Yesterday

    const aggregateForYesterday = aggregateForSite.filter(
      (element) => element.date === yesterday
    )[0];

    if (aggregateForYesterday) {
      let yesterdayLists = aggregateForYesterday.by_list;
      let selectedListYesterday = yesterdayLists[id];

      if (selectedListYesterday) {
        const hourlyDataYesterday = selectedListYesterday.by_hour;
        for (const [hour, values] of Object.entries(hourlyDataYesterday)) {
          yesterdayTotals[hour].send += values.send || 0;
          yesterdayTotals[hour].click += values.click || 0;
        }
      }
    }

    // Same day last week

    const aggregateForSDLW = aggregateForSite.filter(
      (element) => element.date === sdlw
    )[0];

    if (aggregateForSDLW) {
      let sdlwLists = aggregateForSDLW.by_list;
      let selectedListSDLW = sdlwLists[id];

      if (selectedListSDLW) {
        const hourlyDataSDLW = selectedListSDLW.by_hour;
        for (const [hour, values] of Object.entries(hourlyDataSDLW)) {
          sdlwTotals[hour].send += values.send || 0;
          sdlwTotals[hour].click += values.click || 0;
        }
      }
    }

    const aggregateForDate = aggregateForSite.filter(
      (element) => element.date === date
    )[0];

    if (aggregateForDate) {
      let dateLists = aggregateForDate.by_list;
      let selectedListDate = dateLists[id];

      if (selectedListDate) {
        const hourlyDataDate = selectedListDate.by_hour;
        for (const [hour, values] of Object.entries(hourlyDataDate)) {
          dateTotals[hour].send += values.send || 0;
          dateTotals[hour].click += values.click || 0;
        }
      }
    }
  }

  for (let i = 0; i < 24; i++) {
    todayTotals[i]["ctr"] =
      ((todayTotals[i].click || 0) / (todayTotals[i].send || 0)) * 100;

    yesterdayTotals[i]["ctr"] =
      ((yesterdayTotals[i].click || 0) / (yesterdayTotals[i].send || 0)) * 100;

    sdlwTotals[i]["ctr"] =
      ((sdlwTotals[i].click || 0) / (sdlwTotals[i].send || 0)) * 100;

    dateTotals[i]["ctr"] =
      ((dateTotals[i].click || 0) / (dateTotals[i].send || 0)) * 100;
  }

  const dataForLists = {
    today: todayTotals,
    yesterday: yesterdayTotals,
    sdlw: sdlwTotals,
  };

  if (date) {
    dataForLists[date] = dateTotals;
  }

  return dataForLists;
};

const SelectSite = ({ onChange, options, values }) => {
  return (
    <Autocomplete
      value={values}
      multiple={true}
      options={options}
      getOptionLabel={(option) => option.name}
      style={{ width: 300 }}
      onChange={(event, newValues) => {
        onChange(newValues);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Lists" variant="outlined" />
      )}
    />
  );
};

const SelectMetric = ({ onChange, defaultMetric }) => {
  return (
    <Select value={defaultMetric} onChange={(ev) => onChange(ev.target.value)}>
      <MenuItem key="send" value="send">
        Send
      </MenuItem>

      <MenuItem key="click" value="click">
        Click
      </MenuItem>

      <MenuItem key="ctr" value="ctr">
        CTR
      </MenuItem>
    </Select>
  );
};

const SelectAdditionalDate = ({ onChange }) => {
  const [date, setDate] = useState();

  return (
    <TextField
      id="date"
      label="Custom Date"
      type="date"
      defaultValue={date}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(e) => {
        setDate(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

const SelectLists = ({ options, onChange }) => {
  const [values, setValues] = useState(options);

  useEffect(() => {
    setValues(options);
  }, [options]);

  return (
    <Autocomplete
      value={values}
      multiple={true}
      options={options}
      getOptionLabel={(option) => option.name}
      style={{ width: 500 }}
      onChange={(event, newValues) => {
        onChange(newValues);
        setValues(newValues);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Lists" variant="outlined" />
      )}
    />
  );
};

const Graph = ({ data, metric, date }) => {
  const datasets = [
    {
      label: "Today",
      data: Object.values(data.today).map((row) => row[metric]),
      fill: false,
      backgroundColor: "rgb(36, 137, 34)",
      borderColor: "rgb(36, 137, 34)",
    },

    {
      label: "Yesterday",
      data: Object.values(data.yesterday).map((row) => row[metric]),
      fill: false,
      backgroundColor: "rgb(175, 36, 36)",
      borderColor: "rgb(175, 36, 36)",
    },

    {
      label: "Same Day Last Week",
      data: Object.values(data.sdlw).map((row) => row[metric]),
      fill: false,
      backgroundColor: "rgb(40, 78, 155)",
      borderColor: "rgb(40, 78, 155)",
    },
  ];

  if (date) {
    datasets.push({
      label: date,
      data: Object.values(data[date]).map((row) => row[metric]),
      fill: false,
      backgroundColor: "rgb(234, 212, 16)",
      borderColor: "rgb(234, 212, 16)",
    });
  }

  const graphData = {
    labels: _.range(0, 24),
    datasets,
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return <Line data={graphData} options={options} />;
};

const ChartView = ({ data, onChange }) => {
  const [availableSites, setAvailableSites] = useState(() => {
    return Object.entries(data).map(([key, value]) => {
      return { name: value.name, domain: key };
    });
  });

  const [sites, setSites] = useState(availableSites);

  const [metric, setMetric] = useState("click");
  const [date, setDate] = useState(null);

  const [availableLists, setAvailableLists] = useState(() =>
    getAvailableLists(data, sites)
  );

  const [selectedLists, setSelectedLists] = useState(() =>
    getAvailableLists(data, sites)
  );

  useEffect(() => {
    const availableLists = getAvailableLists(data, sites);
    setAvailableLists(availableLists);
    setSelectedLists(availableLists);
  }, [data, sites]);

  const graphData = getDataForSelectedLists(data, selectedLists, date);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={2}>
          <SelectMetric
            defaultMetric={metric}
            onChange={(metric) => {
              setMetric(metric);
            }}
          />
        </Grid>

        <Grid item lg={2}>
          <SelectAdditionalDate
            onChange={(date) => {
              setDate(date);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={4}>
          <SelectSite
            options={availableSites}
            values={sites}
            onChange={(site) => {
              setSites(site);
            }}
          />
        </Grid>

        <Grid item lg={6}>
          <SelectLists
            options={availableLists}
            values={selectedLists}
            onChange={(lists) => {
              setSelectedLists(lists);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item lg={10}>
          <Graph data={graphData} metric={metric} date={date} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ChartView;

import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Box, Card, CardContent, Grid } from "@material-ui/core";
import DataTable from "../../components/DataTable";
import {
  calculatePercentage,
  calculateRevenuePerRecord,
  divideOrGetZero
} from "../../utils/math";
import {
  calculatePercentagesForStats,
  calculateTotals
} from "../../utils/stats";
import BarChart from "../../components/charts/Bar";
import SMSStatsItemSelector from "../../components/SMSStatsChartItemSelector";
import { getChartLabel } from "../../config/chart";

const CarrierGroup = ({ totals: stats, className, ...rest }) => {
  const [chartItem, setChartItem] = useState("insert");

  if (!stats) return null;

  const totalFields = [
    "insert",

    "welcome_send",
    "alerts_send",
    "total_send",

    "delivered",
    "failed",

    "welcome_click",
    "alerts_click",
    "total_click",

    "profit"
  ];

  const totals = calculateTotals(stats, totalFields);
  const percentageFields = [
    "delivery",
    "fail",
    "welcome_click",
    "alerts_click",
    "total_click"
  ];

  const totalPercentages = calculatePercentagesForStats(
    totals,
    percentageFields
  );

  const adminColumns = [
    { name: "carrier", label: "Carrier" },
    { name: "insert", label: "Accepted" },

    {
      name: "welcome_send",
      label: "Welcome Send"
    },
    {
      name: "alerts_send",
      label: "Alerts send"
    },
    { name: "total_send", label: "Total Send" },

    {
      name: "delivered",
      label: "Delivered",
      options: {
        footerData: `${
          totals.delivered
        } (${totalPercentages.delivery_percentage.toFixed(2)}%)`
      }
    },
    {
      name: "failed",
      label: "Failed",
      options: {
        footerData: `${
          totals.failed
        } (${totalPercentages.fail_percentage.toFixed(2)}%)`
      }
    },
    {
      name: "welcome_click",
      label: "Welcome Click",
      options: {
        footerData: `${
          totals.welcome_click
        } (${totalPercentages.welcome_click_percentage.toFixed(2)}%)`
      }
    },
    {
      name: "alerts_click",
      label: "Alerts Click",
      options: {
        footerData: `${
          totals.alerts_click
        } (${totalPercentages.alerts_click_percentage.toFixed(2)}%)`
      }
    },
    {
      name: "total_click",
      label: "Total Click",
      options: {
        footerData: `${
          totals.total_click
        } (${totalPercentages.total_click_percentage.toFixed(2)}%)`
      }
    },

    { name: "cost", label: "Sending Cost" },
    { name: "revenue", label: "Total Revenue" },
    {
      name: "profit",
      label: "Total Profit"
    },
    {
      name: "revenue_per_record",
      label: "RPR",
      options: {
        footerData: divideOrGetZero(totals.profit, totals.insert).toFixed(4)
      }
    }
  ];

  const adminTableData = [];

  for (const row of stats) {
    //set fixed digits after decimal
    row.revenue = parseFloat(row.revenue.toFixed(4));
    row.cost = parseFloat(row.cost.toFixed(4));
    row.profit = parseFloat(row.profit.toFixed(4));
    row.revenue_per_record = parseFloat(
      calculateRevenuePerRecord(row.profit, row.insert).toFixed(4)
    );

    adminTableData.push({
      carrier: row.carrier,
      insert: row.insert,

      welcome_send: row.welcome_send || 0,
      alerts_send: row.alerts_send || 0,
      total_send: row.total_send || 0,

      delivered: `${row.delivered || 0} (${calculatePercentage(
        row.delivered,
        row.total_send
      ).toFixed(2)}%)`,
      failed: `${row.failed || 0} (${calculatePercentage(
        row.failed,
        row.total_send
      ).toFixed(2)}%)`,

      welcome_click: `${row.welcome_click || 0} (${calculatePercentage(
        row.welcome_click,
        row.welcome_send
      ).toFixed(2)}%)`,
      alerts_click: `${row.alerts_click || 0} (${calculatePercentage(
        row.alerts_click,
        row.alerts_send
      ).toFixed(2)}%)`,
      total_click: `${row.total_click || 0} (${calculatePercentage(
        row.total_click,
        row.total_send
      ).toFixed(2)}%)`,

      cost: row.cost,
      revenue: row.revenue,
      profit: row.profit,

      revenue_per_record: row.revenue_per_record.toFixed(4)
    });
  }

  const chartItems = [
    "insert",

    "welcome_send",
    "alerts_send",
    "total_send",

    "delivered",
    "failed",

    "welcome_click",
    "alerts_click",
    "total_click",

    "cost",
    "revenue",
    "profit",
    "revenue_per_record"
  ];
  const labels = Object.values(stats).map(item => item.carrier);
  const chartData = Object.values(stats).map(item => item[chartItem]);

  return (
    <Card>
      <CardContent>
        <PerfectScrollbar>
          <Box minWidth={800}>
            <DataTable
              title="Carrier Group Stats"
              columns={adminColumns}
              data={adminTableData}
            />
          </Box>

          <Box m={2}>
            <Grid container spacing={3}>
              <Grid item lg={2} md={2} xl={2} xs={6}>
                <SMSStatsItemSelector
                  chartItems={chartItems}
                  value={chartItem}
                  onChange={setChartItem}
                  isAdmin={true}
                />
              </Grid>
            </Grid>
            <BarChart
              title={getChartLabel(chartItem)}
              labels={labels}
              data={chartData}
            />
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

export default CarrierGroup;

import React from "react";
import { Navigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import { login } from "./../../redux/auth";

import Page from "src/components/Page";
import { findAccessibleViews } from "../../utils/permissions";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const { token, access, error } = auth;

  if (token) {
    const accessibleViews = findAccessibleViews(access);
    let view;
    if (accessibleViews.includes("sms:dashboard")) {
      view = <Navigate to="/app/admin/dashboard" />;
    } else if (accessibleViews.includes("email:dashboard")) {
      view = <Navigate to="/app/admin/email-dashboard" />;
    } else if (accessibleViews.includes("docs")) {
      view = <Navigate to="/app/admin/docs" />;
    } else {
      view = <Navigate to="/app/404" />;
    }
    return view;
  }

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "user@example.com",
              password: "Password123"
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string()
                .max(255)
                .required("Password is required")
            })}
            onSubmit={({ email, password }, { setSubmitting }) => {
              dispatch(login({ email, password }));
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>

                {error ? (
                  <Box mb={3}>
                    <Typography color="error" variant="caption">
                      {error}
                    </Typography>
                  </Box>
                ) : null}

                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;

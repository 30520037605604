import { Box } from '@material-ui/core';
import { Outlet } from 'react-router';
import React from 'react';

// This is just a blank layout

export const CompanyCategorizationLayout = () => {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Outlet />
    </Box>
  );
};
